import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import Dashboard from '../src/views/Dashboard.vue'
// import NewPostPage from '../src/views/NewPostPage.vue'
import NewPost from '../src/views/NewPost.vue'
import NewPhotoPost from '../src/views/NewPhotoPost.vue'
import NewPostMobile from '../src/views/NewPostMobile.vue'
import NewContribution from '../src/views/NewContribution.vue'
import PhotoContribution from '../src/views/PhotoContribution.vue'
import ManageSubscription from '../src/views/ManageSubscription.vue'
import AdminDashboard from '../src/views/AdminDashboard.vue'

// import LandPage from '../src/views/LandPage.vue'
import LandPageA from '../src/views/LandPageA.vue'
import LandPageArabic from '../src/views/LandPageArabic.vue'
// import LandPageB from '../src/views/LandPageB.vue'
// import LandPageC from '../src/views/LandPageC.vue'
import Login from '../src/components/Login/Login.vue'
import ArabicLogin from '../src/components/Login/Arabic/ArabicLogin.vue'
import SignUp from '../src/components/Login/SignUp.vue'
import ArabicSignUp from '../src/components/Login/Arabic/ArabicSignUp.vue'
import ManageAccount from '../src/components/Login/ManageAccount.vue'
import ArabicManageAccount from '../src/components/Login/Arabic/ArabicManageAccount.vue'
import HomeFeed from '../src/components/HomeFeed.vue'
import FriendsPage from '../src/components/Friends/FriendsPage.vue'
import Profile from '../src/components/Profile.vue'
import Drafts from '../src/components/Drafts.vue'
import About from '../src/components/About.vue'
// import Checkout from '../src/components/Checkout.vue'
import Survey from '../src/components/Survey.vue'
import Calico from '../src/components/Calico.vue'
import Help from '../src/views/Help.vue'
// import Terms from '../src/views/Terms.vue'
import Terms from '../src/components/TC.vue'
import Privacy from '../src/components/Privacy.vue'
// import Blog from '../src/components/Blog.vue'
import FAQ from '../src/components/FAQ.vue'

import GroupsPage from '../src/components/Groups/GroupsPage.vue'
import NewGroup from '../src/components/Groups/NewGroup.vue'
import NewMember from '../src/components/Groups/NewMember.vue'

import SettingsPage from '../src/components/SettingsPage.vue'
import FeedbackPage from '../src/components/FeedbackPage.vue'
import SearchPage from '../src/components/SearchPage.vue'
import ViewPost from '../src/components/ViewPost.vue'
import ViewPhotoPost from '../src/components/ViewPhotoPost.vue'
import PostView from '../src/components/PostView.vue'
import PhotoPostView from '../src/components/PhotoPostView.vue'
import ViewUserProfile from '../src/components/ViewUserProfile.vue'
import ViewTag from '../src/components/ViewTag.vue'
// import ViewBlog from '../src/components/ViewBlog.vue'

import firebase from '../firebaseConfig'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: (route) => ({
            alertEmailSent: route.params.emailSent
        }),
    },
    {
        path: '/ar-login',
        name: 'ar-login',
        component: ArabicLogin,
        props: (route) => ({
            alertEmailSent: route.params.emailSent
        }),
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: SignUp,
    },
    {
        path: '/ar-sign-up',
        name: 'ar-sign-up',
        component: ArabicSignUp,
    },
    // {
    //     path: '/manageAccount',
    //     name: 'manageAccount',
    //     component: ManageAccount,
    //     props: (route) => ({
    //         code: route.query.oobCode,
    //         mode: route.query.mode,
    //         continueURL: route.query.continueUrl,
    //     })
    // },
    {
        path: '/manage-account',
        name: 'manage-account',
        component: ManageAccount,
        props: (route) => ({
            code: route.query.oobCode,
            mode: route.query.mode,
            continueURL: route.query.continueUrl,
        })
    },
    {
        path: '/ar-manage-account',
        name: 'ar-manage-account',
        component: ArabicManageAccount,
        props: (route) => ({
            code: route.query.oobCode,
            mode: route.query.mode,
            continueURL: route.query.continueUrl,
        })
    },
    {
        path: '/CALICO',
        name: 'calico',
        component: Calico,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/survey',
        name: 'survey',
        component: Survey,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/UTAS',
        name: 'UTAS',
        component: Survey,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/DU',
        name: 'DU',
        component: Survey,
        meta: {
            requiresAuth: false,
        }
    },
    {
        path: '/post-view',
        name: 'post-view',
        component: PostView,
        meta: { requiresAuth: false },
        props: 
         (route) => ({
            post: route.query.post,
            postId: route.query.pid,
            postType: route.query.postType,
            groupId: route.query.gId,
            userData: route.query.userData,
        })
    },
    {
        path: '/photo-post-view',
        name: 'photo-post-view',
        component: PhotoPostView,
        meta: { requiresAuth: false },
        props: 
         (route) => ({
            post: route.query.post,
            postId: route.query.id,
            postType: route.query.postType,
            groupId: route.query.gId,
            userData: route.query.userData,
        })
    },
    {
        path: '',
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
        children: [
            // routes for the child router-view inside of the dashboard component
            {
                path: '',
                redirect: 'home',
                meta: { requiresAuth: true },
            },
            {
                path: 'home',
                component: HomeFeed,
                meta: { requiresAuth: true },
                props: (route) => ({
                    newUser: route.query.newUser
                }),
            },
            {
                path: 'profile',
                component: Profile,
                meta: { requiresAuth: true }
            },
            {
                path: 'friends',
                component: FriendsPage,
                meta: { requiresAuth: true },
            },
            {
                path: 'groups',
                component: GroupsPage,
                meta: { requiresAuth: true },
                props: (route) => ({
                    groupId: route.query.gId
                }),
                children: [{
                    path: 'new-group',
                    name: 'new-group',
                    component: NewGroup,
                    meta: { requiresAuth: true },
                }, {
                    path: 'new-member',
                    name: 'new-member',
                    component: NewMember,
                    meta: { requiresAuth: true },
                }],
            },
            {
                path: 'settings',
                component: SettingsPage,
                meta: { requiresAuth: true },
            },
            {
                path: 'feedback',
                component: FeedbackPage,
                meta: { requiresAuth: true }
            },
            {
                path: 'search',
                component: SearchPage,
                meta: { requiresAuth: true },
                props: (route) => ({
                    searchQuery: route.query.s
                })
            },
            {
                path: 'view-post',
                name: 'view-post',
                component: ViewPost,
                meta: { requiresAuth: true },
                props: 
                 (route) => ({
                    // post: route.query.post,
                    postId: route.query.pid,
                    postType: route.query.postType,
                    audioDataId: route.query.aid,
                    // groupId: route.query.gId,
                    // userData: route.query.userData,
                })
            },
            {
                path: 'view-tag',
                name: 'view-tag',
                component: ViewTag,
                meta: { requiresAuth: true },
                props: 
                 (route) => ({
                    postType: route.query.postType,
                    tag: route.query.tag,
                })
            },
            {
                path: 'view-photo-post',
                name: 'view-photo-post',
                component: ViewPhotoPost,
                meta: { requiresAuth: true },
                props: 
                 (route) => ({
                    post: route.query.post,
                    postId: route.query.id,
                    postType: route.query.postType,
                    groupId: route.query.gId,
                })
            },
            {
                path: 'view-user-profile',
                name: 'view-user-profile',
                component: ViewUserProfile,
                meta: { requiresAuth: true },
                props: (route) => ({
                    propUserId: route.query.id,
                })
            },
            {
                path: '/about',
                name: 'about',
                component: About,
                meta: {
                    requiresAuth: false
                },
            },
            {
                path: '/manage-subscription',
                name: 'manageSubscription',
                component: ManageSubscription,
                meta: {
                    requiresAuth: true,
                },
                props: (route) => ({
                    product: route.query.product
                })
            },
            {
                path: '/help',
                name: 'help',
                component: Help,
                meta: {
                    requiresAuth: true
                },
                props: (route) => ({
                    loadTopic:  route.query.loadTopic,
                    loadSubTopic: route.query.loadSubTopic
                })
            },
            {
                path: '/drafts',
                name: 'drafts',
                component: Drafts,
                meta: {
                    requiresAuth: true
                },
            },
            {
                path: '/admin',
                name: 'AdminDashboard',
                component: AdminDashboard,
                meta: {
                    requiresAuth: true
                },
            },

        ]
    },
    {
        path: '/new-post',
        name: 'new-post',
        component: NewPost,
        meta: {
            requiresAuth: true
        },
        props: (route) => ({
            isDraftPost: route.params.isDraftPost,
            draftPost: route.params.draftPost,
        })
    },
    {
        path: '/new-photo-post',
        name: 'new-photo-post',
        component: NewPhotoPost,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/new-post-mobile',
        name: 'new-post-mobile',
        component: NewPostMobile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/new-contribution',
        name: 'new-contribution',
        component: NewContribution,
        meta: {
            requiresAuth: true
        },
        props: (route) => ({
            postId: route.query.pid,
            postType: route.query.postType,
            audioData: route.params.audioData,
            // groupId: route.query.gId,
            // userData: route.query.userData,
            // post: route.query.post,
        })
    },
    {
        path: '/photo-contribution',
        name: 'photo-contribution',
        component: PhotoContribution,
        meta: {
            requiresAuth: true
        },
        props: (route) => ({
            postId: route.query.id,
            postType: route.query.postType,
            groupId: route.query.gId,
            post: route.query.post,
            audio: route.query.audio,
        })
    },
    {
        path: '/intro',
        name: 'intro',
        component: LandPageA,
        // component: LandPageB,
        // component: LandPageC,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/ar-intro',
        name: 'ar-intro',
        component: LandPageArabic,
        // component: LandPageB,
        // component: LandPageC,
        meta: {
            requiresAuth: false
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        meta: { requiresAuth: false },
        component: Terms,
    },
    {
        path: '/privacy',
        name: 'Privacy',
        meta: { requiresAuth: false },
        component: Privacy,
    },
    {
        path: '/blog',
        redirect: 'intro',
        // name: 'Blog',
        meta: { requiresAuth: false },
        // component: Blog,
    },
    // {
    //     path: '/view-blog',
    //     name: 'view-blog',
    //     component: ViewBlog,
    //     meta: { requiresAuth: false },
    //     props:
    //      (route) => ({
    //         post: route.params.post,
    //     })
    // },
    {
        path: '/faq',
        name: 'FAQ',
        meta: { requiresAuth: false },
        component: FAQ,
    },
    {
        path: '*',
        redirect: '',
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = { x: 0, y: 0 };
        // if (to.hash){
        //     scrollTo = {
        //         selector: to.hash,
        //         behavior: 'smooth'
        //     }
        // } else if ( savedPosition ){
        if ( savedPosition ){
            scrollTo = {
                selector: savedPosition.y,
                offset: { x: 0, y: 0 },
                behavior: 'smooth'
            }
            // console.log("saved position: ", savedPosition.y)
        }
        return scrollTo;
    },
    routes
})

// function hasQueryParams(route) {
//     return !!Object.keys(route.query).length
// }

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    // console.log("requires auth ", requiresAuth)
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    // next();
    // if(!hasQueryParams(to) && hasQueryParams(from)){
    //     next({name: to.name, query: from.query});
    // }
    if (requiresAuth && !firebase.auth.currentUser) {
        if (to.path === '/view-post'){
            next({ name: 'post-view', query: to.query, hash: to.hash });
        }
        else if (to.path === '/view-photo-post'){
            next({ name: 'photo-post-view', query: to.query });
        }
        // this is for reload on photo-post-view
        else if (to.path === '/photo-post-view'){ 
            next();
        }
        // this is for reload on post-view
        else if (to.path === '/post-view'){ 
            next();
        }
        else {
            next('/intro');
        }
    } else if (requiresAuth && firebase.auth.currentUser) {
        if (to.path === '/post-view'){
            next({ name: 'view-post', query: to.query, hash: to.hash });
        }
        if(window.location.host === '0-0-0-dot-tawq-in-www.appspot.com'){
            // if the location is the development site, only allow Dillan, Scott, and Marty's main accounts
            if (firebase.auth.currentUser.uid === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || firebase.auth.currentUser.uid === '9hg1CKOXMFefAWLkUMaBGv7j40u1' || firebase.auth.currentUser.uid === 'lXVXV6nvtLTRWJQbtvg9zH8C1hL2' || firebase.auth.currentUser.uid === 'mEiUvmKgUNgQyJ3EAcAvqanUdyU2'){
                next();
            }
            else {
                firebase.auth.signOut().then( () => {
                    window.location.href= "https://tawq.in";
                    return;
                })
            }
        }
        if (firebase.auth.currentUser.emailVerified) {
            next()
        } else if (firebase.auth.currentUser.providerData[0].providerId === 'facebook.com'){
            // we have to put this check because when we set email verified to true for facebook accounts, it takes a while for firebase to update that for some reason.
            next();
        }
        else {
            next('/login');
        }
    } else {
        const userLang = navigator.userLanguage || navigator.language;
        if (userLang.includes('ar')) {
        // if (userLang.includes('en')) {
            if (to.path == '/sign-up') {
                next("/ar-sign-up");
            } else if (to.path == '/login') {
                next("/ar-login");
            } else if (to.path == '/manage-account') {
                const query = to.query;
                next({ path: "/ar-manage-account", query});
            } else if (to.path == '/manageaccount' || to.path == '/manageAccount') {
                const query = to.query;
                next({ path: "/ar-manage-account", query});
            } else if (to.path == '/intro') {
                next("/ar-intro");
            } else {
                next(); // because beforeEach is called again when the above next(ar) functions are called, we need to have this to actually reroute to the arabic version. Otherwise it will get stuck in a loop.
            }
        } else {
            next();
        }
    }
});


export default router