<template>
    <div>
        <Alert
            :key="NaN"
            v-if="alert"
            :dialogVal="alertIsDialog"
            :snackbarVal="alertIsSnackbar"
            :action="alertAction"
            :type="alertType"
            :title="alertTitle"
            :text="alertText"
            @dialogClicked="alertClicked"
            @snackbarClicked="alertClicked"
        ></Alert>

        <!-- Nav Bar Code -->
        <v-container class="mb-10">
            <v-app-bar v-bind:class="[$vuetify.theme.dark ? '' : 'grey lighten-3']" fixed flat app>
                <v-row justify="space-between" align="center">
                    <div class="flex-grow-1 d-flex flex-row align-center">
                        <v-btn icon @click.stop="homeDialog">
                            <v-icon color="primary" x-large>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title class="primary--text font-weight-light">tawq.in Photo Create</v-toolbar-title>
                    </div>
                    <div>
                        <router-link class="pr-8" :to="{ name: 'help', query: {loadTopic: 'createPost', loadSubTopic: (1) }  }" target="_blank" style="text-decoration: none;">
                            <v-icon :class="[$vuetify.theme.dark ? 'white--text' : 'primary--text']" size="20">mdi-help</v-icon>
                        </router-link>
                    </div>
                </v-row>
                <!-- HELP BUTTON -->
                <transition name="fade">
                    <div v-if="userData.postsCreated < 3 && showHelpIndicator" class="d-flex flex-column px-8"  style="position: absolute; bottom: -60px; right: 0px;">
                        <v-icon class="align-self-end">mdi-arrow-up</v-icon>
                        <span class="rounded-xl primary py-1 px-3">Need help? Click here.</span> 
                    </div>
                </transition>
                <!-- END HELP BUTTON -->
            </v-app-bar>
        </v-container>
        <!-- End Nav Bar Code -->

        <v-row v-show="!stepOneComplete" justify="center" class="mx-0">
            <v-col :cols="isMobile ? '12' : '4'">
                <v-row justify="center" class="mb-5">
                    <!-- <h1 class="font-weight-light text-center">Upload your content</h1> -->
                    <h2 class="font-weight-bold text-center">Upload your content</h2>
                </v-row>
                <v-form v-model="isFormValid">
                    <v-file-input
                        v-model="photoPost.photoURL"
                        accept="image/*"
                        :show-size="true"
                        flat
                        outlined
                        clearable
                        required
                        prepend-inner-icon="mdi-camera"
                        :append-outer-icon="photoPost.photoURL ? 'mdi-image' : null"
                        @click:append-outer="previewPhoto = !previewPhoto;"
                        :rules="photoRules"
                        label="Upload a photo (required)"
                        @change="previewImageFile"
                    ></v-file-input>
                    <v-img
                        v-if="previewPhoto"
                        @click.stop="previewPhoto = !previewPhoto;"
                        contain
                        :src="previewImage"
                        class="mb-5"
                    ></v-img>
                    <v-text-field
                        clearable
                        required
                        flat
                        outlined
                        :counter="50"
                        :rules="titleRules"
                        v-model="photoPost.title"
                        label="Enter Title (required)"
                    ></v-text-field>
                    <v-textarea
                        flat
                        outlined
                        clearable
                        required
                        no-resize
                        :counter="750"
                        :rules="descriptionRules"
                        v-model="photoPost.description"
                        label="Enter Description (required)"
                    ></v-textarea>
                    <v-text-field
                        flat
                        outlined
                        counter="15"
                        @keypress.enter="addTag"
                        @keypress.space="addTag"
                        v-model="tag"
                        label="Enter Tags (optional)"
                        :rules="tagRules"
                    ></v-text-field>
                    <v-chip
                        close
                        pill
                        v-for="(tag, index) in photoPost.tags"
                        :key="index"
                        class="ma-2"
                        color="primary"
                        @click:close="removeChip(tag)"
                        >
                        {{ tag }}
                    </v-chip>
                    <!-- <v-row justify="center"> -->
                    <h2 class="font-weight-bold text-center">Select Audience</h2>
                    <!-- </v-row> -->
                    <v-row justify="center">
                        <v-radio-group
                            :rules="radioGroupRules"
                            v-model="radioGroup"
                            row>
                            <v-radio
                                label="Public"
                                value="public"
                            ></v-radio>
                            <v-radio
                                label="Group"
                                value="group"
                                @click="getUserGroups"
                            ></v-radio>
                        </v-radio-group>
                    </v-row>
                    <v-row justify="center">
                        <h3 v-if="radioGroup == 'public'" class="font-weight-light text-center">Your post will be available for everyone to see</h3>
                    </v-row>
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-autocomplete
                                v-if="radioGroup == 'group'"
                                v-model="photoPost.group"
                                :items="groups"
                                persistent-hint
                                hint="Select a group from your groups"
                                return-object
                                no-data-text="No Groups to Select"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <h2 class="font-weight-bold text-center">Allow Contributions</h2>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon small class="mx-2" v-on="on">mdi-information-variant</v-icon>
                            </template>
                            <span>Contributions allow other users to add their<br/> own audio and captions to the text you've added; <br/> or create new text, audio and captions to your photo. <br/> Unselect the box below to disable contributions.</span>
                        </v-tooltip>
                    </v-row>
                    <v-row justify="center">
                        <v-checkbox v-model="photoPost.canContribute" label="Allow Contributions"></v-checkbox>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <!-- MAIN CONTENT -->
        <!-- <div class="main-div" style="border: 1px solid red;"> -->
        <v-row v-show="stepOneComplete" justify="center" class="mx-0" :class="isMobile ? 'pb-10' : null">

            <!-- TEXT BOXES LEFT SIDE -->
            <!-- :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'" -->
            <v-col :cols="isMobile ? '12' : '4'" class="border-box" :class="isMobile ? 'order-2' : 'order-1'">

                <!-- IMAGE (MAIN/TOP) TEXT BOX -->
                <v-col align="center" class="width: 100%">
                    <!-- :color="$vuetify.theme.dark ? '' : 'grey lighten-5'" -->

                    <v-btn v-if="reverseTextAdded.length > 0" @click="showAllTheText" :disabled="uploadingRecording || recording">{{showAllText ? 'hide all text' : 'show all text'}}</v-btn>

                    <v-card class="rounded-lg">
                        <v-card-title @click="showMoreText(true,imageText)" :ripple="false" :style="imageText.showMoreText ? 'cursor: pointer' : null" class="rounded-lg" :class="$vuetify.theme.dark ? 'primary' : 'primary'">{{imageText.text == "" ? "Enter text below" : imageText.text.length >= 23 ? imageText.text.match(/.{23}/)[0] + "..." : imageText.text}}</v-card-title>
                        <v-col v-if="!imageText.showMoreText" cols="12">
                            <v-text-field
                                :error="noText"
                                v-model="imageText.text"
                                outlined
                                placeholder="Enter text here"
                                @keypress.enter="addText"
                                @keypress="noText = false"
                                counter="30"
                                maxlength="30"
                            ></v-text-field>

                            <!-- RECORDING CODE -->
                            <v-col cols="12">
                                <v-row v-if="selectedText.audioURL == null && !uploadingRecording" class="mx-0" align="center" justify="space-between">
                                    <v-btn class="px-0" v-if="recording" @click.stop="pauseRecording" :color="$vuetify.theme.dark ? 'white' : 'black'" text :ripple="false">
                                        {{recording && recordingPaused ? "Play" : "Pause"}}
                                        <v-icon>{{recording && recordingPaused ? "mdi-play" : "mdi-pause"}}</v-icon>
                                    </v-btn>
                                    <v-btn class="px-0" :ripple="false" @click.stop="recordingTime" :color="recording ? 'red' : $vuetify.theme.dark ? 'white' : 'black'" text>
                                        {{recording ? "Stop" : "Record"}}
                                        <v-icon color="red">{{recording ? "mdi-stop" : "mdi-record"}}</v-icon>
                                    </v-btn>
                                    <h4>{{secondsToTime(timePlaying).h}}{{secondsToTime(timePlaying).m}}:{{secondsToTime(timePlaying).s}}</h4>
                                </v-row>
                                <v-row v-else align="center" justify="space-around" class="mx-0">
                                    <!-- :duration="100000" -->
                                    <!-- :src="selectedText.audioURL" -->                                    
                                    <AudioPlayer v-if="!uploadingRecording" style="width: 100%;" :src="selectedText.audioURL"/>
                                    <v-btn class="px-0" v-if="!uploadingRecording" large @click.stop="deleteRecording(imageText)" :ripple="false" :color="$vuetify.theme.dark ? 'white' : 'black'" text>
                                        {{"Re-record Audio"}}
                                        <v-icon small :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-replay</v-icon>
                                    </v-btn>
                                    <div v-else class="d-flex flex-column align-center">
                                        <pre class="invert--text subtitle-1 text-left font-weight-medium">{{ convertingCaption }}</pre>
                                    </div>
                                </v-row>
                                <!-- :style="[$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;']" -->
                                <v-row v-show="recording && !recordingPaused" align="center" justify="center">
                                    <canvas class="visualizer" height="40px"></canvas>
                                </v-row>
                            </v-col>
                            <!-- END RECORDING CODE -->

                            <v-textarea v-show="!uploadingRecording && !recording" v-model="selectedText.caption" :placeholder="selectedText.caption == '' ? 'Record or type to add caption' : ''" rows="2" outlined></v-textarea>

                            <!-- ADVANCED SETTINGS -->
                            <v-row v-show="!uploadingRecording && !recording" justify="center" align="center" class="mx-0" :class="[!selectedText.showMoreOptions ? 'pb-0' : 'pb-0']">
                                <h4 text @click.stop="selectedText.showMoreOptions = !selectedText.showMoreOptions" style="cursor:pointer;" class="font-weight-light accent--text">More Options</h4>
                                <v-btn icon @click="selectedText.showMoreOptions = !selectedText.showMoreOptions" :ripple="false">
                                    <v-icon>{{ selectedText.showMoreOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                </v-btn>
                            </v-row>
                            <v-col cols="12" v-if="selectedText.showMoreOptions">
                                <v-autocomplete
                                    v-show="!uploadingRecording && !recording && selectedText.audioURL == null"
                                    label="Select a language"
                                    v-model="selectedText.languageSelected"
                                    :items="$languageCodes"
                                    no-data-text="No Groups to Select"
                                    item-text="text"
                                    item-value="value"
                                    return-object
                                    outlined
                                    hint="*This will be the langauge you speak while recording"
                                    persistent-hint
                                    @change="languageChanged">
                                </v-autocomplete>
                                <v-slider
                                    v-model="selectedText.style.fontSize"
                                    class="mt-3 font-weight-bold"
                                    label="Text Size:"
                                    thumb-label="always"
                                    thumb-size="25"
                                    min="1"
                                    max="10"
                                    step=".5"
                                ></v-slider>
                                <v-color-picker
                                    :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                                    v-model="selectedText.style.textColor"
                                    mode="hexa"
                                    dot-size="20"
                                    canvas-height="75"
                                    hide-inputs
                                    hide-sliders
                                ></v-color-picker>
                            </v-col>
                            <!-- END ADVANCED SETTINGS -->

                        </v-col>

                        <v-row v-show="!uploadingRecording && !recording" v-if="selectedText == imageText">
                            <v-col>
                                <v-btn class="add-text" @click="addText" text> <v-icon>mdi-plus</v-icon>Add</v-btn>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>
                <!-- END IMAGE (MAIN/TOP) TEXT BOX -->

                <!-- TEXT ADDED BOX -->
                <v-col v-for="(text,i) in reverseTextAdded" :key='i' style="position: relative;">
                    <v-hover v-slot="{hover}">
                        <div>
                            <v-card class="rounded-lg">
                                <v-card-title @click="showMoreText(false, text)" :ripple="false" style="cursor: pointer" class="justify-space-between">{{text.text.length >= 20 ? text.text.match(/.{20}/)[0] + "..." : text.text}}
                                    <v-btn icon :ripple="false">
                                        <v-icon>{{ reverseTextAdded[i].showMoreText ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-col v-if="reverseTextAdded[i].showMoreText" cols="12">
                                    <v-text-field
                                        v-model="text.text"
                                        outlined
                                        :placeholder="text.text"
                                        counter="30"
                                        maxlength="30"
                                    ></v-text-field>

                                    <!-- RECORDING CODE -->
                                    <v-col cols="12">
                                        <!-- <h4>{{selectedText.segment}}</h4> -->
                                        <v-row v-if="selectedText.audioURL == null && !uploadingRecording" class="mx-0" align="center" justify="space-between">
                                            <v-btn class="px-0" v-if="recording" @click.stop="pauseRecording" :color="$vuetify.theme.dark ? 'white' : 'black'" text :ripple="false">
                                                {{recording && recordingPaused ? "Play" : "Pause"}}
                                                <v-icon>{{recording && recordingPaused ? "mdi-play" : "mdi-pause"}}</v-icon>
                                            </v-btn>
                                            <v-btn class="px-0" :ripple="false" @click.stop="recordingTime" :color="recording ? 'red' : $vuetify.theme.dark ? 'white' : 'black'" text>
                                                {{recording ? "Stop" : "Record"}}
                                                <v-icon color="red">{{recording ? "mdi-stop" : "mdi-record"}}</v-icon>
                                            </v-btn>
                                            <h4>{{secondsToTime(timePlaying).h}}{{secondsToTime(timePlaying).m}}:{{secondsToTime(timePlaying).s}}</h4>
                                        </v-row>
                                        <v-row v-else align="center" justify="space-around" class="mx-0">
                                            <AudioPlayer v-if="!uploadingRecording" style="width: 100%;" :src="text.audioURL"/>
                                            <v-btn class="px-0" v-if="!uploadingRecording" large @click.stop="deleteRecording(text)" :ripple="false" :color="$vuetify.theme.dark ? 'white' : 'black'" text>
                                                {{"Re-record Audio"}}
                                                <v-icon small :color="$vuetify.theme.dark ? 'white' : 'black'">mdi-replay</v-icon>
                                            </v-btn>
                                            <div v-else class="d-flex flex-column align-center">
                                                <pre class="invert--text subtitle-1 text-left font-weight-medium">{{ convertingCaption }}</pre>
                                            </div>
                                        </v-row>
                                        <!-- :style="[$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;']" -->
                                        <v-row v-show="recording && !recordingPaused" align="center" justify="center">
                                            <canvas class="visualizer" height="40px"></canvas>
                                        </v-row>
                                    </v-col>
                                    <!-- END RECORDING CODE -->

                                    <v-textarea v-show="!uploadingRecording && !recording" v-model="text.caption" :label="text.caption == '' ? 'Record or type to add caption here' : ''" rows="2" outlined></v-textarea>

                                    <!-- ADVANCED SETTINGS -->
                                    <v-row v-show="!uploadingRecording && !recording" v-model="selectedText.caption" justify="center" align="center" class="mx-0 pb-0">
                                        <h4 text @click.stop="selectedText.showMoreOptions = !selectedText.showMoreOptions" style="cursor:pointer;" class="font-weight-light accent--text">More Options</h4>
                                        <v-btn icon @click="selectedText.showMoreOptions = !selectedText.showMoreOptions" :ripple="false">
                                            <v-icon>{{ selectedText.showMoreOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                                        </v-btn>
                                    </v-row>
                                    <v-col cols="12" v-if="selectedText.showMoreOptions">
                                        <v-autocomplete
                                            v-show="!uploadingRecording && !recording && selectedText.audioURL == null"
                                            label="Select a language"
                                            v-model="selectedText.languageSelected"
                                            :items="$languageCodes"
                                            no-data-text="No Groups to Select"
                                            item-text="text"
                                            item-value="value"
                                            return-object
                                            outlined
                                            hint="*This will be the langauge you speak while recording"
                                            persistent-hint
                                            @change="languageChanged">
                                        </v-autocomplete>
                                        <v-slider
                                            v-model="selectedText.style.fontSize"
                                            class="mt-3 font-weight-bold"
                                            label="Text Size:"
                                            thumb-label="always"
                                            thumb-size="25"
                                            min="1"
                                            max="10"
                                            step=".5"
                                        ></v-slider>
                                        <v-color-picker
                                            :class="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                                            v-model="selectedText.style.textColor"
                                            mode="hexa"
                                            dot-size="20"
                                            canvas-height="75"
                                            hide-inputs
                                            hide-sliders
                                        ></v-color-picker>
                                    </v-col>
                                    <!-- END ADVANCED SETTINGS -->

                                </v-col>
                            </v-card>
                            <v-icon v-if="hover" @click="deleteTextAdded(/*text,*/i)" style="position: absolute; top:0; left:0;" class="pl-1 pt-1">mdi-close-circle-outline</v-icon>
                        </div>
                    </v-hover>
                </v-col>
                <!-- TEXT ADDED BOX -->
            </v-col>
            <!-- END TEXT BOXES LEFT SIDE -->

            <!-- IMAGE RIGHT SIDE -->
             <!-- style="border: 1px solid red;" -->
            <v-col :cols="isMobile ? '12' : '7'" class="image-div" :class="isMobile ? 'order-1' : 'order-2'" id="image-div">
                <!-- height='80vh'-->
                <!-- contain
                :aspect-ratio="16/9" -->
                <v-img
                    id="image"
                    ref="image"
                    class="image"
                    :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'"
                    style="position: relative;"
                    contain
                    :src="photoPost.photoURL ? previewImage : require('../assets/no-thumbnail.png')"
                >
                    <!-- :style="$vuetify.theme.dark ? 'border: 1px solid white;' : 'border: 1px solid black;'" -->
                    <Moveable
                        v-if="!showAllText"
                        class="moveable"
                        :style="`font-size: ${selectedText.style.fontSize}vw; color: ${selectedText.style.textColor}; transform: ${selectedText.style.transform};`"
                        id="moveable"
                        ref="moveable"
                        v-bind="moveable"
                        @drag="handleDrag"
                        @rotate="handleRotate"
                        @scale="handleScale"
                        @resize="handleResize"
                        @pinch="handlePinch"
                    >
                        <!-- :class="`${selectedText.style.textColor}--text`" -->
                        <span :style="`border: 1px solid ${selectedText.style.textColor};`">{{selectedText.text == "" ? "Enter text to begin" : selectedText.text}}</span>
                        <!-- <span :class="`${reverseTextAdded[i].textColor}--text`" :style="`font-size: ${reverseTextAdded[i].textSize}px;`">{{reverseTextAdded[i].text}}</span> -->
                    </Moveable>
                    <div v-else-if="showAllText && reverseTextAdded.length > 0">
                        <Moveable
                            v-for="(text,i) in reverseTextAdded" :key='i'
                            :style="`font-size: ${text.style.fontSize}vw; color: ${text.style.textColor}; transform: ${text.style.transform};`"
                            class="moveable"
                        >
                            <span @click="changeSelectedText(text)" :style="`border: 1px solid ${text.style.textColor};`">{{text.text == "" ? "Enter text to begin" : text.text}}</span>
                        </Moveable>
                    </div>
                    <span class="water-mark">tawq.in</span>
                </v-img>
            </v-col>
            <!-- END IMAGE RIGHT SIDE -->

        </v-row>

        <!-- DONE/NEXT/PREVIOUS BUTTONS -->
        <div class="bottom-div">
            <v-btn v-if="stepOneComplete" @click="doneDialog" :disabled="recording || uploadingRecording">done</v-btn>
            <v-btn v-else :disabled="!isFormValid || (radioGroup == 'group' && photoPost.group == null)" @click="next">next</v-btn>
        </div>
        <div v-if="stepOneComplete" class="bottom-div-2">
            <v-btn @click="previous">previous</v-btn>
        </div>
        <!-- END DONE/NEXT/PREVIOUS BUTTONS -->

        <!-- </div> -->
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
import axios from "axios";
import firebase from "../../firebaseConfig";
import Alert from "../components/Alert.vue";
import Moveable from "vue-moveable";
import AudioPlayer from "../components/AudioPlayer.vue";

export default {
    name: "new-photo-post",
    components: {
        Alert,
        Moveable,
        AudioPlayer,
    },
    data: () => ({
        userData: {},
        stepOneComplete: false,
        previewPhoto:false,
        isFormValid: false,
        textAdded: [],
        recording: false,
        uploadingRecording: false,
        recordingPaused: false,
        recordedChunks: [],
        segment: 0,
        audioStorageReference: null,
        convertingCaption: "Converting   ",
        convertingInterval: null,
        photoFileLimit: 15000000, // 6MB
        photoRules: [(v) => !!v || "Photo is required", (v) => (v && v.size <= 15000000) || "Photo Must be less than 15 MB",],
        titleRules: [(v) => !!v || "Title is required", (v) => (v && v.length <= 50) || "Title must be 50 characters or less",],
        descriptionRules: [(v) => !!v || "Description is required", (v) => (v && v.length <= 750) || "Description must be 750 characters or less",],
        tagRules: [(v) => (v.length <= 15) || "Tag must be 15 characters or less",],
        tag: "",
        radioGroupRules: [(v) => !!v || "Must select either public or group"],
        radioGroup: null,
        groups: [],
        previewImage: "",
        photoPost: {
            postId: null,
            audioDataId: null,
            userId: firebase.auth.currentUser.uid,
            username: firebase.auth.currentUser.displayName,
            userProfilePhoto: firebase.auth.currentUser.photoURL,
            photoURL: null,
            title: "",
            description: "",
            date: new Date(),
            type: null,
            tags: [],
            thumbnail: null,
            group: null,
            // isPublicPost: false,
            canContribute: true,
            languageSelected: {
                text: "English (United States)",
                value: "en-US",
            },
            imageRect: {
                height: '',
                width: '',
                top: '',
                bottom: '',
                left: '',
                right: '',
                x: '',
                y: '',
            },
        },
        moveable: {
            draggable: true,
            throttleDrag: 0.01,
            rotatable: false,
            throttleRotate: 0.01,
            scalable: false,
            throttleScale: 0.01,
            resizable: false,
            throttleResize: 0.01,
            pinchable: false, // ["draggable", "resizable", "scalable", "rotatable"]
            origin: false,//true, // set true if you wanna see dot in center
            keepRatio: false,
        },
        noText: false,
        showAllText: false,
        imageText: {
            text: "",
            date: new Date(),
            languageSelected: {
                text: "English (United States)",
                value: "en-US",
            },
            recordingTime: 0,// Saved in milliseconds
            audioURL: null,
            segment: 0,
            caption: "",
            rect: {},
            style: {
                transform: "matrix(1,0,0,1,0,0) translate(0px, 0px)",
                fontSize: "2",
                textColor: "#ffffff",
            },
            showMoreText: false,
            showMoreOptions: false,
        },
        selectedText: {},
        image: {
            rect: {},
        },
        dbRefs: {
            subtitleRef: null,
            conversionRef: null,
        },
        showHelpIndicator: false,
        alert: false,
        alertIsDialog: false,
        alertIsSnackbar: false,
        alertType: "",
        alertTitle: "",
        alertText: "",
        alertAction: "",
        timePlaying: 0,
        INTERVAL: 1000,
        audioTimer: null,
        durationHours: 0,
        durationMinutes: 0,
        durationSeconds: 0,
        recorder: null,
        canvas: null,
        canvasCtx: null,
        analyser: null,
        bufferLength: 0,
        dataArray: null,
        // currentWindowWidth: null,
    }),
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        reverseTextAdded: function() {
            return this.textAdded.slice().reverse();
        },
        // test: function() {
        //     return this.selectedText.caption === "" ? "Caption will appear here" : this.selectedText.caption;
        // }
    },
    methods: {
        appendClicked: function () {
            // this.previewPhoto = !this.previewPhoto;
            console.log("prepend clicked");
        },
        showAllTheText: function () {
            if(!this.uploadingRecording && !this.recording){
                this.showAllText = !this.showAllText;
                if(this.showAllText === false){
                    this.selectedText = this.imageText;
                    this.showMoreText(false,this.imageText);
                } else {
                    this.selectedText.showMoreText = false;
                    this.imageText.showMoreText = true;
                    this.selectedText = {};
                    // this.showMoreText(false,{},this.reverseTextAdded);
                }
            }
        },
        changeSelectedText: function (text) {
            if(!this.uploadingRecording && !this.recording){
                this.selectedText = text;
                this.showMoreText(false, text, this.reverseTextAdded);
                this.showAllText = false;
                // console.log(text);
            }
        },
        previewImageFile: function () {
            this.$window.URL.revokeObjectURL(this.previewImage);
            this.previewImage = "";
            if(this.photoPost.photoURL) {
                this.previewImage = this.$window.URL.createObjectURL(this.photoPost.photoURL);
            }
            // if (this.previewImage) {
                
            //     this.$window.URL.revokeObjectURL(this.previewImage);
            //     this.photoPost.photoURL = null;
            //     this.previewImage = "";
            //     // TODO:  validate size if you want!
            // } else {
            //     if(this.photoPost.photoURL){
            //         this.previewImage = this.$window.URL.createObjectURL(this.photoPost.photoURL);
            //     }
            //     this.profileImageUrl = this.profileImageInput;
            // }
        },
        getUserGroups: function () {
            let userDataRef = firebase.db
                .collection("userData")
                .doc(`${firebase.auth.currentUser.uid}`);
            let groupsRef = userDataRef.collection("groups");
            groupsRef
                .get()
                .then((snapshot) => {
                    snapshot.forEach((doc) => {
                        var newGroup = {
                        text: doc.data().groupName,
                        value: doc.data().groupId,
                        };
                        this.groups.push(newGroup);
                    });
                })
                .catch((err) => {
                    this.alert = true;
                    this.alertIsSnackbar = true;
                    this.alertAction = "snackbar";
                    this.alertType = "snackbar";
                    this.alertText = "Something went wrong. " + err.message;
                });
        },
        homeDialog: function () {
            this.alert = true;
            this.alertIsDialog = true;
            this.alertAction = "home";
            this.alertType = "dialog";
            this.alertTitle = "Your work will be deleted.";
            this.alertText = "Are you sure you want to quit?";
        },
        doneDialog: async function () {
            // get Rect for image
            this.photoPost.imageRect = await this.getImageRect();
            // console.log("Photo Post: ", this.photoPost.imageRect);
            // console.log("Text Added: ", this.textAdded);
            // console.log("Window Width: ", window.innerWidth);
            // console.log("Window Height: ", window.innerHeight);

            this.alert = true;
            this.alertIsDialog = true;
            this.alertAction = "done";
            this.alertType = "dialog";
            this.alertTitle = "Finish and save work?";
            this.alertText = "Are you sure you want to continue?";
        },
        alertClicked: function (value) {
            if (this.alertAction == "home"){
                if (value) {
                    if(this.textAdded.length>0){
                        this.deleteIncompletePostAudioFilesFromStorage();
                    }
                    this.$router.go(-1);
                }
            } else if (this.alertAction == "done") {
                if (value) {
                    this.done();
                }
            }
            this.alert = false;
            this.alertIsDialog = false;
            this.alertIsSnackbar = false;
            this.alertType = "";
            this.alertTitle = "";
            this.alertText = "";
            this.alertAction = "";
        },
        addTag: function () {
            if(this.tag != "" && this.tag.length <= 15 && !this.photoPost.tags.includes("#" + this.tag) && this.photoPost.tags.length < 9) {
                if(this.tag.startsWith(" ")){
                this.tag = this.tag.split(" ")[1];
                }
                if(this.tag.startsWith("#")){
                this.photoPost.tags.push(this.tag);
                } else {
                this.photoPost.tags.push("#" + this.tag);
                }
                this.tag = "";
            }
        },
        removeChip: function (tag) {
            this.photoPost.tags.splice(this.photoPost.tags.indexOf(tag), 1);
        },
        recordingTime: function () {
            this.recording = !this.recording;
            if (this.selectedText.showMoreOptions) {
                this.selectedText.showMoreOptions = false;
            }
            if(this.recording) {
                this.recorder.start();
                this.timePlaying = 0;
                this.audioTimer = setInterval(() => {
                this.timePlaying = (this.timePlaying + this.INTERVAL);
                }, this.INTERVAL);
            } else {
                this.recorder.stop();
                this.selectedText.recordingTime = this.timePlaying;
                this.timePlaying = 0;
                clearInterval(this.audioTimer);
            }
        },
        pauseRecording: function () {
            this.recorder.pause();
            this.recordingPaused = !this.recordingPaused;
            if(this.recordingPaused) {
                clearInterval(this.audioTimer);
            } else {
                this.recorder.resume();
                this.audioTimer = setInterval(() => {
                this.timePlaying = (this.timePlaying + this.INTERVAL);
                }, this.INTERVAL);
            }
        },
        handleDataAvailable: function (event) {
            if (event.data.size > 0) {
                this.recordedChunks.push(event.data);
                this.uploadAudioRec();
            }
        },
        uploadAudioRec: async function () {
            // console.log("Upload Audio Recording");
            this.uploadingRecording = true;
            var dotCount = 1;
            this.convertingInterval = setInterval(() => {
                if (dotCount > 3) {
                dotCount = 1;
                this.convertingCaption = "Converting   ";
                return;
                } else {
                dotCount++;
                this.convertingCaption = this.convertingCaption.replace(" ", ".");
                return;
                }
            }, 400);
            if (this.selectedText.recordingTime > 500) {
                // console.log("Long enough to continue");
                var blob = new Blob(this.recordedChunks, { type: "audio/webm" });
                var metaData = {
                    contentType: "audio/webm",
                    customMetadata: {
                        duration: this.selectedText.recordingTime,
                        userId: firebase.auth.currentUser.uid,
                        langCode: this.selectedText.languageSelected.value,
                    },
                };
                var storageRef = firebase.storage.ref();
                await storageRef
                    .child(this.audioStorageReference + "/" + this.selectedText.segment)
                    .put(blob, metaData)
                    .catch((err) => {
                        this.alert = true;
                        this.alertIsSnackbar = true;
                        this.alertAction = "snackbar";
                        this.alertType = "snackbar";
                        this.alertText = "Something went wrong. " + err.message;
                    });
            }
            this.clearAudioBuffer();
        },
        clearAudioBuffer: function () {
            // console.log("clearAudioBuffer");
            this.recordedChunks.length = 0;
            this.recordedChunks = [];
        },
        secondsToTime: function(secs){
            // secs = Math.round(secs);
            secs = secs/1000
            var hours = Math.floor(secs / (60 * 60));

            var divisor_for_minutes = secs % (60 * 60);
            var minutes = Math.floor(divisor_for_minutes / 60);

            var divisor_for_seconds = divisor_for_minutes % 60;
            var seconds = Math.ceil(divisor_for_seconds);
            if(seconds < 10){
                seconds = "0" + seconds;
            }

            var obj = {
                "h": hours,
                "m": minutes,
                "s": seconds
            };
            return obj;
        },
        languageChanged: function () {
            // this.selectedText.languageSelected.text = this.selectedText.languageSelected.text;
            // this.selectedText.languageSelected.value = this.selectedText.languageSelected.value;
            if (this.photoPost.languageSelected.value != null && this.photoPost.languageSelected != this.selectedText.languageSelected) {
                this.photoPost.languageSelected.text = "Multiple Languages";
                this.photoPost.languageSelected.value = null;
            } 
            // console.log(this.photoPost);
        },
        textSizeChanged: function () {
            // console.log(this.selectedText.rect);
            // console.log(this.$refs.moveable.getRect());
            // this.selectedText.rect = this.$refs.moveable.getRect();
            // console.log(this.selectedText.style);
        },
        addText: function () {
            if(!this.uploadingRecording) {
                if (this.recording) {
                    this.recordingTime();
                }
                else if (this.imageText.text != "" && this.imageText.text.length <= 30){
                    this.segment++;
                    this.$refs.moveable.updateRect();
                    this.imageText.rect = this.$refs.moveable.getRect();
                    // console.log("moveable getrect(): ", this.imageText.rect);
                    this.textAdded.push(this.imageText);
                    this.imageText =  {
                        text: "",
                        date: new Date(),
                        languageSelected: this.selectedText.languageSelected,
                        recordingTime: 0,
                        audioURL: null,
                        segment: this.segment,
                        caption: "",
                        rect: {},
                        style: {
                            transform: "matrix(1,0,0,1,0,0) translate(0px, 0px)",
                            fontSize: "2",
                            textColor: "#ffffff",
                        },
                        showMoreText: false,
                        showMoreOptions: false,
                    }
                    this.selectedText = this.imageText;
                    // console.log(this.textAdded);
                } else {
                    this.noText = true;
                }
            }
        },
        addTextAsync: async function () {
            return new Promise((resolve) => {
                this.segment++;
                this.$refs.moveable.updateRect();
                this.imageText.rect = this.$refs.moveable.getRect();
                this.textAdded.push(this.imageText);
                this.imageText =  {
                    text: "",
                    date: new Date(),
                    languageSelected: this.selectedText.languageSelected,
                    recordingTime: 0,
                    audioURL: null,
                    segment: this.segment,
                    caption: "",
                    rect: {},
                    style: {
                        transform: "matrix(1,0,0,1,0,0) translate(0px, 0px)",
                        fontSize: "2",
                        textColor: "#ffffff",
                    },
                    showMoreText: false,
                    showMoreOptions: false,
                }
                this.selectedText = this.imageText;
                // console.log(this.textAdded);
                resolve(true);
            });
        },
        showMoreText: function (top, text){
            if(this.showAllText === true) {
                this.showAllText = false;
            }
            //true == top(imageText), false == bottom(addedText)
            if(!this.recording && !this.uploadingRecording) {
                if(top && !text.showMoreText) {
                    // if the top text box is already open
                    this.selectedText = this.imageText;
                } else if (top && text.showMoreText) {
                    // if top text box is closed, another is open and must be closed before reopening top text box
                    this.reverseTextAdded.forEach((text)=>{
                        if(text.showMoreText) {
                            text.showMoreText = !text.showMoreText;
                        }
                    });
                    this.imageText.showMoreText = false;
                    this.selectedText = this.imageText;
                } else {
                    // bottom box is clicked
                    if(!text.showMoreText) {
                        // closed bottom text box is clicked. Check all other text boxes and close if open.
                        this.reverseTextAdded.forEach((text)=>{
                            if(text.showMoreText) {
                                text.showMoreText = !text.showMoreText;
                            }
                        });
                        // then open clicked text box, close top text box, set selectedText to clicked text box
                        text.showMoreText = !text.showMoreText;
                        this.imageText.showMoreText = true;
                        this.selectedText = text;
                    } else {
                        // open bottom text box is clicked.
                        // close text box, open top text box, set selectedText to top text box
                        text.showMoreText = !text.showMoreText;
                        this.imageText.showMoreText = false;
                        this.selectedText = this.imageText;
                    }
                }
            }
        },
        deleteTextAdded: function (/*text,*/ index) {
            // console.log(this.textAdded[(this.textAdded.length-1) - index].segment);
            if (this.textAdded[(this.textAdded.length-1) - index].audioURL != null) {
                this.deleteRecording(this.textAdded[(this.textAdded.length-1) - index])
            }
            this.textAdded.splice(((this.textAdded.length-1) - index), 1);
            this.imageText.showMoreText = false;
            this.selectedText = this.imageText;
        },
        deleteIncompletePostAudioFilesFromStorage: function () {
            // delete the audiofile from audioSegments/postId/audioDataId
            let audioDataRef = firebase.storage.ref(
                `audioSegments/${this.photoPost.postId}/${this.photoPost.audioDataId}`
            );
            audioDataRef.list().then((res) => {
                res.items.forEach(function (itemRef) {
                /* retrieve the file names of each segment, so that we can delete each file
                    individually from the storage bucket. GCS doesn't allow us to delete the full bucket */

                let filename = itemRef.name;
                let audioRef = audioDataRef.child(filename);
                audioRef
                    .delete()
                    .then(() => {
                    return;
                    })
                    .catch(function (error) {
                    console.error(
                        `Error Deleting Audio Segment File ${filename} from Storage:`,
                        error
                    );
                    return;
                    });
                });
            });
        },
        handleDrag( {target, transform} ) {
            // console.log(transform);
            // console.log(this.$refs.image);
            // this.selectedText.rect = this.$refs.moveable.getRect();
            // if(this.selectedText.rect.left >= this.$refs.image.$el.offsetLeft){
            target.style.transform = transform;
            this.selectedText.style.transform = transform;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
            // console.log(this.selectedText.style.transform);
        },
        handleRotate({ target, /*dist,*/ transform }) {
            // console.log('onRotate', dist);
            target.style.transform = transform;
            this.selectedText.style.transform = transform;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
        },
        handleScale({ target, transform/*, scale*/ }) {
            console.log('handleScale called');
            // console.log('onScale scale', scale);
            target.style.transform = transform;
            this.selectedText.style.transform = transform;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
        },
        handleResize({target, width, height, delta,}) {
            // console.log("Width: ", width);
            // console.log('onResize', width, height);
            // console.log('onResize', delta);
            // console.log(target);
            delta[0] && (target.style.width = `${width}px`);
            delta[1] && (target.style.height = `${height}px`);
            this.selectedText.style.width = `${width}px`;
            this.selectedText.style.height = `${height}px`;
            this.$refs.moveable.updateRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
            // console.log(this.selectedText.rect.width);
        },
        handlePinch({ target }) {
            console.log('onPinch', target);
        },
        // handleWarp({ target, transform }) {
        //     console.log('onWarp', transform);
        //     target.style.transform = transform;
        // },
        resizeWindow: function (/*event*/) {
            var oldWidth = this.image.rect.width;
            var oldHeight = this.image.rect.height;
            var image = document.getElementById("image");
            this.image.rect = image.getBoundingClientRect();
            this.selectedText.rect = this.$refs.moveable.getRect();
            // console.log("Old Width", oldWidth);
            // console.log("Old Height", oldHeight);
            // console.log("New Width: ",this.image.rect.width);
            // console.log("New Height: ",this.image.rect.height);
            var target = this.$refs.moveable.$el;
            // var width = this.selectedText.rect.offsetWidth;
            // var height = this.selectedText.rect.offsetHeight;
            // console.log(target);
            if (oldWidth != this.image.rect.width && oldHeight != this.image.rect.height) {
                // console.log(Math.min(this.selectedText.rect.offsetWidth * this.image.rect.width) / oldWidth);
                // console.log(Math.min(oldWidth % this.image.rect.width));
                var width = (this.selectedText.rect.offsetWidth * this.image.rect.width) / oldWidth;
                var height = (this.selectedText.rect.offsetHeight * this.image.rect.height) / oldHeight;
                this.handleResize({target, width, height})
                this.selectedText.rect = this.$refs.moveable.getRect();
            }
            /*else if (oldWidth > this.image.rect.width && oldHeight <= this.image.rect.height) {
                console.log("Width");
                width = (this.selectedText.rect.offsetWidth * this.image.rect.width)/ oldWidth;
            } else if (oldWidth <= this.image.rect.width && oldHeight > this.image.rect.height) {
                console.log("Height");
                height = (this.selectedText.rect.offsetHeight * this.image.rect.height)/ oldHeight;
            }*/
        },
        updateRefs: function (userId) {
            this.dbRefs.subtitleRef = firebase.rtDb.ref(`${userId}/subtitle`);
            this.dbRefs.conversionRef = firebase.rtDb.ref(`${userId}/conversion`);
        },
        clearRTDB: function (userId) {
            this.dbRefs.subtitleRef.off();
            this.dbRefs.conversionRef.off();
            const allRefs = firebase.rtDb.ref(userId);
            allRefs.remove();
        },
        setAudioURL: function (filename, location) {
            // console.log("setAudioURL");
            var storageRef = firebase.storage.ref();
            // storageRef.child(`audioSegments/${this.postData.postId}/${this.postData.audioDataId}/${filename}`)
            // console.log(location);
            // console.log(location, '/', filename);
            storageRef.child(`${location}`).getDownloadURL().then((url) => {
                // console.log(url);
                this.selectedText.audioURL = url;
                this.uploadingRecording = false;
                clearInterval(this.convertingInterval);
            }).catch((error) => {
                // console.log('ref', ref);
                console.log("The error is: ", error.message);
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText =
                    "Oops. Couldn't retrieve your audio segments at this time. Try again." +
                    error.message;
            });
        },
        deleteRecording: function (text) {
            // console.log(text);
            // console.log(this.selectedText.audioURL);
            var storageRef = firebase.storage.ref("audioSegments");
            var audioRef = storageRef.child(
                `/${this.photoPost.postId}/${this.photoPost.audioDataId}/${text.segment}`
            );
            audioRef
                .delete()
                .then(() => {
                    // this.$emit("audioData", this.audioData);
                    // console.log("Deleted");
                }) //end of .then()
                .catch((err) => {
                    // alert("Error deleting audio segment", err);
                    console.log(err.message);
                    this.alert = true;
                    this.alertIsSnackbar = true;
                    this.alertAction = "snackbar";
                    this.alertType = "snackbar";
                    this.alertText = "Something went wrong." + err;
                });
            // 
            // go delete the local list of audioData
            // 
            this.selectedText.audioURL = null;
            this.selectedText.caption = "";
            this.selectedText.recordingTime = 0;
        },
        next: function () {
            if(this.isFormValid){
                if (this.radioGroup == 'group') {
                    // this.photoPost.isPublicPost = false;
                    this.photoPost.type = "group";
                } else {
                    // this.photoPost.isPublicPost = true;
                    this.photoPost.type = "public";
                }
                this.stepOneComplete = true;
                // console.log(this.photoPost);
            }
        },
        previous: function () {
            this.stepOneComplete = false;
        },
        done: async function () {
            if (this.imageText.text != "" && this.imageText.text.length <= 30) {
                await this.addTextAsync();
            }

            this.$store.dispatch('newPhotoPostStore/updateTextAdded', this.textAdded);
            this.$store.dispatch('newPhotoPostStore/setIsPublic', this.radioGroup === 'public' ? true : false );

            this.$store.dispatch('newPhotoPostStore/savePhotoPost', this.photoPost);
            this.$router.push({path: this.photoPost.type === 'group' ? 'groups' : 'profile'});

            // this.photoPost.thumbnail = await this.uploadThumbnail();

            // save post data to firebase database
            // let newPostRef = firebase.db.collection( this.photoPost.isPublicPost ? "publicPhotoPosts" : "groupPhotoPosts").doc(this.photoPost.postId);
            // newPostRef.set({
            //     isPhoto: true,
            //     title: this.photoPost.title,
            //     description: this.photoPost.description,
            //     postDate: this.photoPost.date,
            //     postType: this.photoPost.type,
            //     tags: this.photoPost.tags,
            //     postId: this.photoPost.postId,
            //     audioDataId: this.photoPost.audioDataId,
            //     userId: this.photoPost.userId,
            //     username: this.photoPost.username,
            //     userProfilePhoto: this.photoPost.userProfilePhoto,
            //     thumbnail: this.photoPost.thumbnail,
            //     languageName: this.photoPost.languageSelected.text,
            //     imageRect: this.photoPost.imageRect,
            //     groupId: this.photoPost.group ? this.photoPost.group.value : null,
            //     groupName: this.photoPost.group ? this.photoPost.group.text : null,
            //     canContribute: this.photoPost.canContribute,
            // },{merge: true});
            // newPostRef.collection("contributions").doc(this.photoPost.userId).set({
            //     audioDataId: this.photoPost.audioDataId,
            // },{merge: true});
            // create object for each textAdded with data to save to firebase database
            // var textAdded = await this.createTextObjects();
            // var newAudioRef = firebase.db.collection("photoAudioSegments").doc(this.photoPost.audioDataId);
            // newAudioRef.set({
            //       title: this.photoPost.title,
            //       userId: this.photoPost.userId,
            //       username: this.photoPost.username,
            //       userProfilePhoto: this.photoPost.userProfilePhoto,
            //       postId: this.photoPost.postId,
            //       text: textAdded,
            //       date: this.photoPost.date,
            //       languageSelected: this.photoPost.languageSelected,
            //       audioDataId: this.photoPost.audioDataId,
            // },{ merge: true });
            // this.uploading = false;
        },
        uploadThumbnail: function () {
            // get the thumbnail from the file input
            return new Promise(async (resolve) => {
                var data = new FormData();
                data.append("file", this.photoPost.photoURL);
                data.append("postId", this.photoPost.postId);
                let response = await axios.post(`uploadpic`, data);
                resolve(response.data.url);
            });
        },
        getImageRect: function () {
            return new Promise((resolve) => {
                var rect = {
                    height: '',
                    width: '',
                    top: '',
                    // bottom: '',
                    left: '',
                    // right: '',
                    x: '',
                    y: '',
                };
                var image = document.getElementById("image");//.getBoundingClientRect();
                // console.log("Image: ", image.offsetBottom);
                // var imageRect = image.getBoundingClientRect();
                // console.log("Image Rect: ", imageRect);
                rect.height = image.clientHeight;
                rect.width = image.clientWidth;
                rect.top = image.offsetTop;
                // rect.bottom = imageRect.bottom;
                rect.left = image.offsetLeft;
                // rect.right = imageRect.right;
                rect.x = image.offsetLeft;
                rect.y = image.offsetTop;
                // console.log("Rect: ", rect);
                resolve(rect);
            });
        },
        createTextObjects: function () {
            return new Promise((resolve) => {
                var objects = [];
                this.textAdded.forEach((text) => {
                    var object = {
                        text: text.text,
                        date: text.date,
                        languageSelected: text.languageSelected,
                        recordingTime: text.recordingTime,
                        audioURL: text.audioURL,
                        segment: text.segment,
                        caption: text.caption,
                        rect: text.rect,
                        style: text.style,
                    };
                    objects.push(object);
                });
                resolve(objects);
            });
        },
        visualize: function (stream) {
            if(!this.audioCtx) {
                this.audioCtx = new AudioContext();
            }

            var source = this.audioCtx.createMediaStreamSource(stream);

            this.analyser = this.audioCtx.createAnalyser();
            this.analyser.fftSize = 2048;
            this.bufferLength = this.analyser.frequencyBinCount;
            this.dataArray = new Uint8Array(this.bufferLength);

            source.connect(this.analyser);
            // this.analyser.connect(this.audioCtx.destination);

            this.draw();
        },
        draw: function () {
            this.canvas = document.querySelector('.visualizer');
            if(this.canvas == null) {
                return;
            } else {
                this.canvasCtx = this.canvas.getContext("2d");
                var WIDTH = this.canvas.width
                var HEIGHT = this.canvas.height;

                requestAnimationFrame(this.draw);

                this.analyser.getByteTimeDomainData(this.dataArray);

                this.canvasCtx.fillStyle = this.$vuetify.theme.dark ? 'rgb(30, 30, 30)' : 'rgb(256, 256, 256)';
                this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

                this.canvasCtx.lineWidth = 2;
                this.canvasCtx.strokeStyle = this.$vuetify.theme.dark ? 'rgb(256, 256, 256)' : 'rgb(28, 30, 30)';

                this.canvasCtx.beginPath();

                let sliceWidth = WIDTH * 1.0 / this.bufferLength;
                let x = 0;


                for(let i = 0; i < this.bufferLength; i++) {
                    let v = this.dataArray[i] / 128.0;
                    let y = v * HEIGHT/2;
                    if(i === 0) {
                        this.canvasCtx.moveTo(x, y);
                    } else {
                        this.canvasCtx.lineTo(x, y);
                    }
                    x += sliceWidth;
                }
                
                this.canvasCtx.lineTo(this.canvas.width, this.canvas.height/2);
                this.canvasCtx.stroke();
            }
        },
        disableMicrophone: function() {
            if(this.recorder) {
                if (this.recorder.stream != undefined) {
                    this.recorder.stream.getTracks().forEach((track) => track.stop());
                }
            }
            delete this.recorder;
        }
    },
    mounted: function () {
        // var image = document.getElementById("image");
        // this.image.rect = image.getBoundingClientRect();
        // console.log("Mounted Width: ",this.image.rect.width);
        // console.log("Mounted Height: ",this.image.rect.height);
        // this.selectedText.rect = this.$refs.moveable.getRect();
        // console.log(this.selectedText);

        this.$store.dispatch('newPhotoPostStore/setUser');

        this.canvas = document.querySelector('.visualizer');
        this.canvasCtx = this.canvas.getContext("2d");
        this.updateRefs(firebase.auth.currentUser.uid);
        this.audioStorageReference = `audioSegments/${
            this.photoPost.postId
          }/${this.photoPost.audioDataId}`;
        if (this.$navigator.mediaDevices){
            // console.log('getUserMedia supported.');
            var constraints = { audio: true };
            this.$navigator.mediaDevices.getUserMedia( constraints )
            .then((stream) => {
                var options = {
                    mimeType: "audio/webm",
                    audioBitsPerSecond: 128000,
                };
                // console.log("about to call MediaRecorder() in mounted, stream is ready")
                // console.log(MediaRecorder.isTypeSupported('audio/webm'))
                this.recorder = new MediaRecorder(stream, options);
                this.visualize(stream);
                // console.log("the media recorder in mounted: ", this.recorder);
                this.recorder.addEventListener('dataavailable', this.handleDataAvailable);
                // this.recorder.ondataavailable = this.handleDataAvailable;
            })
            .catch((err) => {
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText = "Something went wrong. " + err.message;
            });

        } // if the user device allows us to get their media
        
        // REALTIME DB LISTENERS
        this.dbRefs.subtitleRef.on("child_added", (snapshot) => {
                // console.log("snapshot val = ", snapshot.val() );
                // console.log(snapshot.val());
                if (snapshot.val() == null) {
                    return;
                } else {
                    // this.loadingCaption = false;
                    // this.$emit('loadingCaption', false);
                    // clearInterval(this.convertingInterval);
                    this.selectedText.caption = snapshot.val().subtitle;
                }
            }, (err) => {
                console.log(err.code);
                //display an error message
            }
        );

        this.dbRefs.conversionRef.on("child_added", (snapshot) => {
                if (snapshot.val() == null) {
                    return;
                } else {
                    let filename = snapshot.val().filename;
                    let location = snapshot.val().location;
                    /* we need to retrieve the url's for the audioSegments here, because the cloud function converts them to mp4 
                    which will change the the download URL that they originally have when uploaded to storage */
                    this.setAudioURL(filename, location);
                }
            }, (err) => {
                this.alert = true;
                this.alertIsSnackbar = true;
                this.alertAction = "snackbar";
                this.alertType = "snackbar";
                this.alertText = err.message;
            }
        );
        // END REALTIME DB LISTENERS
    },
    created: function() {
        this.updateRefs(firebase.auth.currentUser.uid);
        this.clearRTDB(firebase.auth.currentUser.uid);
        this.photoPost.postId = firebase.db.collection("photoPosts").doc().id;
        this.photoPost.audioDataId = firebase.db.collection(`audioSegments`).doc().id;
        this.userData = this.$store.state.userData;
        this.photoPost.languageSelected = this.userData.languageSelected;
        this.imageText.languageSelected = this.userData.languageSelected;
        this.selectedText = this.imageText;
    },
    beforeDestroy: function() {
        this.clearRTDB(firebase.auth.currentUser.uid);
        // this.canvas = document.querySelector('.visualizer');
        // this.canvas.clear();
    },
    destroyed: function() {
        this.disableMicrophone();
    },
};
</script>

<style>
.moveable {
    display: inline-block !important;
    position: absolute !important;
    cursor: move !important;
    /* border: 1px solid var(--v-primary-base) !important; */
    /* font-family: "Verdana", sans-serif; */
    /* font-size: auto !important;
    width: auto !important;
    text-align: center; */
    top: 50% !important;
    left: 50% !important;
}
.moveable-control {
    display: none !important;
}
.moveable-line {
    display: none !important;
}
.moveable-line.moveable-rotation-line {
    display: none !important;
}
.image {
    position: relative;
    max-height: 85vh;
}
.bottom-div {
    /* border: 1px solid orange; */
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 25px;
    margin-right: 25px;
}
.bottom-div-2 {
    /* border: 1px solid orange; */
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 25px;
    margin-left: 25px;
}
.uploading {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
}
.add-text {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 5px;
    margin-right: 5px;
}
.border-box {
    max-height: 80vh;
    overflow: auto;
    /* background-color: #616161; */
    /* background-color: #BDBDBD; */
    /* background-color: #6B6E70; */
    /* border: 1px solid yellow; */
}
.water-mark {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 5px;
    margin-right: 5px;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.5);
}
</style>