<template>
    <v-container fluid>
        <Navbar @search="getSearchedPosts" @toggleDrawer="toggleDrawer" @toggle-home="$router.push({ path: 'home' }).catch(error =>{return})"/>
        <Drawer />
        <v-main>
            <router-view />
        </v-main>
    </v-container>
</template>

<script>
import firebase from "../../firebaseConfig.js";
import Navbar from "../components/Navbar.vue";
import Drawer from "../components/Drawer.vue";
export default {
    name: "dashboard",
    components: {
        Navbar,
        Drawer,
    },
    data: function () {
        return {
            searchParam: "",
            currentUser: firebase.auth.currentUser,
        };
    },
    methods: {
        getSearchedPosts: function (params) {
            this.$refs.dashState.searchStr = params;
        },
        toggleDrawer: function (value) {
            this.$store.commit("changeDrawer", value);
        },
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
    },
    created: function () {
        if (this.isMobile) this.toggleDrawer(false); // used to initially set the drawer open value to false when using mobile device
    },
    // updated: function() {},
};
</script>