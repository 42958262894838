<template>
    <v-container fluid>
        <!-- Nav Bar Code -->
        <v-app-bar v-bind:class="[$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3']" app>

            <v-row justify="space-between" align="center" no-gutters>
                <v-col cols="10" class="ma-0">
                    <v-row align="center" no-gutters>
                        <v-btn icon @click.stop="homeDialog = true;">
                            <v-icon color="primary" x-large>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title class="ml-4 primary--text font-weight-light">tawq.in Create</v-toolbar-title>
                    </v-row>
                </v-col>
                <!-- <v-col cols="2" align="end" class="ma-0">
                    <router-link class="pr-5" :to="{ name: 'help', query: {loadTopic: 'createPost', loadSubTopic: (currentStep - 1)} }" target="_blank" style="text-decoration: none;">
                        <v-icon :class="[$vuetify.theme.dark ? 'white--text' : 'primary--text']" size="20">mdi-help</v-icon>
                    </router-link>
                </v-col> -->
            </v-row>

            <!-- HELP BUTTON -->
            <!-- <transition name="fade">
                <div v-if="userData.postsCreated < 3 && showHelpIndicator" class="d-flex flex-column px-8"  style="position: absolute; bottom: -60px; right: 0px;">
                    <v-icon class="align-self-end">mdi-arrow-up</v-icon>
                    <span class="rounded-xl primary py-1 px-3">Need help? Click here.</span> 
                </div>
            </transition> -->
        </v-app-bar>
        <!-- End Nav Bar Code -->

        <div v-if="loading" class="payment-loading-container ma-0 pa-0">
            <v-overlay absolute>
                <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
            </v-overlay>
        </div>
        
        <!-- Create Form Stepper Code -->
        <v-row justify="center" no-gutters class="mt-12 mt-lg-15">
            <v-col cols="12" xl="10" lg="10" md="10" sm="12">

                <!-- GO BACK HOME DIALOG -->
                <v-dialog v-if="homeDialog && currentStep > 1 || isDraftPost || hasAudio || createPost.transcoded" v-model="homeDialog" absolute persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
                    <v-card class="rounded-xl pa-2">
                        <v-card-title v-if="!isDraftPost" style="word-break: normal">
                            <span class="text-wrap text-h5 font-weight-bold">Are you sure you want to Exit?</span>
                            <span class="font-weight-light text-subtitle-1 mt-3">To save your post as a draft that you can continue later, click Save as Draft.</span>
                            <span class="font-weight-light text-subtitle-1 mt-3">By selecting Delete, all your progress will be <span class="font-weight-bold">permanently</span> lost.</span>
                            <!-- <span v-if="!checkButtonValidity" class="font-weight-light text-subtitle-1 mt-3">Can't Save as Draft or Delete while converting is in progress.</span>
                            <div v-else>
                                <span class="font-weight-light text-subtitle-1 mt-3">To save your post as a draft that you can continue later, click Save as Draft.</span>
                                <span class="font-weight-light text-subtitle-1 mt-3">By selecting Delete, all your progress will be <span class="font-weight-bold">permanently</span> lost.</span>
                            </div> -->
                        </v-card-title>
                        <v-card-title v-else style="word-break: normal">
                            <span class="text-wrap text-h5 font-weight-bold">Are you sure you want to Exit?</span>
                            <p class="font-weight-light text-subtitle-1 mt-3">To save your current work and continue later, click Save Draft.</p>
                            <span class="font-weight-light text-subtitle-1 mt-3">By selecting Delete Draft, all your progress will be <span class="font-weight-bold">permanently</span> lost.</span>
                        </v-card-title>

                        <v-card-actions class="hidden-sm-and-down">
                            <v-btn color="primary" text @click="homeDialog = false">Cancel</v-btn>
                            <v-row justify="end" no-gutters>
                                <v-btn color="primary" text :disabled="checkDeleteAndDraftButtonValidity" @click="savePostAsDraft()">{{ isDraftPost ? 'Save Draft' : 'Save as Draft'}}</v-btn>
                                <v-btn color="red" text :disabled="checkDeleteAndDraftButtonValidity" @click="goBackHomePage()">{{ isDraftPost ? 'Delete Draft' : 'Delete'}}</v-btn>
                            </v-row>
                        </v-card-actions>

                        <v-card-actions class="hidden-md-and-up">
                            <v-btn color="primary" small text @click="homeDialog = false">Cancel</v-btn>
                            <v-row justify="end" no-gutters>
                                <v-btn color="primary" small text :disabled="checkDeleteAndDraftButtonValidity" @click="savePostAsDraft()">{{ isDraftPost ? 'Save Draft' : 'Save as Draft'}}</v-btn>
                                <v-btn color="red" small text :disabled="checkDeleteAndDraftButtonValidity" @click="goBackHomePage()">{{ isDraftPost ? 'Delete Draft' : 'Delete'}}</v-btn>
                            </v-row>
                        </v-card-actions>

                    </v-card>
                </v-dialog>

                <v-dialog v-else v-model="homeDialog" absolute persistent max-width="600" overlay-color="grey darken-1" overlay-opacity=".5">
                    <!-- v-if="homeDialog && currentStep == 1 && !isDraftPost" -->
                    <v-card class="rounded-xl pa-2">
                        <v-card-title style="word-break: normal">
                            <span class="text-wrap text-h5 font-weight-bold">Are you sure you want to Exit?</span>
                            <p class="font-weight-light text-subtitle-1 mt-3">By selecting Delete, all your progress will be <span class="font-weight-bold">permanently</span> lost.</p>
                        </v-card-title>

                        <v-card-actions>
                            <v-row justify="end" no-gutters>
                                <v-btn color="primary" text @click="homeDialog = false">Cancel</v-btn>
                                <v-btn color="red" text @click="goBackHomePage()">Delete</v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- END OF GO BACK HOME DIALOG -->
            
                <!--NEW POST STEPPER -->
                <v-stepper v-model="currentStep" style="box-shadow: none; background: none;" class="font-weight-light">
                    <v-stepper-header style="box-shadow: none;">
                        <template v-for="n in totalSteps">
                            <v-stepper-step :key="`${n}-step`" :edit-icon="'mdi-check-bold'" :complete="currentStep >= n" :step="n"></v-stepper-step>
                            <v-divider v-if="n != totalSteps" :key="n" :class="[currentStep >= n  ? 'primary' : '']"></v-divider>
                        </template>
                    </v-stepper-header>

                    <v-stepper-items class="ma-0 pa-0">
                        <!-- MEDIA SELECTION STEP -->
                        <v-stepper-content :step="newPostStepEnum.MEDIA_SELECTION" :key="'step-' + newPostStepEnum.MEDIA_SELECTION" class="ma-0 pa-0">
                            <PostContentMediaSelection 
                                v-if="currentStep === newPostStepEnum.MEDIA_SELECTION"
                                :languageCodes="languageCodes" 
                                :isDraftPost="isDraftPost"
                                :draftPost="draftPost"
                            />
                            <VideoCodecProcessingDialog
                                v-if="videoCodecProcessingDialog"
                                :dialog="videoCodecProcessingDialog"
                                @earlyUploadSaveAsDraft="earlyUploadSaveAsDraft"
                                @cancelVideoCodecProcessingDialog="cancelVideoCodecProcessingDialog"
                            />
                        </v-stepper-content>
                        <!-- END MEDIA SELECTION STEP -->

                        <!-- POST CONTENT CREATION STEP -->
                        <v-stepper-content :step="newPostStepEnum.CONTENT_CREATION" :key="'step-' + newPostStepEnum.CONTENT_CREATION" class="ma-0 pa-0">
                            <PostContentCreation
                                v-if="currentStep === newPostStepEnum.CONTENT_CREATION"
                                ref="postContentCreation"
                                :previewVideo="previewVideo"
                                :isDraftPost="isDraftPost"
                                @setCloudFunctionTimeout="setCloudFunctionTimeout"
                                @clearCloudFunctionTimeout="clearCloudFunctionTimeout"
                                @clearAllCloudFunctionTimeouts="clearAllCloudFunctionTimeouts"
                            />
                        </v-stepper-content>
                        <!-- END OF POST CONTENT CREATION STEP -->

                        <!-- CONTENT METADATA STEP -->
                        <v-stepper-content :step="newPostStepEnum.CONTENT_METADATA" :key="'step-' + newPostStepEnum.CONTENT_METADATA" class="ma-0 pa-0">
                            <PostContentMetadata
                                v-if="currentStep === newPostStepEnum.CONTENT_METADATA"
                                :isDraftPost="isDraftPost"
                                :draftPost="draftPost"
                                @setDeleteDraftPostThumbnail="deleteDraftPostThumbnail = true"
                            />
                        </v-stepper-content>
                        <!-- END OF CONTENT METADATA STEP -->

                        <!-- BUTTONS FOR MD AND UP -->
                        <v-row justify="center" align="center" no-gutters class="hidden-sm-and-down mt-5">
                            <v-col cols="4">
                                <v-btn rounded outlined depressed color="primary" large :disabled="currentStep === 1" @click="previousStep()" class="font-weight-light">Previous</v-btn>
                            </v-col>
                            <v-col cols="4" align="end">
                                <v-btn v-if="currentStep != totalSteps" rounded depressed color="primary" large :disabled="checkButtonValidity" @click="nextStep()" class="font-weight-light">Next</v-btn>
                                <v-btn v-else rounded depressed color="primary" large :disabled="checkButtonValidity" @click="saveStep()" class="font-weight-light">Save</v-btn>
                            </v-col>
                        </v-row>

                        <!-- BUTTONS FOR SM AND DOWN -->
                        <v-row justify="center" align="center" no-gutters class="hidden-md-and-up mt-5">
                            <v-col cols="6">
                                <v-btn rounded outlined depressed color="primary" medium :disabled="currentStep === 1" @click="previousStep()" class="font-weight-light">Previous</v-btn>
                            </v-col>
                            <v-col cols="6" align="end">
                                <v-btn v-if="currentStep != totalSteps" rounded depressed color="primary" medium :disabled="checkButtonValidity" @click="nextStep()" class="font-weight-light">Next</v-btn>
                                <v-btn v-else rounded depressed color="primary" medium :disabled="checkButtonValidity" @click="saveStep()" class="font-weight-light">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-stepper-items>
                </v-stepper>
            </v-col>
        </v-row>
        <!-- End Create Form Stepper Code -->
    </v-container>
</template>

<script>
import firebase from "../../firebaseConfig";
import PostContentCreation from "../components/PostContentCreation";
import PostContentMediaSelection from "../components/PostContentMediaSelection";
import PostContentMetadata from "../components/PostContentMetadata";
import VideoCodecProcessingDialog from "../components/VideoCodecProcessingDialog";

export default {
    name: "new-post",
    metaInfo: {
        title: "Create Post - tawqin"
    },
    props: [ "isDraftPost", "draftPost" ], // these props are only passed when navigating from a previously created draft post (DraftPost.vue)
    components: {
        PostContentCreation,
        PostContentMediaSelection,
        PostContentMetadata,
        VideoCodecProcessingDialog,
    },
    data() {
        return {
            loading: false,
            homeDialog: false,
            totalSteps: Object.keys(this.$store.state.newPost.newPostStep).length,
            completed: false,
            previewVideo: {
                src: null,
                type: null,
            },
            languageCodes: [
                {
                    text: "None (Disable Auto Captions)",
                    value: "null",
                },
            ],
            deleteDraftPostThumbnail: false,
            cloudFunctionTimeoutIds: [],
            // showHelpIndicator: true,
        };
    },
    methods: {
        goBackHomePage: function () {
            this.completed = false;
            this.homeDialog = false;
            this.clearAllCloudFunctionTimeouts();
            this.clearRTDB(firebase.auth.currentUser.uid);
            // this.$router.replace({ path: "home" }); 
            this.$router.go(-1); // navigate user back to page they were on before clicking new post button
        },
        nextStep: async function () {
            if (this.currentStep === this.newPostStepEnum.MEDIA_SELECTION && this.mediaSelectionStep.complete) { // handle going on from MEDIA SELECTION STEP (step 1 to step 2)
                if (!this.userData.pushToTawq) { // get permissions from new post if !pushToTawq (pickupToTawq = true)
                    // push to tawq -> will ask microphone permissions (not orientation) in push to tawq
                    // !push to tawq (pick up to tawq) -> asks orientation permissions
                    let proceed = await this.getPermissions();
                    if (!proceed) return; // dont proceed, didn't get permissions or user didn't allow
                }

                if (this.mediaSelectionStep.fileSelected) { // local file selected
                    // In this if statement we need to check to see if the video has been transcoded,
                    // if it HAS been transcoded then we need to set the previewVideo.src to the newly
                    // transcoded url from google cloud storage. In this case we also need to make sure
                    // that when the user pushes previousStep that we DON'T revokeObjectURL?!?!
                    // The above line might not need to happen!
                    // Else we set it normally, as shown below
                    if (this.createPost.transcoded) {
                        this.previewVideo.src = this.createPost.videoUrl;
                        this.previewVideo.type = this.createPost.video.type;
                    } else {
                        this.previewVideo.src = this.$window.URL.createObjectURL(this.createPost.video);
                        this.previewVideo.type = this.createPost.video.type;
                    }
                } else if (this.mediaSelectionStep.youtubeUrlSelected) { // YouTube video selected
                    this.previewVideo.src = this.createPost.videoUrl;
                    this.previewVideo.type = "video/youtube";
                }
                this.currentStep += 1;
            } else if (this.currentStep === this.newPostStepEnum.CONTENT_CREATION && this.hasAudio) { // handle going on from POST CONTENT CREATION (step 2 to step 3)
                if (this.previewVideo.src) { // remove and reset previewVideo when moving to final step
                    this.$window.URL.revokeObjectURL(this.createPost.video);
                    this.previewVideo.src = null;
                    this.previewVideo.type = null;
                }
                this.currentStep += 1;
            } else if ( this.currentStep === this.newPostStepEnum.CONTENT_METADATA && this.contentMetadataStep.complete) { // final step (should show save button instead of next button)
                this.currentStep += 1;
            }
        },
        previousStep: function () {
            if (this.currentStep === this.newPostStepEnum.CONTENT_CREATION) { // going back from content creation (step 2) to media selection (step 1)
                if (this.previewVideo.src) { // remove and reset previewVideo when moving to first step
                    this.$window.URL.revokeObjectURL(this.createPost.video);
                    this.previewVideo.src = null;
                    this.previewVideo.type = null;
                }
            } else if (this.currentStep === this.newPostStepEnum.CONTENT_METADATA) { // going back from content metadata (step 3) to content creation (step 2)
                if (this.mediaSelectionStep.fileSelected) { // local file selected
                    if (this.createPost.transcoded) { // file was transcoded
                        this.previewVideo.src = this.createPost.videoUrl;
                        this.previewVideo.type = this.createPost.video.type;
                    } else { // file was NOT transcoded create object url for video player
                        this.previewVideo.src = this.$window.URL.createObjectURL(this.createPost.video);
                        this.previewVideo.type = this.createPost.video.type;
                    }
                } else if (this.mediaSelectionStep.youtubeUrlSelected) { // YouTube video selected
                    this.previewVideo.src = this.createPost.videoUrl;
                    this.previewVideo.type = "video/youtube";
                }
                this.createPost.thumbnail = null;
            }
            this.currentStep -= 1
        },
        saveStep: async function () {
            this.loading = true;
            await this.filterUndefinedValues();
            this.completed = true; // if this is not set to true here, in beforeDestroy it will delete the audio files.

            this.$store.dispatch('setIsPostPublic', (this.createPost.type === 'public' ? true : false) ); // update store state with post type

            // this.mediaSelectionStep.fileSelected ? this.dispatchSavePost('saveVideoPost', false) : this.dispatchSavePost('saveYoutubePost', false);
            this.dispatchSavePost((this.mediaSelectionStep.fileSelected ? 'saveVideoPost' : 'saveYoutubePost'), false);
        },
        savePostAsDraft: async function() {
            if (Object.values(this.tawqData).some(segment => segment.error !== undefined)) {
                this.homeDialog = false;
                this.$store.commit('alertUser', { show: true, text: `You cannot save with errors in captions. Please try again!`, type: 'snackbar' });
                return;
            }

            if (Object.values(this.tawqData).some(segment => segment.audio === null || segment.caption === null)) { // this if statement is used as a double check to make sure a user is not saving while a caption is converting. It should not get called because we are using the computed property checkDeleteAndDraftButtonValidity() to disable the button while a caption is converting.
                this.homeDialog = false;
                this.$store.commit('alertUser', { show: true, text: `You cannot save while captions are converting. Please try again!`, type: 'snackbar' });
                return;
            }

            this.loading = true;
            await this.filterUndefinedValues();
            this.completed = true; // if this is not set to true here, in beforeDestroy it will delete the audio files.
            // DOUBLE CHECK THE BELOW VALUE, BECAUSE IT SHOULD BY DEFAULT BE SET TO NULL!!!
            if (this.createPost.type != "group" && this.createPost.type != "public") this.createPost.type = null; // saving as draft BEFORE selecting type (in PostContentMediaSelection or PostContentCreation).

            // this.mediaSelectionStep.fileSelected ? this.dispatchSavePost('file', true) : this.dispatchSavePost('youtube', true);
            this.dispatchSavePost((this.mediaSelectionStep.fileSelected ? 'file' : 'youtube'), true);
        },
        dispatchSavePost: function (dispatchAction, saveAsDraft) {
            this.postAnalytics.endTimes.push(new Date());
            this.postAnalytics.postType = this.createPost.type;
            this.postAnalytics.totalAudioTime = this.getTotalAudioTime();
            this.clearRTDB(firebase.auth.currentUser.uid);
            this.loading = false;

            const dispatchPayload = {
                post: this.createPost,
                draftPost: (this.isDraftPost ? this.draftPost : null),
                isDraft: this.isDraftPost || false,
                deleteDraftPostThumbnail: this.deleteDraftPostThumbnail
            };

            saveAsDraft
            ? (this.$store.dispatch('savePostAsDraft', { ...dispatchPayload, step: this.currentStep, type: dispatchAction }), this.$router.replace({ path: "profile" }))
            : (this.$store.dispatch(dispatchAction, dispatchPayload), this.$router.push({ path: this.createPost.type === 'group' ? 'groups' : 'profile' }));
        },
        earlyUploadSaveAsDraft: function () {
            this.completed = true; // if this is not set to true here, in beforeDestroy it will delete the audio files.

            this.postAnalytics.endTimes.push( new Date() ); // Set postAnalytics data
            this.postAnalytics.postType = this.createPost.type;
            this.postAnalytics.totalAudioTime = this.getTotalAudioTime();

            this.$router.replace({path: '/home'}); // route back to the home feed
        },
        cancelVideoCodecProcessingDialog: function (processing) {
            // if (processing) {
            //     // console.log("videoProcessing go back home and delete what was done");
            //     // In this case we might be able to delete everything, either by cancelling the upload process or delete the files that have been saved on the server,
            //     // and then to reset the state of the store, so that the user can just reselect another video.
            // } else {
            //     // console.log("clear the video so user can reselect a video");
            //     this.createPostTranscoded = false;
            //     this.createPostVideo = undefined;
            //     this.videoCodecCompatible = null;
            // }
            if (!processing) {
                this.createPostTranscoded = false;
                this.createPostVideo = undefined;
                this.videoCodecCompatible = null;
            }
            this.videoCodecProcessingDialog = false;
        },
        filterUndefinedValues() {
            return new Promise(async (resolve) => {
                for (const key in this.tawqData) {
                    const segment = this.tawqData[key];

                    if (typeof segment === 'undefined') {
                        this.$delete(this.tawqData, key);
                    } else {
                        this.ensureCaptionProperty(segment);
                        this.ensureAudioObjectProperties(key, segment);
                    }
                }
                resolve();
            });
        },
        ensureCaptionProperty(segment) {
            if (typeof segment.caption === 'undefined') segment.caption = "";
        },
        ensureAudioObjectProperties(key, segment) {
            const { audio, videoSeg } = segment;

            if (typeof audio === 'undefined') segment.audio = {};
            if (typeof audio !== 'undefined') this.ensureAudioProperty(key, audio, videoSeg);
        },
        ensureAudioProperty(key, audio, videoSeg) {
            if (typeof audio.audioDur === 'undefined' || audio.audioDur === null) audio.audioDur = (typeof videoSeg !== 'undefined') ? videoSeg.dt : 0;
            if (typeof audio.audioStart === 'undefined' || audio.audioStart === null) audio.audioStart = (typeof videoSeg !== 'undefined') ? Math.max(videoSeg.vStart - videoSeg.dt, 0) : 0;
            if (typeof audio.audioEnd === 'undefined' || audio.audioEnd === null) audio.audioEnd = (typeof videoSeg !== 'undefined') ? audio.audioStart + videoSeg.dt : 0;
            if (typeof audio.segment === 'undefined') audio.segment = parseInt(key);
            if (typeof audio.audioURL === 'undefined' || audio.audioURL === null) {
                const location = `audioSegments/${this.createPost.postId}/${this.createPost.audioDataId}/${parseInt(key)}`;
                this.setAudioURL(audio, location);
            }
        },
        setAudioURL(audio, location) {
            firebase.storage.ref().child(location).getDownloadURL().then((url) => {
                audio.audioURL = url || "";
            }).catch((error) => {
                audio.audioURL = "";
                console.log("An error occurred when setting the audio segment URL. The error is: ", error);
                this.$store.commit('alertUser', { show: true, text: `Oops, Couldn't retrieve the URL for your audio. Error: ${error.message}`, type: 'snackbar' });
            });
        },
        setPostDocId: function () {
            var newPostRef = firebase.db.collection("publicPosts").doc();
            this.createPost.postId = newPostRef.id;
        },
        setAudioDataId: function () {
            var newAudioRef = firebase.db.collection(`audioSegments`).doc();
            this.createPost.audioDataId = newAudioRef.id;
            firebase.rtDb.ref(`${firebase.auth.currentUser.uid}/currentAudioDataId`).set({ audioDataId: newAudioRef.id});
        },
        setCreatePostToDraft: async function() {
            this.completed = true; // we set this to true so it doesn't delete the audio files unless they specifically say they want to delete the draft!
            /* take care of the step specifics first */
            console.log("draft Post Id ", this.draftPost.postId);


            // The following if statement might not be needed.
            if (this.draftPost.currentStep >= this.newPostStepEnum.MEDIA_SELECTION) {
                this.mediaSelectionStep = this.draftPost;
            }

            // 3 - TAKE CARE OF CONTENT METADATA STEP
            if (this.draftPost.currentStep >= this.newPostStepEnum.CONTENT_METADATA) {
                this.createPost.type = this.draftPost.postType;
            }
            if (typeof this.draftPost.tawqData != 'undefined' && Object.keys(this.draftPost.tawqData).length > 0 ) {
                // if there is tawqData then draftPost has audio data
                this.contentCreationStep.complete = true;
                this.tawqData = this.draftPost.tawqData;
            }
            this.currentStep = 1;
            this.createPost.hasDraft = true;
            this.createPost.audioDataId = this.draftPost.audioDataId;
            this.createPost.languageSelected = this.draftPost.languageSelected;
            this.createPost.postId = this.draftPost.postId;
            this.createPost.userId = this.draftPost.userId;
            this.createPost.userProfilePhoto = firebase.auth.currentUser.photoURL;
            this.createPost.username = this.draftPost.username;
            this.createPost.category = this.draftPost.category;
            this.createPost.description = this.draftPost.description;
            this.createPost.group = this.draftPost.group;
            this.createPost.type = this.draftPost.postType;
            this.createPost.title = this.draftPost.title;
            this.createPost.tags = this.draftPost.tags;

            if (this.draftPost.transcoded) { // video was saved as draft before uploading and transcoding were completed
                this.createPost.transcoded = true;
                this.previewVideo.src = this.draftPost.videoUrl;
                this.previewVideo.type = this.draftPost.videoType;
                this.createPost.videoUrl = this.draftPost.videoUrl;
                this.createPost.videoType = this.draftPost.videoType;
                this.createPost.video = this.draftPost.draftVideo;

                this.videoCodecCompatible = true;
                this.videoFileUploadComplete = true;

            } else {
                // The post was not transcoded so the user could have moved on past PostContentMediaSelection
                // set all potential values that could have been changed and saved to draftPost but not necessary
                // if video was transcoded because they would have never made it past postContentMediaSelection
                // 1 - TAKE CARE OF MEDIA SELECTION STEP
                if (this.draftPost.draftVideo.type != 'video/mp4' && this.draftPost.draftVideo.type.split('/')[0] != 'audio') {
                    // youtube video
                    this.previewVideo.src = this.draftPost.draftVideo.url;
                    this.previewVideo.type = this.draftPost.draftVideo.type;
                }
                this.createPost.videoUrl = typeof this.draftPost.draftVideo.url === 'undefined' ? "" : this.draftPost.draftVideo.url;
                this.createPost.videoType = this.draftPost.draftVideo.type;
                this.$store.commit('saveVideoDuration', this.draftPost.draftVideo.videoDuration);
            }
            this.postAnalytics = this.draftPost.postAnalytics;
        },
        getPermissions: function () {
            return new Promise((resolve) => {
                if (DeviceOrientationEvent && typeof DeviceOrientationEvent.requestPermission === "function") {
                    DeviceOrientationEvent.requestPermission().then((response) => {
                        if (response === "granted") {
                            resolve(true);
                        } else if (response === "denied") {
                            this.$store.commit('alertUser', { show: true, text: `Device Orientation Permission Denied. Clear Web Data then Accept Permissions.`, type: 'snackbar' });
                            resolve(false);
                        } else {
                            this.$store.commit('alertUser', { show: true, text: `Please allow device orientation detection to record your audio, or change turn 'Push to tawq' on in settings.`, type: 'snackbar' });
                            resolve(false);
                        }
                    }).catch((err) => {
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. Error: ${err.message}`, type: 'snackbar' });
                        resolve(false);
                    });
                } else {
                    //not an iOS device, add event normally
                    resolve(true);
                }
            });
        },
        getTotalAudioTime: function() {
            return Object.values(this.tawqData).reduce((totalTime, segment) => {
                const dur = segment.audio?.audioDur || 0;
                return totalTime + dur;
            }, 0);
        },
        setCloudFunctionTimeout: function (segment, timeoutId) {
            this.cloudFunctionTimeoutIds.push({segment, timeoutId});
        },
        clearCloudFunctionTimeout: function (segment, clear) {
            const matchingObject = this.cloudFunctionTimeoutIds.find(obj => obj.segment === segment);
            if (matchingObject) {
                if (clear) this.clearCloudFunctionTimeoutById(matchingObject);

                const index = this.cloudFunctionTimeoutIds.indexOf(matchingObject);
                if (index !== -1) this.cloudFunctionTimeoutIds.splice(index, 1);
            }
        },
        clearCloudFunctionTimeoutById: function (matchingObject) {
            clearTimeout(matchingObject.timeoutId);
        },
        clearAllCloudFunctionTimeouts: function () {
            this.cloudFunctionTimeoutIds.forEach(obj => clearTimeout(obj.timeoutId));
            this.cloudFunctionTimeoutIds.length = 0;
        },
        clearRTDB: async function (userId) {
            const allRefs = firebase.rtDb.ref(userId);
            allRefs.off();
            await allRefs.remove();
        },
        deleteIncompletePostFromStorage: function () {
            // If the post is saved as a draft, we will need to delete the whole post like a saved post.
            // If it is not a draft post, we only need to delete the audio files
            this.isDraftPost ? this.$store.dispatch('deleteIncompletePostFromStorage', { isDraft: this.isDraftPost, post: this.createPost, audioDataId: this.createPost.audioDataId, userId: firebase.auth.currentUser.uid })
            : this.$store.dispatch('deleteIncompletePostAudioFiles', { postId: this.createPost.postId, audioDataId: this.createPost.audioDataId });
        },
    },
    computed: {
        isMobile () {
            return this.$store.state.isMobileDevice;
        },
        isIOS () {
            return this.$store.state.isIOSDevice;
        },
        userData () {
            return this.$store.state.userData;
        },
        userGroups () {
            return this.$store.state.userGroups;
        },
        videoCodecProcessingDialog: {
            get(){
                return this.$store.state.newPost.earlyUpload.videoCodecProcessingDialog;
            },
            set(value){
                this.$store.commit('saveEarlyUploadVideoCodecProcessingDialog', value);
            }
        },
        videoFileUploadComplete: {
            get(){
                return this.$store.state.newPost.videoFileUploadComplete;
            },
            set(value){
                this.$store.commit('saveVideoFileUploadComplete', value);
            }
        },
        createPostTranscoded: {
            get(){
                return this.$store.state.newPost.createPost.transcoded;
            },
            set(value){
                this.$store.commit('saveCreatePostTranscoded', value);
            }
        },
        createPostVideo: {
            get(){
                return this.$store.state.newPost.createPost.video;
            },
            set(value){
                this.$store.commit('saveCreatePostVideo', value);
            }
        },
        videoCodecCompatible: {
            get(){
                return this.$store.state.newPost.earlyUpload.videoCodecCompatible;
            },
            set(value){
                this.$store.commit('saveEarlyUploadVideoCodecCompatible', value);
            }
        },
        postAnalytics: {
            get(){
                return this.$store.state.newPost.postAnalytics;
            },
            set(value){
                this.$store.commit('saveNewPostAnalytics', value);
            }
        },
        createPost: {
            get(){
                return this.$store.state.newPost.createPost;
            },
            set(value){
                this.$store.commit('saveCreatePost', value);
            }
        },
        newPostStepEnum () {
            return this.$store.state.newPost.newPostStep;
        },
        currentStep: {
            get() {
                return this.$store.state.newPost.createPost.currentStep;
            },
            set(value){
                this.$store.commit('saveCreatePostCurrentStep', value);
            }
        },
        mediaSelectionStep: {
            get () {
                return this.$store.state.newPost.mediaSelectionStep;
            },
            set (value) {
                this.$store.commit('saveMediaSelectionStep', { complete: true, fileSelected: (value.draftVideo.type === 'video/mp4' || value.draftVideo.type.split('/')[0] === 'audio') ? true : false, youtubeUrlSelected: (value.draftVideo.type === 'video/mp4' || value.draftVideo.type.split('/')[0] === 'audio') ? false : true});
            }
        },
        contentCreationStep: {
            get() {
                return this.$store.state.newPost.contentCreationStep;
            },
            set(value){
                this.$store.commit('saveContentCreationStep', value);
            }
        },
        contentMetadataStep () {
            return this.$store.state.newPost.contentMetadataStep;
        },
        tawqData: {
            get(){
                return this.$store.state.newPost.tawqData;
            },
            set(value){
                this.$store.commit('saveTawqData', value);
            }
        },
        hasCaptions () {
            return Object.values(this.tawqData).some((item) => item.caption !== undefined && item.caption !== null);
        },
        hasAudio () {
            return Object.values(this.tawqData).some((item) => item.audio !== undefined && item.audio !== null);
        },
        checkDeleteAndDraftButtonValidity() { // disables 'Save as Draft' and 'Delete' buttons while recording and converting
            return !(this.hasCaptions === this.hasAudio && this.hasAudio === this.contentCreationStep.complete);
        },
        // checkDeleteAndDraftButtonValidity () {
        //     if (!this.hasCaptions && !this.hasAudio && !this.contentCreationStep.complete) {
        //         return false;
        //     } else if (this.hasCaptions && this.hasAudio && this.contentCreationStep.complete) {
        //         return false
        //     } else {
        //         return true
        //     };
        // },
        checkButtonValidity () {
            /* return false if can proceed (not disabled), true if cant proceed (disabled) */
            // uncomment the last portion of code to disable the next button when a video does not have the correct codec for web browsers
            switch (this.currentStep) {
                case this.newPostStepEnum.MEDIA_SELECTION: // Disable if media selection step is incomplete or video codec is not compatible
                    return !this.mediaSelectionStep.complete || (!this.videoCodecCompatible && !this.mediaSelectionStep.youtubeUrlSelected);
                case this.newPostStepEnum.CONTENT_CREATION: // Disable if content creation step is incomplete
                    return !this.contentCreationStep.complete;
                case this.newPostStepEnum.CONTENT_METADATA: // Disable if content metadata step is incomplete
                    return !this.contentMetadataStep.complete;
                default:
                    return false;
            }
        },
    },
    beforeCreate() {
        this.$store.dispatch('resetStoreState');
    },
    created: async function () {
        this.postAnalytics.startTimes.push( new Date() );
        this.postAnalytics.isMobileDevice = this.isMobile;
        this.postAnalytics.isIOS = this.isIOS; 
        this.postAnalytics.stepOrder = this.newPostStepEnum;

        this.postAnalytics.pushToTawq = this.userData.pushToTawq && !this.isMobile;
        this.postAnalytics.pickUpToTawq = !this.userData.pushToTawq && this.isMobile;

        await this.clearRTDB(firebase.auth.currentUser.uid);

        if (this.isDraftPost) {
            // console.log("draftPost: ", this.draftPost);
            this.setCreatePostToDraft();
        } else {
            if (this.userData.languageSelected) this.createPost.languageSelected = this.userData.languageSelected;
            this.setPostDocId();
            this.setAudioDataId();
            console.log("new postId: ", this.createPost.postId);
        }
        // console.log("createPost: ", this.createPost);
    },
    mounted: function () {
        this.$store.dispatch('setFirebaseUser');
        this.languageCodes.push(...this.$languageCodes);
        // setTimeout( () => {
        //   this.showHelpIndicator = false;
        // }, 4000);
    },
    beforeDestroy: async function () {
        if (this.completed === false) {
            console.log("Incomplete, Calling Delete!");
            // We need to delete all audio files from storage, because they save as they are recorded.
            // And in that case, the contribution was not completed, therefore the audioFiles will be
            // in storage but are absolutely useless. If it is a draft post then we will need to also delete the draftpost metadata
            if (this.hasAudio || this.isDraftPost) {
                await this.deleteIncompletePostFromStorage();
            } else if (this.createPost.transcoded && !this.isDraftPost) {
                // TODO: check to make sure this doesn't get called when we are deleting the post as call from the if statement above
                // console.log("video has been uploaded and transcoded. Delete from Storage");
                await this.$store.dispatch('deleteUploadedAndTranscodedVideoFiles', {post: this.createPost});
            }
        }
        this.clearRTDB(firebase.auth.currentUser.uid);
        this.clearAllCloudFunctionTimeouts();
    },
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>