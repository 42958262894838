const languageCodes = [
    // {
    //   text: "",
    //   value: "",
    // },
    // {
    //   text: "None (Disable Auto Captions)",
    //   value: "null",
    // },
    {
      text: "English (United States)",
      value: "en-US",
    },
    {
      text: "English (India)",
      value: "en-IN",
    },
    {
      text: "English (United Kingdom)",
      value: "en-GB",
    },
    {
      text: "English (Australia)",
      value: "en-AU"
    },
    {
      text: "English (Canada)",
      value: "en-CA",
    },
    {
      text: "English (Ghana)",
      value: "en-GH",
    },
    {
      text: "English (Ireland)",
      value: "en-IE",
    },
    {
      text: "English (Kenya)",
      value: "en-KE",
    },
    {
      text: "English (New Zealand)",
      value: "en-NZ",
    },
    {
      text: "English (Nigeria)",
      value: "en-NG",
    },
    {
      text: "English (Philippines)",
      value: "en-PH",
    },
    {
      text: "English (South Africa)",
      value: "en-ZA",
    },
    {
      text: "English (Tanzania)",
      value: "en-TZ",
    },
    {
      text: "English (Hong Kong)",
      value: "en-HK",
    },
    {
      text: "English (Pakistan)",
      value: "en-PK",
    },
    {
      text: "English (Singapore)",
      value: "en-SG",
    },
    {
      text: "Español (Estados Unidos) | Spanish (United States)",
      value: "es-US",
    },
    {
      text: "Español (México) | Spanish (Mexico)",
      value: "es-MX",
    },
    {
      text: "Español (Perú) | Spanish (Peru)",
      value: "es-PE",
    },
    {
      text: "Español (Argentina) | Spanish (Argentina)",
      value: "es-AR",
    },
    {
      text: "Español (Bolivia) | Spanish (Bolivia)",
      value: "es-BO",
    },
    {
      text: "Español (Chile) | Spanish (Chile)",
      value: "es-CL",
    },
    {
      text: "Español (Colombia) | Spanish (Colombia)",
      value: "es-CO",
    },
    {
      text: "Español (Costa Rica) | Spanish (Costa Rica)",
      value: "es-CR",
    },
    {
      text: "Español (República Dominicana) | Spanish (Dominican Republic)",
      value: "es-DO",
    },
    {
      text: "Español (Ecuador) | Spanish (Ecuador)",
      value: "es-EC",
    },
    {
      text: "Español (El Salvador) | Spanish (El Salvador)",
      value: "es-SV",
    },
    {
      text: "Español (Guatemala) | Spanish (Guatemala)",
      value: "es-GT",
    },
    {
      text: "Español (Honduras) | Spanish (Honduras)",
      value: "es-HN",
    },
    {
      text: "Español (Nicaragua) | Spanish (Nicaragua)",
      value: "es-NI",
    },
    {
      text: "Español (Panamá) | Spanish (Panama)",
      value: "es-PA",
    },
    {
      text: "Español (Paraguay) | Spanish (Paraguay)",
      value: "es-PY",
    },
    {
      text: "Español (Puerto Rico) | Spanish (Puerto Rico)",
      value: "es-PR",
    },
    {
      text: "Español (España) | Spanish (Spain)",
      value: "es-ES",
    },
    {
      text: "Español (Uruguay) | Spanish (Uruguay)",
      value: "es-UY",
    },
    {
      text: "Español (Venezuela) | Spanish (Venezuela)",
      value: "es-VE",
    },
    {
      text: "Afrikaans (Suid-Afrika) | Afrikaans (South Africa)",
      value: "af-ZA",
    },
    {
      text: "አማርኛ (ኢትዮጵያ) | Amharic (Ethiopia)",
      value: "am-ET",
    },
    {
      text: "العربية (الجزائر)‏ | Arabic (Algeria)",
      value: "ar-DZ",
    },
    {
      text: "العربية (البحرين)‏ | Arabic (Bahrain)",
      value: "ar-BH",
    },
    {
      text: "العربية (مصر)‏ | Arabic (Egypt)",
      value: "ar-EG",
    },
    {
      text: "العربية (العراق) | Arabic (Iraq)",
      value: "ar-IQ",
    },
    {
      text: "العربية (إسرائيل)‏ | Arabic (Israel)",
      value: "ar-IL",
    },
    {
      text: "العربية (الأردن)‏ | Arabic (Jordan)",
      value: "ar-JO",
    },
    {
      text: "العربية (الكويت) | Arabic (Kuwait)",
      value: "ar-KW",
    },
    {
      text: "العربية (عُمان) | Arabic (Oman)",
      value: "ar-OM",
    },
    {
      text: "العربية (لبنان) | Arabic (Lebanon)",
      value: "ar-LB",
    },
    {
      text: "العربية (المغرب) | Arabic (Morocco)",
      value: "ar-MA",
    },
    {
      text: "العربية (قطر)‏ | Arabic (Qatar)",
      value: "ar-QA",
    },
    {
      text: "العربية (السعودية)‏ | Arabic (Saudi Arabia)",
      value: "ar-SA",
    },
    {
      text: "العربية (فلسطين) | Arabic (State of Palestine)",
      value: "ar-PS",
    },
    {
      text: "العربية (تونس)‏ | Arabic (Tunisia)",
      value: "ar-TN",
    },
    {
      text: "العربية (الإمارات)‏ | Arabic (United Arab Emirates)",
      value: "ar-AE",
    },
    {
      text: "العربية (اليمن) | Arabic (Yemen)",
      value: "ar-YE",
    },
    {
      text: "Հայ (Հայաստան) | Armenian (Armenia)",
      value: "hy-AM",
    },
    {
      text: "Azərbaycan (Azərbaycan)(Azerbaijan) | Azerbaijani (Azerbaijan)",
      value: "az-AZ",
    },
    {
      text: "Euskara (Espainia) | Basque (Spain)",
      value: "eu-ES",
    },
    {
      text: "Български (България) | Bulgarian (Bulgaria)",
      value: "bg-BG",
    },
    {
      text: "Català (Espanya) | Catalan (Spain)",
      value: "ca-ES",
    },
    {
      text: "Hrvatski (Hrvatska) | Croatian (Croatia)",
      value: "hr-HR",
    },
    {
      text: "Čeština (Česká republika) | Czech (Czech Republic)",
      value: "cs-CZ",
    },
    {
      text: "Dansk (Danmark) | Danish (Denmark)",
      value: "da-DK",
    },
    {
      text: "Nederlands (Nederland) | Dutch (Netherlands)",
      value: "nl-NL",
    },
    {
      text: "Nederlands (België) | Dutch (Belgium)",
      value: "nl-BE",
    },
    {
      text: "Filipino (Pilipinas) | Filipino (Philippines)",
      value: "fil-PH",
    },
    {
      text: "Français (France) | French (France)",
      value: "fr-FR",
    },
    {
      text: "Français (Canada) | French (Canada)",
      value: "fr-CA",
    },
    {
      text: "Français (Belgique) | French (Belgium)",
      value: "fr-BE",
    },
    {
      text: "Français (Suisse) | French (Switzerland)",
      value: "fr-CH",
    },
    {
      text: "Galego (España) | Galician (Spain)",
      value: "gl-ES",
    },
    {
      text: "ქართული (საქართველო) | Georgian (Georgia)",
      value: "ka-GE",
    },
    {
      text: "Deutsch (Deutschland) | German (Germany)",
      value: "de-DE",
    },
    {
      text: "Deutsch (Österreich) | German (Austria)",
      value: "de-AT",
    },
    {
      text: "Deutsch (Schweiz) | German (Switzerland)",
      value: "de-CH",
    },
    {
      text: "Ελληνικά (Ελλάδα) | Greek (Greece)",
      value: "el-GR",
    },
    {
      text: "ગુજરાતી (ભારત) | Gujarati (India)",
      value: "gu-IN",
    },
    {
      text: "Íslenska (Ísland) | Icelandic (Iceland)",
      value: "is-IS",
    },
    {
      text: "Italiano (Italia) | Italian (Italy)",
      value: "it-IT",
    },
    {
      text: "Italiano (Svizzera) | Italian (Switzerland)",
      value: "it-CH",
    },
    {
      text: "日本語（日本) | Japanese (Japan)",
      value: "ja-JP",
    },
    {
      text: "Jawa (Indonesia) | Javanese (Indonesia)",
      value: "jv-ID",
    },
    {
      text: "ಕನ್ನಡ (ಭಾರತ) | Kannada (India)",
      value: "kn-IN",
    },
    {
      text: "한국어 (대한민국) | Korean (South Korea)",
      value: "ko-KR",
    },
    {
      text: "ລາວ (ລາວ) | Lao (Laos)",
      value: "lo-LA",
    },
    {
      text: "Latviešu (latviešu) | Latvian (Latvia)",
      value: "lv-LV",
    },
    {
      text: "Lietuvių (Lietuva) | Lithuanian (Lithuania)",
      value: "lt-LT",
    },
    {
      text: "Bahasa Melayu (Malaysia) | Malay (Malaysia)",
      value: "ms-MY",
    },
    {
      text: "മലയാളം (ഇന്ത്യ) | Malayalam (India)",
      value: "ml-IN",
    },
    {
      text: "मराठी (भारत) | Marathi (India)",
      value: "mr-IN",
    },
    {
      text: "Norsk bokmål (Norge) | Norwegian Bokmål (Norway)",
      value: "no-NO",
    },
    {
      text: "Português (Brasil) | Portugese (Brazil)",
      value: "pt-BR",
    },
    {
      text: "Português (Portugal) | Portuguese (Portugal)",
      value: "pt-PT",
    },
    {
      text: "Română (România) | Romanian (Romania)",
      value: "ro-RO",
    },
    {
      text: "Српски (Србија) | Serbian (Serbia)",
      value: "sr-RS",
    },
    {
      text: "සිංහල (ශ්රී ලංකාව) | Sinhala (Sri Lanka)",
      value: "si-LK",
    },
    {
      text: "Slovenčina (Slovensko) | Slovak (Slovakia)",
      value: "sk-SK",
    },
    {
      text: "Slovenščina (Slovenija) | Slovenian (Slovenia)",
      value: "sl-SI",
    },
    {
      text: /*Kiswahili (Kenya) | */"Swahili (Kenya)",
      value: "sw-KE",
    },
    {
      text: /*Kiswahili (Tanzania) | */"Swahili (Tanzania)",
      value: "sw-TZ",
    },
    {
      text: "தமிழ் (இந்தியா) | Tamil (India)",
      value: "ta-IN",
    },
    {
      text: "தமிழ் (மலேசியா) | Tamil (Malaysia)",
      value: "ta-MY",
    },
    {
      text: "தமிழ் (சிங்கப்பூர்) | Tamil (Singapore)",
      value: "ta-SG",
    },
    {
      text: "தமிழ் (இலங்கை) | Tamil (Sri Lanka)",
      value: "ta-LK",
    },
    {
      text: "తెలుగు (భారతదేశం) | Telugu (India)",
      value: "te-IN",
    },
    {
      text: "ไทย (ประเทศไทย) | Thai (Thailand)",
      value: "th-TH",
    },
    {
      text: "Türkçe (Türkiye) | Turkish (Turkey)",
      value: "tr-TR",
    },
    {
      text: "IsiZulu (Ningizimu Afrika) | Zulu (South Africa)",
      value: "zu-ZA",
    },
    {
      text: "Tiếng Việt (Việt Nam) | Vietnamese (Vietnam)",
      value: "vi-VN",
    },
    {
      text: "Suomi (Suomi) | Finnish (Finland)",
      value: "fi-FI",
    },
    {
      text: "한국어 (대한민국) | Korean (South Korea)",
      value: "ko-KR",
    },
    {
      text: "Русский (Россия) | Russian (Russia)",
      value: "ru-RU",
    },
    {
      text: /*"中文、*/"普通话（简体中文）| Chinese, Mandarin (Simplified China)",
      value: "zh",//"zh (cmn-Hans-CN)",
    },
    {
      text: /*"中文、*/"粵語（香港繁體）| Chinese, Cantonese (Traditional Hong Kong)",
      value: "yue-Hant-HK",
    },
    {
      text: "國語 (台灣) | Chinese, Mandarin (Traditional, Taiwan)",
      value: "zh-TW", //(cmn-Hant-TW)",
    },
    {
      text: "Polski (Polska) | Polish (Poland)",
      value: "pl-PL",
    },
    {
      text: "Українська (Україна) | Ukrainian (Ukraine)",
      value: "uk-UA",
    },
    {
      text: "Svenska (Sverige) | Swedish (Sweden)",
      value: "sv-SE",
    },
    {
      text: "हिन्दी (भारत) | Hindi (India)",
      value: "hi-IN",
    },
    {
      text: "বাংলা (বাংলাদেশ) | Bengali (Bangladesh)",
      value: "bn-BD",
    },
    {
      text: "বাংলা (ভারত) | Bengali (India)",
      value: "bn-IN",
    },
    {
      text: "עברית (ישראל) | Hebrew (Israel)",
      value: "iw-IL",
    },
    {
      text: "Magyar (Magyarország) | Hungarian (Hungary)",
      value: "hu-HU",
    },
    {
      text: "नेपाली (नेपाल) | Nepali (Nepal)",
      value: "ne-NP",
    },
    {
      text: "فارسی (ایران) | Persian (Iran)",
      value: "fa-IR",
    },
    {
      text: "اردو (بھارت)‏ | Urdu (India)",
      value: "ur-IN",
    },
    {
      text: "اردو (پاکستان) | Urdu (Pakistan)",
      value: "ur-PK",
    },
    {
      text: "ဗမာ (မြန်မာ) | Burmese (Myanmar)",
      value: "my-MM",
    },
    {
      text: "ភាសាខ្មែរ (កម្ពុជា) | Khmer (Cambodia)",
      value: "km-KH",
    },
    {
      text: "Shqip (shqiperi) | Albanian (Albania)",
      value: "sq-AL",
    },
    {
      text: "Bosanski (Bosna i Ḫercegovina) | Bosnian (Bosnia and Herzegovina)",
      value: "bs-BA",
    },
    {
      text: "Eesti (eesti) | Estonian (Estonia)",
      value: "et-EE",
    },
    {
      text: "Indonesia (Indonesia) | Indonesian (Indonesia)",
      value: "id-ID",
    },
    {
      text: "Қазақ (Қазақстан) | Kazakh (Kazakhstan)",
      value: "kk-KZ",
    },
    {
      text: "Македонски (северна Македонија) | Macedonian (North Macedonia)",
      value: "mk-MK",
    },
    {
      text: "Монгол (Монгол) | Mongolian (Mongolia)",
      value: "mn-MN",
    },
    {
      text: "ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ ਇੰਡੀਆ) | Punjabi (Gurmukhi India)",
      value: "pa-Guru-IN",
    },
    {
      text: "Basa Sunda (Indonésia) | Sundanese (Indonesia)"/*Sundan (Indonesia)*/,
      value: "su-ID",
    },
    {
      text: "O'zbek (O'zbekiston) | Uzbek (Uzbekistan)",
      value: "uz-UZ",
    },

    // Below was added at Marty request on July 1, 2024 by Scott Brady
    // These are from "Speech-to-Text V2 supported languages"
    {
      text: "অসমীয়া (ভাৰত) | Assamese (India)",
      value: "as-IN",
    },
    {
      text: "Asturian (España) | Asturian (Spain)",
      value: "ast-ES",
    },
    {
      text: "Cebuano (Pilipinas) | Cebuano (Philippines)",
      value: "ceb-PH",
    },
    {
      text: "کوردی ناوەڕاست (عێراق) | Central Kurdish (Iraq)",
      value: "ckb-IQ",
    },
    {
      text: "Hausa (Nigeria)",
      value: "ha-NG",
    },
    {
      text: "Kabuverdianu (Cape Verde)",
      value: "kea-CV",
    },
    {
      text: "Kamba (Kenya)",
      value: "kam-KE",
    },
    {
      text: "Кыргызча (кириллица) | Kyrgyz (Cyrillic)",
      value: "ky-KG",
    },
    {
      text: "Lingala (Kongo-Kinshasa) | Lingala (Congo-Kinshasa)",
      value: "ln-CD",
    },
    {
      text: "Luo (Kenya)",
      value: "luo-KE",
    },
    {
      text: "Lëtzebuergesch (Lëtzebuerg) | Luxembourgish (Luxembourg)",
      value: "lb-LU",
    },
    {
      text: "Malti (Malta) | Maltese (Malta)",
      value: "mt-MT",
    },
    {
      text: "Maori (New Zealand)",
      value: "mi-NZ",
    },
    {
      text: "Nyanja (Malawi)",
      value: "ny-MW",
    },
    {
      text: "Occitan (França) | Occitan (France)",
      value: "oc-FR",
    },
    {
      text: "پښتو | Pashto",
      value: "ps-AF",
    },
    {
      text: "Sepedi (Afrika Borwa) | Sepedi (South Africa)",
      value: "nso-ZA",
    },
    {
      text: "Shona (Zimbabwe)",
      value: "sn-ZW",
    },
    {
      text: "سنڌي (ڀارت) | Sindhi (India)",
      value: "sd-IN",
    },
    {
      text: "Soomaali | Somali",
      value: "so-SO",
    },
    {
      text: "тоҷикӣ (Тоҷикистон) | Tajik (Tajikistan)",
      value: "tg-TJ",
    },
    {
      text: "Cymraeg (y Deyrnas Unedig) | Welsh (United Kingdom)",
      value: "cy-GB",
    },
    {
      text: "Wolof (Senegaal) | Wolof (Senegal)",
      value: "wo-SN",
    },
    {
      text: "Èdè Yorùbá (Nàìjíríà) | Yoruba (Nigeria)",
      value: "yo-NG",
    },
    // {
    //   text: "",
    //   value: "",
    // },
  ];

  export default { languageCodes };