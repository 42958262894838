<template>
    <v-row justify="center" class="ma-0 pa-0">
        <v-col cols="12" xl="11" lg="11" md="12" sm="12">

            <v-row justify="center" no-gutters>
                <span class="text-h4 text-md-h3 font-weight-light">Settings</span>
            </v-row>

            <v-row justify="center" align="center" no-gutters class="my-2">
                <v-avatar class="hidden-sm-and-down" size="120" color="primary">
                    <v-img v-if="userProfilePic != null" :src="userProfilePic" :alt="username" max-height="118" max-width="118" referrerpolicy="no-referrer" @error="userProfilePic=null"></v-img>
                    <v-img v-else-if="userProfilePic === null" src="../assets/no-user.png" :alt="username" max-height="118" max-width="118"></v-img>
                </v-avatar>

                <v-avatar class="hidden-md-and-up hidden-xs-only" size="100" color="primary">
                    <v-img v-if="userProfilePic != null" :src="userProfilePic" :alt="username" max-height="98" max-width="98" referrerpolicy="no-referrer" @error="userProfilePic=null"></v-img>
                    <v-img v-else-if="userProfilePic === null" src="../assets/no-user.png" :alt="username" max-height="98" max-width="98"></v-img>
                </v-avatar>

                <v-avatar class="hidden-sm-and-up" size="80" color="primary">
                    <v-img v-if="userProfilePic != null" :src="userProfilePic" :alt="username" max-height="78" max-width="78" referrerpolicy="no-referrer" @error="userProfilePic=null"></v-img>
                    <v-img v-else-if="userProfilePic === null" src="../assets/no-user.png" :alt="username" max-height="78" max-width="78"></v-img>
                </v-avatar>
            </v-row>

            <v-row justify="center" no-gutters class="mb-2">
                <v-col cols="12" align="center">
                    <span class="text-h6">{{ username }}</span><br/>
                    <span class="text-body-2 font-italic font-weight-light accent--text">{{ email }}</span><br/>
                    <!-- <router-link v-if="isAdmin()" to="/manage-subscription" class="text-decoration-none">
                        <span class="text-body-2 font-weight-light primary--text">Manage Subscription</span>
                    </router-link> -->
                </v-col>
            </v-row>

            <v-row justify="center" no-gutters>
                <v-col cols="12" xl="4" lg="4" md="6" sm="8">

                    <v-divider/>
                    <v-row justify="space-around" align="center" no-gutters>
                        <span class="mr-5">Theme: <v-icon class="theme-icon">mdi-theme-light-dark</v-icon></span>
                        <v-switch v-model="userData.darkTheme" inset color="primary" @change="toggleDarkTheme"></v-switch>
                        <!-- <v-switch v-model="userData.darkTheme" inset color="primary" :hint="userData.darkTheme ? 'dark' : 'light'" persistent-hint @change="toggleDarkTheme" class="ma-0 pa-0"></v-switch> -->
                    </v-row>
                    <v-divider class="pb-8"/>

                    <v-autocomplete v-model="userData.languageSelected" label="Select default language" :items="$languageCodes" item-text="text" item-value="value" return-object outlined hint="*This will be the langauge you speak while recording" persistent-hint class="px-10"></v-autocomplete>
                    <v-divider class="pb-8"/>

                    <v-file-input v-model="imageInput" :rules="profileImageRules" validate-on-blur type="file" accept="image/*" outlined placeholder="Update Profile Picture" prepend-icon="" append-icon="mdi-camera" @change="getFiles()" @keypress.enter="UpdateInformation()" class="px-10"></v-file-input>
                    <v-text-field v-model="newUsername" clearable outlined label="Update Current Username" append-icon="mdi-account" :error-messages="nameErrors" @input="$v.newUsername.$touch()" @blur="$v.newUsername.$touch()" @keypress.enter="UpdateInformation()" class="px-10"></v-text-field>
                    <v-divider class="pb-8"/>

                    <!-- ADVANCED SETTINGS -->
                    <v-row justify="center" align="center" class="pb-8">
                        <span text @click.stop="advancedShow = !advancedShow" style="cursor:pointer;" class="font-weight-light accent--text">Advanced Settings</span>
                        <v-btn icon @click="advancedShow = !advancedShow" :ripple="false"><v-icon>{{ advancedShow ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon></v-btn>
                    </v-row>

                    <!-- <v-divider v-if="advancedShow" class="pb-8 hidden-xs-only"/> -->
                    <v-row v-if="advancedShow" justify="center" no-gutters class="pb-0 pb-sm-8">
                        <v-col cols="12" xl="8" lg="8" md="10" sm="10" justify="center" align="center" class="ma-0 pa-0">
                            <v-divider class="pb-2"/>

                            <v-divider class="mx-10"/>
                            <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <v-col cols="12" justify="center" align="center" class="ma-0 pa-0">
                                    <span class="text-h6 font-weight-light">Creation Settings</span>
                                </v-col>
                            </v-row>
                            <!-- <v-divider class="mx-10"/> -->

                            <v-divider/>
                            <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <span>Push to tawq:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <!-- <span>When turned off this option allows the user to<br/> pick up the phone and place to their ear to record</span> -->
                                        <span>When ON, push button to toggle audio recording<br/> When OFF, pick up the phone to record audio.</span>
                                    </v-tooltip>
                                </span>
                                <v-switch v-model="userData.pushToTawq" inset color="primary" :label="userData.pushToTawq ? 'ON' : 'OFF'"></v-switch>
                            </v-row>
                            <v-divider class="pb-2"/>

                            <v-divider class="mx-10"/>
                            <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <v-col cols="12" justify="center" align="center" class="ma-0 pa-0">
                                    <span class="text-h6 font-weight-light">Viewing Settings</span>
                                </v-col>
                            </v-row>
                            <!-- <v-divider class="mx-10 pb-2"/> -->

                            <v-divider/>
                            <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <span>Playback Style:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <!-- <span v-if="!userData.playbackStyle">This option applies to captions during a video.<br/> The video will pause while the caption audio plays.<br/></span> -->
                                        <!-- <span v-if="!userData.playbackStyle">Audio segment plays while video is paused.<br/></span> -->
                                        <!-- <span v-else>This option applies to captions during a video.<br/> The video will continue to play while the caption audio plays.<br/></span> -->
                                        <!-- <span v-else>Audio and video segments play at same time.<br/></span> -->

                                        <span>'pauseplay' - Audio segment plays while video is paused.<br/> 'continuous' - Audio and video segments play at same time.</span>
                                    </v-tooltip>
                                    <br/><span class="font-weight-light">{{userData.playbackStyle ? `'continuous'` : `'pauseplay'`}}</span>
                                </span>
                                <!-- <span v-if="!userData.playbackStyle">PausePlay Playback:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <span>This option applies to captions during a video.<br/> The video will pause while the caption audio plays.<br/></span>
                                    </v-tooltip>
                                </span> -->
                                <!-- <span v-else>Continuous Playback:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <span>This option applies to captions during a video.<br/> The video will continue to play while the caption audio plays.<br/></span>
                                    </v-tooltip>
                                </span> -->
                                <v-switch v-model="userData.playbackStyle" inset color="primary"></v-switch>
                            </v-row>
                            <v-divider class="mx-10"/>
                            <v-row v-if="!userData.playbackStyle" justify="space-between" align="center" no-gutters class="px-10 mx-sm-0">
                                <!-- if playbackStyle is pauseplay -->
                                <span>Audio First:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <!-- <span>This option applies to captions during a video, when playback style is pauseplay.<br/> When turned on, the video pauses at the beginning of the segment.<br/> When turned off, the video pauses at the end of the segment.</span> -->
                                        <span>When ON, audio segment plays BEFORE the video segment.<br/> When OFF, audio segment plays AFTER the video segment.</span>
                                    </v-tooltip>
                                </span>
                                <v-switch v-model="userData.playbackStyleAudioFirst" inset color="primary" :disabled="userData.playbackStyle" :label="userData.playbackStyleAudioFirst ? 'ON' : 'OFF'"></v-switch>
                            </v-row>
                            <v-row v-if="userData.playbackStyle" justify="space-between" align="center" no-gutters class="px-10 mx-sm-0">
                                <!-- if playbackStyle is continuous -->
                                <span>Skip Overlap:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <!-- <span>This option applies to captions during a video, when playback style is continuous.<br/> When turned on, audio segments will skip playing those they overlap.<br/> When turned off, audio segements that overlap will stop (cut off) and play the next segment.</span> -->
                                        <!-- <span>When turned on, audio segments will skip playing those they overlap.<br/> When turned off, audio segements that overlap will stop (cut off) and play the next segment.</span> -->
                                        <span>When ON, skips any overlapping audio segments.<br/> When OFF, ends current audio segment early to play next audio.</span>
                                    </v-tooltip>
                                </span>
                                <v-switch v-model="userData.playbackStyleSameStart" inset color="primary" :disabled="!userData.playbackStyle" :label="userData.playbackStyleSameStart ? 'ON' : 'OFF'"></v-switch>
                            </v-row>
                            <!-- <v-divider class="mx-10 pb-2"/> -->
                            <v-divider/>

                            <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <span>Caption Audio:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <span>When ON, caption audio will be UNMUTED.<br/> When OFF, caption audio will be MUTED.</span>
                                    </v-tooltip>
                                </span>
                                <v-switch v-model="userData.captionAudio" inset color="primary" :label="userData.captionAudio ? 'ON' : 'OFF'"></v-switch>
                            </v-row>
                            <v-divider/>

                            <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <span>Show Captions:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <span>When ON, caption will be shown while audio plays.<br/> When OFF, caption will NOT be shown while audio plays.</span>
                                    </v-tooltip>
                                </span>
                                <v-switch v-model="userData.showCaptions" inset color="primary" :label="userData.showCaptions ? 'ON' : 'OFF'"></v-switch>
                            </v-row>
                            <v-divider class="hidden-xs-only"/>

                            <!-- REMOVED ON OCTOBER 28, 2022 AT MARTY'S REQUEST -->
                            <!-- <v-row justify="space-between" align="center" no-gutters class="mx-10 mx-sm-0">
                                <span>Dual Device:
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon small v-on="on">mdi-information-outline</v-icon>
                                        </template>
                                        <span>When on, this allows a user to upload a video on their<br/> desktop computer and record from their phone</span>
                                        <span>This option applies to the audio played from a<br/> caption during a video. When on the caption will<br/> play the audio with sound, while off the audio<br/> from the caption will be muted</span>
                                    </v-tooltip>
                                </span>
                                <v-switch v-model="userData.dualDevice" inset color="primary"></v-switch>
                            </v-row> -->
                        </v-col>
                    </v-row>
                    <!-- END ADVANCED SETTINGS -->
                    <v-divider class="pb-2"/>

                    <v-row justify="center" align="center" no-gutters>
                        <v-btn rounded large depressed color="primary" :loading="infoChanged" :disabled="$v.$invalid || newImageUrl.size > profileImageFileLimit" @click="UpdateInformation()">Save</v-btn>
                    </v-row>
                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
import firebase from "../../firebaseConfig.js";
// import fb from 'firebase/app'
import { validationMixin } from 'vuelidate'
import { maxLength } from 'vuelidate/lib/validators'
export default {
    metaInfo: {
        title: "Settings - tawqin"
    },
    mixins: [validationMixin],
    validations: {
        newUsername: { maxLength: maxLength(30) },
    },
    data: function() {
        return {
            email: firebase.auth.currentUser.email,
            username: firebase.auth.currentUser.displayName,
            userProfilePic: firebase.auth.currentUser.photoURL,
            notificationsSwitch: true,
            subscriptionPlan: this.$store.state.userData.subscriptionPlan,
            profileImageFileLimit: 6000000,
            profileImageRules: [(value) => !value || value.size < this.profileImageFileLimit || "Image must be less than 6 MB",],
            advancedShow: false,
            infoChanged: false,
            image: "",
            previewImage: "",
            newImageUrl: "",
            newUsername: "",
            userData: {},
            loading: false,
            imageInput: null,
            languageName: "English (United States)",
            languageCode: "en-US",//null,//In the future this can be changed to the users personal preference from userData (which could be changed in settings).
            languageSelected: {
                text: "English (United States)",
                value: "en-US",
                },
        }
    },
    methods: {
        updateAllPosts: function () {
            firebase.db.collection("publicPosts").get().then((snapshot) => {
                snapshot.forEach(async (doc) => {
                    if (!doc.data().mediaType) {
                        await firebase.db.collection("publicPosts").doc(doc.data().postId).set({mediaType: "video"},{merge: true});
                    }
                });
            });
            firebase.db.collection("groupPosts").get().then((snapshot) => {
                snapshot.forEach(async (doc) => {
                    if (!doc.data().mediaType) {
                        await firebase.db.collection("groupPosts").doc(doc.data().postId).set({mediaType: "video"},{merge: true});
                    }
                });
            });
        },

        UpdateAllUsers: function () {
            firebase.db.collection("userData").get().then((snapshot) => {
                snapshot.forEach( async (doc)=>{
                    if(!doc.data().dualDevice){
                        // console.log(doc.data().userId);
                        await firebase.db.collection("userData").doc(doc.data().userId).set({dualDevice: false},{merge: true})
                    }
                });
            });
        },
        languageChanged: function () {
            if (typeof this.userData.languageSelected != "undefined"){
                // console.log(this.userData.languageSelected);
                this.languageName = this.userData.languageSelected.text;
                this.languageCode = this.userData.languageSelected.value;
            }
        },
        getFiles: function() {
            // var files = this.imageInput.files;
            // this.image = this.imageInput.files;
            if (this.imageInput) {
                this.previewImage = this.$window.URL.createObjectURL(this.imageInput);
                this.newImageUrl = this.imageInput;
            } else {
                this.previewImage = this.userProfilePic;
                this.newImageUrl = "";
            }
        },
        UpdateInformation: function() {
            // if (!this.newImageUrl && !this.newUsername) {
            //   return;
            // } else
            this.$v.$touch()
            if (this.$v.$invalid || this.newImageUrl.size > this.profileImageFileLimit) {
                return;
            } else {
                // this.$store.dispatch('unbindUserData');
                this.infoChanged = true;
                if (!this.newImageUrl && !this.newUsername){
                    this.updateUserMetaData();
                    return;
                } else if (this.newImageUrl){
                    this.UpdatePhotoURL();
                } else {
                    this.updateUserData();
                }
            }
        },
        updateUserData: function() {
            let name;
            if (this.newUsername === ""){
                name = this.username;
            } else {
                name = this.newUsername;
            }
            firebase.auth.currentUser.updateProfile({displayName: name,}).then(() => {
                this.username = firebase.auth.currentUser.displayName;
                this.updatePublicPostsMetadata();
                this.updateGroupPostsMetadata();
                this.updateAudioSegmentMetadata();
                this.updateGroupMetadata();
                this.updateFriendMetadata();
                this.updateNotificationMetadata();
                this.updateUserMetaData();
                this.newUsername = "";
            });
        },
        updateUserMetaData: function() {
            setTimeout(() => {
                this.infoChanged = false;
                this.$store.dispatch('bindUserData', firebase.auth.currentUser);
            }, 500);
            // console.log("this.userProfilePic", this.userProfilePic)
            let userDataDoc = firebase.db.collection("userData").doc(`${firebase.auth.currentUser.uid}`);
            userDataDoc.set({
                username: this.username,
                profilePicture: this.userProfilePic,
                // profanityFilter: this.userData.profanityFilter,
                darkTheme: this.userData.darkTheme,
                pushToTawq: this.userData.pushToTawq,
                playbackStyle: this.userData.playbackStyle, // true is continuous, false is pauseplay.
                playbackStyleAudioFirst: this.userData.playbackStyleAudioFirst, // if true, when segment start is reached, video will pause and audio will play; else, when segment end is reached video will pause and audio will play.
                playbackStyleSameStart: this.userData.playbackStyleSameStart, // if true, audio segment will play and skip overlapping segments; else, if overlap, audio will stop (cut off) and play next segment.
                captionAudio: this.userData.captionAudio,
                showCaptions: this.userData.showCaptions,
                dualDevice: this.userData.dualDevice,
                languageSelected: typeof this.userData.languageSelected === 'undefined' ? {text: this.languageName, value: this.languageCode } : this.userData.languageSelected,
            }, {merge: true});
        },
        UpdatePhotoURL: function() {
            const metadata = {
                cacheControl: 'public,max-age=604800' // 1 week
            }
            var file = this.newImageUrl;
            if (file) {
                this.loading = true;
            }
            var storageRef = firebase.storage.ref("userProfilePics");
            var photoRef = storageRef.child("/" + firebase.auth.currentUser.uid + "/1");
            var uploadTask = photoRef.put(file, metadata);
            uploadTask.on("state_changed",() => {},(error) => {
                console.log("ERROR UPLOADING THE FILE")
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            },() => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    this.loading = false;
                    this.imageInput = null;
                    firebase.auth.currentUser.updateProfile({
                        photoURL: downloadURL,
                    }).then(() => {
                        this.userProfilePic = firebase.auth.currentUser.photoURL;
                        this.updateUserData();
                        this.newImageUrl = "";
                    }).catch((err) => {
                        console.log("ERROR UPDATING THE FIREBASE.CURRENTUSER");
                        this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
                    });
                }).catch((err) => {
                    console.log("ERROR RETRIEVING THE DOWNLOAD URL OF THE IMAGE")
                    this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
                });
            });
        },
        toggleDarkTheme () {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
        updateFriendMetadata: function() {
            /* this method will update the users' data in the friends collection of all the userData of the users friends */
            this.$http.put(`friends/userdata`, {
                "username": this.username,
                "userProfilePhoto": this.userProfilePic,
                "userId": firebase.auth.currentUser.uid,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("completed the friend metadata update!");
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        updateNotificationMetadata: function() {
            /* this method will update the users' data in the notifications collection for the friend requests that haven't been accepted yet */
            this.$http.put(`notifications/friends/userdata`, {
                "username": this.username,
                "userProfilePhoto": this.userProfilePic,
                "userId": firebase.auth.currentUser.uid,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("completed the not accepted friend notifications metadata update!");
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        updateGroupMetadata: function() {
            /* this method will update the users' data in the members collection of all the groups they a part of */
            this.$http.put(`groups/userdata`, {
                "username": this.username,
                "userProfilePhoto": this.userProfilePic,
                "userId": firebase.auth.currentUser.uid,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("completed the group metadata update!");
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        updateGroupPostsMetadata: function() {
            this.$http.put(`groupposts/userdata`, {
                "username": this.username,
                "userProfilePhoto": this.userProfilePic,
                "userId": firebase.auth.currentUser.uid,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("completed the groupcposts update!");
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        updatePublicPostsMetadata: function() {
            this.$http.put(`publicposts/userdata`, {
                "username": this.username,
                "userProfilePhoto": this.userProfilePic,
                "userId": firebase.auth.currentUser.uid,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("completed the publicposts update!");
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        updateAudioSegmentMetadata: function() {
            this.$http.put(`audiosegments/userdata`, {
                "username": this.username,
                "userProfilePhoto": this.userProfilePic,
                "userId": firebase.auth.currentUser.uid,
            }).then((response) => {
                if (response.status == 200) {
                    // console.log("completed the audiosegs update!");
                } else {
                    // console.log("couldn't complete publicposts the update");
                }
            }).catch((error) => {
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${error.message}`, type: 'snackbar' });
            });
        },
        isAdmin () {
            return firebase.auth.currentUser.uid === 'yzBy9SStgyNr1Ncjf9pkQjfJUO03' || firebase.auth.currentUser.uid === "ex18uSUvXReInwQRqaAZxO6Syer2" || firebase.auth.currentUser.uid === "PLTd7RbPoQWClAmHM5k7Qca5H783" || firebase.auth.currentUser.uid === "9hg1CKOXMFefAWLkUMaBGv7j40u1" || firebase.auth.currentUser.uid === 'lXVXV6nvtLTRWJQbtvg9zH8C1hL2';
        },

        /* FOR TESTING ONLY */
        unlinkGoogleAccount: function () {
            const user = firebase.auth.currentUser;

            user.providerData.forEach(provider => {
                if (provider.providerId === "google.com") {
                    user.unlink("google.com").then(() => {
                        console.log("Google Account Unlinked");
                    }).catch((error) => {
                        console.log("Error: ", error.message);
                    });
                }
            });
        },
        sortEmails: function () {
            const emailCategories = {
                'yahoo.com': [],
                'gmail.com': [],
                'microsoft.com': [],
                'edu': [],
                'org': [],
                'net': [],
                'biz': [],
                'in': [],
                'other': []
            };

            firebase.db.collection("userData").get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const email = doc.data().email;
                        if (email) {
                            const emailParts = email.split("@");
                            const domain = emailParts[1];
                            const category = domain.endsWith(".edu") ? 'edu' :
                                domain.endsWith(".org") ? 'org' :
                                domain.endsWith(".net") ? 'net' :
                                domain.endsWith(".biz") ? 'biz' :
                                domain.endsWith(".in") ? 'in' :
                                domain === "hotmail.com" || domain === "outlook.com" || domain === "msn.com" ? 'microsoft.com' :
                                emailCategories.hasOwnProperty(domain) ? domain : 'other';
                            emailCategories[category].push(email);
                        }
                    });

                    for (const category in emailCategories) {
                        console.log(`${category}: ${emailCategories[category].length}`); // Log the count of emails in each category
                    }
                    // console.log("otherEmails: ", emailCategories['other']); // Log the emails in other category
                }).catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        getAudio: async function () {
            // this function is used to get the audio and captions (subtitles) data for a specific audioSegment.
            // it then calls this.sortAudio(audio,captions), which will take the data from above and sort it by audioStart time.
            // finally it sets the newly sorted data to a specific audioSegment in the database.
            // was only used to update the pinnedPost titled 'how to use tawq.in' - postId: rXm4nZfBwDP9DIeycLzS - when the audioStart times were updated.
            // NOTE: this, or something similiar, will need to be used if we ever give the option for a user to update start times of individual audioSegments.
            const docId = "G7jFjyw1UWL1dSL0wgRN";
            const audioDocId = "mMeqakabW2SF9VWYrvTt";
            let audioData = [];
            let captionData = [];
            // let data = {};
            firebase.db.collection("audioSegments").doc(docId).get().then(async (doc) => {
                audioData =  doc.data().audio;
                captionData =  doc.data().subtitles;
                // data = doc.data();
                let newData = await this.sortAudio(audioData, captionData);
                // console.log("newData: ", newData);

                // CHANGES MADE ON FEBRUARY 14, 2024
                // "how to use tawq.in" old postAudioDataId: G7jFjyw1UWL1dSL0wgRN
                // the above in the database under collection audioSegments now has 'old-' in front of the postId so it doesn't show in the list of audioElements
                // "how to use tawq.in" new postAudioDataId: mMeqakabW2SF9VWYrvTt
                // END CHANGES MADE ON FEBRUARY 14, 2024

                firebase.db.collection("audioSegments").doc(audioDocId).set({
                    audio: newData.audio,
                    subtitles: newData.captions,
                }, {merge: true});
            }).catch((error) => {
                console.log("error: ", error);
            });
        },
        sortAudio: async function (audio, captions) {
            // console.log("audio: ", audio);
            // console.log("caption: ", caption);
            return new Promise((resolve) => {
                if (audio.length <= 1){
                    resolve( { audio: audio, captions: captions } );
                } else {
                    var swap;
                    let n = audio.length-1;
                    var newAudio = audio;
                    var newCaptions = captions;
                    do {
                        swap = false;
                        for ( var i = 0; i < n; i++ ){
                            if(typeof audio[i] === 'undefined' || typeof captions[i] === 'undefined'){
                                continue;
                            }
                            if (newAudio[i].audioStart > newAudio[i+1].audioStart){
                                var temp = newAudio[i];
                                var tempCaption = newCaptions[i];
                                newAudio[i] = newAudio[i+1];
                                newAudio[i+1] = temp;
                                newCaptions[i] = newCaptions[i+1];
                                newCaptions[i+1] = tempCaption;
                                swap = true;
                            }
                        }// for loop
                    } while(swap);
                    // console.log("THE NEW ARRAY IN BUBBLE SORT, ", newAudio);
                    // console.log("bubbleSort() audio: ", newAudio);
                    // console.log("bubbleSort() captions: ", newCaptions);
                    resolve( { audio: newAudio, captions: newCaptions } );
                }
            })
        },
        moveDocument: async function () {
            const docId = "BmMrG4QpPpOuONnankqJ";
            const doc = await firebase.db.collection("publicPosts").doc(docId).get();
            // console.log("document: ", doc.data());
            await firebase.db.collection("failedPublicPosts").doc(docId).set(doc.data(), {merge: true});

        },
        getAllPosts: async function () { // get all public posts
            const pubilcPostsCollection = firebase.db.collection("publicPosts");
            const snapshot = await pubilcPostsCollection.get();
            console.log("snapshot size: ", snapshot.size); // see how many public posts we have
            // let count = 0;
            // pubilcPostsCollection.get().then((snapshot) => {
            //     snapshot.forEach((doc) => {
            //         count += 1;
            //     });
            //     console.log("COUNT: ", count);
            // });
        },
        createUserDataInFirebaseFirestore: async function () {
            const user = {
                uid: "emKGest0c3ZqeS75A0aJVbuCbqw1",
                displayName: "Tawqin Test",
                email: "tawqintest@gmail.com",
                emailVerified: false,
                signUpMethod: "email",
                profilePicture: "",
            }
            const userData = {
                    userId: user.uid,
                    fullname: user.displayName,
                    email: user.email,
                    username: user.displayName,
                    profanityFilter: false,
                    darkTheme: true,
                    emailVerified: user.emailVerified,
                    pushToTawq: true,
                    postsCreated: 0,
                    playbackStyle: false, // default to pauseplay
                    captionAudio: true,
                    dualDevice: false,
                    signUpMethod: user.signUpMethod,
                    signUpDate: new Date(),
                    profilePicture: user.profilePicture,
                }
            await firebase.db.collection("userData").doc(userData.userId).set(userData);
        },
        /* END FOR TESTING ONLY */
    },
    computed: {
        nameErrors () {
            const errors = []
            if (!this.$v.newUsername.$dirty) return errors
            !this.$v.newUsername.maxLength && errors.push('Username must be 30 characters or less');
            return errors
        },
        savedUserData() {
            return this.$store.state.userData;
        }
    },
    beforeDestroy: function() {
        if (this.previewImage) {
            this.$window.URL.revokeObjectURL(this.image);
        }
    },
    beforeMount: function(){
        /* copy the local userData var to the savedUserData var from the store. this is so we can change the values */
        this.userData = {
            ... this.savedUserData
        }
        if (typeof this.userData.playbackStyleAudioFirst === "undefined") {
            // console.log("Audio First Mode does not exist in userData, setting to default value of true.");
            this.userData.playbackStyleAudioFirst = true;
        }
        if (typeof this.userData.playbackStyleSameStart === "undefined") {
            // console.log("Same Start Mode does not exist in userData, setting to default value of true.");
            this.userData.playbackStyleSameStart = true;
        }
        if (typeof this.userData.showCaptions === "undefined") {
            // console.log("Show Captions does not exist in userData, setting to default value of true.");
            this.userData.showCaptions = true;
        }
    },
    created: function() {
        if(!this.userData.languageSelected) this.userData.languageSelected = this.languageSelected

        /*** FOR TESTING ONLY ***/
        // const isAdmin = (firebase.auth.currentUser.uid === 'KhZ38UQfssTfNSPuvzj4BSC00rC2' || firebase.auth.currentUser.uid === '9hg1CKOXMFefAWLkUMaBGv7j40u1') ? true : false; // userIds for Scott Brady with account emails: scott.tawq.in@gmail.com and bradyscottmorris@gmail.com respectively
        // if (isAdmin) {
        //     // this.unlinkGoogleAccount(); // this will unlink a google account and is used for testing so we can sign up with a google account again.
        //     // this.sortEmails();
        //     // this.moveDocument();
        //     // this.getAllPosts(); // used to get all public posts
        //     // this.createUserDataInFirebaseFirestore();
        // }

        // this.getAudio(); // used for testing???
        /*** END FOR TESTING ONLY ***/
    },
};
</script>

<style scoped>
.theme-icon {
    transform: rotate(180deg);
}
</style>