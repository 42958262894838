<template>
    <v-row justify="center">
        <v-list-item v-for="(friend, index) in friends" :key="friend.userId" v-bind:class="[isOdd(index) ? 'odd' : $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']" style="width: 100%" class="py-2 my-0 pr-0 justify-space-between rounded-xl">
            <v-row justify="space-between" align="center" no-gutters>
                <v-col cols="2" class="flex-grow-0 flex-shrink-1">
                    <v-avatar size="50" color="primary" @click.stop="openSearchedUsersPage(friend.userId)" style="cursor: pointer;" class="hidden-xs-only">
                        <v-img :src="friend.userProfilePhoto != '' && friend.userProfilePhoto != null ? friend.userProfilePhoto : require('../../assets/no-user.png')" :alt="friend.username" referrerpolicy="no-referrer" @error="friend.userProfilePhoto=null"></v-img>
                    </v-avatar>
                    <v-avatar size="40" color="primary" @click.stop="openSearchedUsersPage(friend.userId)" style="cursor: pointer;" class="hidden-sm-and-up">
                        <v-img :src="friend.userProfilePhoto != '' && friend.userProfilePhoto != null ? friend.userProfilePhoto : require('../../assets/no-user.png')" :alt="friend.username" referrerpolicy="no-referrer" @error="friend.userProfilePhoto=null"></v-img>
                    </v-avatar>
                </v-col>
                <v-col cols="7" class="flex-grow-1 flex-shrink-0">
                    <div v-bind:class="[isOdd(index) ? 'black--text' : $vuetify.theme.dark ? 'white--text' : 'black--text']" @click.stop="openSearchedUsersPage(friend.userId)" style="cursor: pointer;" class="font-weight-medium text-caption text-lg-subtitle-1 text-truncate">
                        {{ friend.username }}
                    </div>
                </v-col>
                <v-col cols="3" class="flex-grow-0 flex-shrink-1">
                    <v-row justify="end" align="center" no-gutters>
                        <v-btn v-if="friend.accepted" text color="primary" @click.stop="openRemoveFriendDialog(friend, index)" class="font-weight-light text-caption">remove</v-btn>
                        <v-btn v-if="!friend.accepted" text color="primary" @click.stop="openCancelFriendDialog(friend)" class="font-weight-light text-caption hidden-sm-and-down">cancel request</v-btn>
                        <v-btn v-if="!friend.accepted" text color="primary" @click.stop="openCancelFriendDialog(friend)" class="font-weight-light text-caption hidden-md-and-up">cancel</v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-list-item>
        <RemoveFriend :dialog="removeFriendDialog" :friend="friend" @removeFriend="removeFriend" @cancelRemoveFriend="removeFriendDialog = false"/>
        <CancelFriend :dialog="cancelFriendDialog" :friend="friend" @cancelFriendRequest="cancelFriend" @dismissCancelFriendRequest="cancelFriendDialog = false"/>
    </v-row>
</template>

<script>
import RemoveFriend from "./RemoveFriend.vue";
import CancelFriend from "./CancelFriend.vue";

export default {
    name: "friends",
    props: ["friends"],
    components: {
        RemoveFriend,
        CancelFriend,
    },
    data: function () {
        return {
            removeFriendDialog: false,
            cancelFriendDialog: false,
            friendIndex: null,
            friend: {},
        };
    },
    methods: {
        isOdd: function (id) {
            if (id % 2 == 1) {
                return true;
            }
            return false;
        },
        openSearchedUsersPage: function (id) {
            this.$router.push({name: 'view-user-profile', query: { id: id }});
            // window.open(`https://tawq.in/view-user-profile?id=${id}`, "_blank");
        },
        openRemoveFriendDialog: function (friend, index) {
            this.friend = friend;
            this.friendIndex = index;
            this.removeFriendDialog = true;
        },
        openCancelFriendDialog: function (friend) {
            this.friend = friend;
            this.cancelFriendDialog = true;
        },
        removeFriend: function () {
            this.$emit("removeFriend", this.friend, this.friendIndex);
            this.removeFriendDialog = false;
        },
        cancelFriend: function () {
            this.$emit("cancelFriend", this.friend);
            this.cancelFriendDialog = false;
        },
    },
};
</script>

<style>
.odd {
    background: #dddddd !important;
}
</style>
