<template>
    <v-container fluid class="mt-9 mt-lg-11 mx-0">
        <v-app-bar app class="primary">
            <v-col cols="4" class="ma-0 pa-0">
                <v-img max-height="60px" :max-width="isMobile ? '90px' : '125px'" contain position="left center" src="../assets/whitetransparent.png" style="cursor: pointer" @click.stop="toggleHome"></v-img>
            </v-col>
            <v-spacer/>
            <v-col cols="8" align="end" class="ma-0 pa-0">
                <v-btn color="white" outlined class="mr-2 font-weight-normal" @click.stop="loginUser">Login</v-btn>
                <!-- <v-btn color="white" outlined class="mr-2 font-weight-normal" :to="{name: 'login'}">Login</v-btn> -->
                <!-- <v-btn color="white" outlined class="font-weight-normal" :to="{name: 'signup'}">Sign Up</v-btn> -->
                <v-btn color="white" outlined class="font-weight-normal" @click.stop="signupUser">Sign Up</v-btn>
            </v-col>
            <!-- HELP BUTTON -->
            <transition name="fade">
                <div v-if="showIsGroupPostIndicator" class="d-flex flex-column"  style="position: absolute; bottom: -60px; right: 0px;">
                    <v-icon class="align-self-center">mdi-arrow-up-bold</v-icon>
                    <p class="rounded-xl white--text accent py-1 px-3">Are you part of this group? Click here to Login.</p>
                </div>
            </transition>
        </v-app-bar>
        <v-row justify="center" no-gutters class="px-lg-15 mt-5 mx-0 py-0">
            <v-col cols="12" xl="7" lg="8" md="12" sm="12" xs="12" class="pr-lg-5">
                <v-row v-if="viewLoading" justify="center" no-gutters>
                    <v-col cols="12">
                        <v-skeleton-loader height="600" type="image" class="hidden-md-and-down"></v-skeleton-loader>
                        <!-- <v-skeleton-loader height="600" type="image" class="hidden-xl-only hidden-md-and-down"></v-skeleton-loader> -->
                        <v-skeleton-loader height="450" type="image" class="hidden-lg-and-up hidden-xs-only"></v-skeleton-loader>
                        <v-skeleton-loader height="200" type="image" class="hidden-sm-and-up"></v-skeleton-loader>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col cols="12">
                        <VideoPlayer
                            v-if="postData.videoUrl && postData.videoType"
                            ref="vidPlayer"
                            :options="vidOptions"
                            :isCreating="false"
                            :videoLoading="videoLoading"
                            :anonymousMode="postData.anonymousMode || false"
                            :showCaptions="showCaptions"
                            @seeked="videoPlayerSeeked"
                            @hasBegunPlaying="audioElementHasBegunPlaying = true"
                            @playAudioPlayer="playAudioPlayer"
                            @pauseAudioPlayer="pauseAudioPlayer"
                            @controlsEnabled="($refs.vidPlayer.getMarkers().length <= 0) && $refs.vidPlayer.placeMarkers(currentAudioElement.audioData.audio, currentAudioElement.audioData.subtitles, currentAudioElement.audioData.username);"
                            @playerReady="getAudioData"
                            @timeUpdate="timeUpdate"
                            @markerClicked="markerClicked"
                        />
                    </v-col>
                </v-row>

                <div class="full-width" style="position: relative; max-width: 100%;">
                    <v-row justify="space-around" align="center" no-gutters class="mt-5">
                        <v-col cols="10">
                            <span class="text-h5 text-xl-h4 text-lg-h4 font-weight-bold">{{postData.title}}</span>
                        </v-col>

                        <v-col cols="2" align="end">
                            <v-menu v-model="menu" rounded="lg" offset-y bottom origin="center" transition="scale-transition" close-on-content-click>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" @click.stop="menu = !menu" icon :disabled="showIsGroupPostIndicator || postDoesNotExist">
                                        <v-icon :color="$vuetify.theme.dark ? 'amber' : 'primary'">mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list rounded>
                                    <v-list-item @click.stop="showShareDialog = true, menu = !menu"> 
                                        <v-icon class="pr-2">mdi-share</v-icon>Share<br>
                                    </v-list-item>
                                    <v-list-item @click.stop="reportDialog = true, menu = !menu">
                                        <v-icon class="pr-2">mdi-flag</v-icon>Report
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <Report v-if="reportDialog" :dialog="reportDialog" page="viewPost" :post="postData" @close-report="reportDialog = false"/>
                            <ShareDialog v-if="showShareDialog" :dialog="showShareDialog" :postData="postData" @close-dialog="showShareDialog = false"/>
                        </v-col>
                    </v-row>

                    <div v-if="viewFullDescription" class="full-width" style="position: relative; max-width: 100%;">
                        <v-row justify="start" align="center" no-gutters class="my-3">
                            <p style="cursor: pointer;" class="subtitle-2 font-weight-light font-italic mb-0 py-0 accent--text" @click.stop="consoleLogToUser('name')"> @{{postData.username ? postData.username : 'Unknown' }}</p>
                            <span class="px-2">|</span>
                            <p class="subtitle-2 font-weight-light mb-0 py-0">{{postData.postDate}}</p>
                        </v-row>

                        <!-- We only want the view more/less button to come up on small size viewports and down -->
                        <!-- Therefore we will check for that here. If it is small >= then only show the condensed version and the view more button if the description length is greater than 150  -->
                        <v-row justify="start" align="center" no-gutters>
                            <span style="max-width: 100%; overflow-x: hidden;" class="subtitle-1 text--secondary font-weight-light">{{postData.description}}</span>
                        </v-row>


                        <v-row justify="space-between" no-gutters class="my-2">
                            <v-col cols="12" xl="4" lg="5" md="5" sm="5" class="my-md-0 my-1">
                                <v-radio-group label="Playback:" v-model="playbackStyle" row dense hide-details :disabled="showIsGroupPostIndicator || postDoesNotExist" class="my-0">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-radio class="hidden-sm-and-down" v-on="on" label="Continuous" value="continuous"></v-radio>
                                            <v-radio class="hidden-md-and-up" label="Continuous" value="continuous"></v-radio>
                                        </template>
                                        <span>Play the captions and audio without the video pausing</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-radio class="hidden-sm-and-down" v-on="on" label="Pause/Play" value="pauseplay"></v-radio>
                                            <v-radio class="hidden-md-and-up" label="Pause/Play" value="pauseplay"></v-radio>
                                        </template>
                                        <span>The video will pause while the captions and audio play</span>
                                    </v-tooltip>
                                </v-radio-group>
                            </v-col>

                            <v-col cols="12" xl="3" lg="3" md="2" sm="2" class="my-md-0 my-1">
                                <v-row :justify="isMobile ? 'start' : 'center'" align="center" class="ma-0 pa-0">
                                    <span class="mr-2 text-subtitle-2 font-weight-light">Captions:</span>
                                    <v-switch v-model="showCaptions" color="primary" :label="showCaptions ? 'ON' : 'OFF'"></v-switch>
                                </v-row>
                            </v-col>

                            <v-col cols="12" xl="5" lg="4" md="5" sm="5" class="my-md-0 my-1">
                                <v-slider
                                    v-model="captionVolume"
                                    label="Caption Audio"
                                    inverse-label
                                    :prepend-icon="captionVolume > 0 ? 'mdi-volume-medium' : 'mdi-volume-variant-off'"
                                    @click:prepend="adjustSegmentVolume('mute')"
                                    @click:append="adjustSegmentVolume(1.0)"
                                    min="0"
                                    max="1"
                                    step="0.1"
                                    v-on:end="adjustSegmentVolume"
                                    v-on:click="adjustSegmentVolume(captionVolume)"
                                    dense
                                    hide-details
                                    :disabled="showIsGroupPostIndicator || postDoesNotExist"
                                ></v-slider>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="!viewFullDescription" v-bind:class="[$vuetify.theme.dark ? 'faded-dark' : 'faded']"></div>
                    <div style="width: 100%;" class="d-flex justify-space-around align-center">
                        <v-divider/>
                        <!-- <p class="text-caption primary--text mb-0 px-2" @click="viewFullDescription = !viewFullDescription">{{ viewFullDescription ? 'View Less' : 'View More'}}</p> -->
                        <v-btn text x-small :ripple="false" color="primary" @click="viewFullDescription = !viewFullDescription">
                            <!-- <v-icon color="primary">{{ viewFullDescription ? 'mdi-triangle-small-up' : 'mdi-triangle-small-down'}}</v-icon> -->
                            <v-icon color="primary">{{ viewFullDescription ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                            {{ viewFullDescription ? 'View Less' : 'View More'}}
                            <!-- <v-icon color="primary">{{ viewFullDescription ? 'mdi-triangle-small-up' : 'mdi-triangle-small-down'}}</v-icon> -->
                            <v-icon color="primary">{{ viewFullDescription ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
                        </v-btn>
                        <v-divider/>
                    </div>
                </div>
            </v-col>

            <v-col cols="12" xl="3" lg="4" md="12" sm="12" xs="12" style="max-height: 60vh; overflow-y: hidden; overflow-x: hidden;" class="bordered mt-5 mt-xl-0 mt-lg-0">
                <!-- style="max-height: 80vh; overflow-y: scroll; overflow-x: hidden;" -->
                <!-- we want to see if there are more than 2 in the array because we start with one ('all'), and only want to filter if there are more than 1 language in the audiodata. -->
                <v-row justify="end" align="center" no-gutters class="mx-0 mt-2 px-5">
                    <v-col v-if="!isMobile" cols="12" xl="7" lg="11" md="3" sm="4" xs="12" align="center">
                        <v-autocomplete v-if="filter.languageCodes.length > 1 || !audioLoading" v-model="filter.languageSelected" :items="filter.languageCodes" label="Filter by language" append-icon="mdi-filter-menu" hide-details :disabled="showIsGroupPostIndicator" class="children-cursor--pointer"/>
                        <v-skeleton-loader v-else type="text@2" class="pa-2"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center" class="mx-0 mb-2 px-5">
                    <v-divider/>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" @click.stop="consoleLogToUser('contribute')" depressed :disabled="showIsGroupPostIndicator || postDoesNotExist" color="primary">Contribute
                                <v-icon right>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Add your own audio</span>
                    </v-tooltip>
                    <v-divider/>
                </v-row>

                <v-row style="max-height: 50vh; overflow-y: scroll;" class="bordered mx-0 px-5">
                    <!-- style="max-height: 100vh; overflow-y: scroll;" -->
                    <v-row v-if="audioLoading" class="my-0 py-0">
                        <v-col v-for="i in 3" :key="i" cols="6" class="my-0 pt-0 px-2 pb-5 hidden-lg-and-up hidden-xs-only">
                            <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                        </v-col>
                        <v-col v-for="i in 3" :key="i+3" cols="6" class="my-0 pt-0 px-2 pb-5 hidden-lg-and-up hidden-xs-only">
                            <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                        </v-col>
                    </v-row>

                    <v-col v-if="leftAudioData.length > 0" cols="12" xs="12" sm="6" md="6" lg="12" xl="12" class="hidden-xs-only hidden-lg-and-up ma-0 pl-0 py-0 justify-end">
                        <v-container fluid v-for="audio in leftAudioData" :key="audio.audioDataId" class="pt-0 px-0 pb-5">
                            <ElementAudio
                                v-if="audio.audioData.audio.length > 0"
                                :audioObject="audio"
                                :postAudioDataId="postData.postAudioDataId"
                                :selectedAudioId="selectedAudioId"
                                :postType="postType"
                                :postData="postData"
                                :videoLoading="videoLoading"
                                :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                                :anonymousMode="postData.anonymousMode || false"
                                @audioChanged="changeElementAudio"
                                @changeElementAudio="changeSegmentAudio"
                                @usernameClicked="consoleLogToUser"
                            />
                        </v-container>
                    </v-col>

                    <v-col v-if="rightAudioData.length > 0" cols="12" xs="12" sm="6" md="6" lg="12" xl="12" class="hidden-xs-only hidden-lg-and-up ma-0 pr-0 py-0 justify-end">
                        <v-container fluid v-for="audio in rightAudioData" :key="audio.audioDataId" class="pt-0 px-0 pb-5">
                            <ElementAudio
                                v-if="audio.audioData.audio.length > 0"
                                :audioObject="audio"
                                :postAudioDataId="postData.postAudioDataId"
                                :selectedAudioId="selectedAudioId"
                                :postType="postType"
                                :postData="postData"
                                :videoLoading="videoLoading"
                                :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                                :anonymousMode="postData.anonymousMode || false"
                                @audioChanged="changeElementAudio"
                                @changeElementAudio="changeSegmentAudio"
                                @usernameClicked="consoleLogToUser"
                            />
                        </v-container>
                    </v-col>

                    <v-col v-if="audioLoading" cols="12" class="hidden-md-only hidden-sm-only">
                        <div v-for="i in 5" :key="i" class="my-0 pt-0 px-0 pb-5">
                            <v-skeleton-loader height="100" type="image" class="rounded-lg"></v-skeleton-loader>
                        </div>
                    </v-col>

                    <v-col v-for="audio in languageFilteredAudio" :key="audio.audioDataId" cols="12" class="hidden-md-only hidden-sm-only my-0 pt-0 px-0 pb-5">
                        <ElementAudio
                            v-if="audio.audioData.audio.length > 0"
                            :audioObject="audio"
                            :postAudioDataId="postData.postAudioDataId"
                            :selectedAudioId="selectedAudioId"
                            :postType="postType"
                            :postData="postData"
                            :videoLoading="videoLoading"
                            :audioElementHasBegunPlaying="audioElementHasBegunPlaying"
                            :anonymousMode="postData.anonymousMode || false"
                            @audioChanged="changeElementAudio"
                            @changeElementAudio="changeSegmentAudio"
                            @usernameClicked="consoleLogToUser"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import fb from "firebase/compat/app";
import firebase from "../../firebaseConfig.js";
import VideoPlayer from "./VideoPlayer";
import ElementAudio from "./ElementAudio";
import ShareDialog from "./ShareDialog.vue";
import Report from "./Report.vue";

export default {
    name: "post-view",
    metaInfo() {
        return {
            title: typeof this.postData.title != 'undefined' ? `${this.postData.title} - tawq.in` : 'Post View - tawq.in',
        }
    },
    props: ["postId", "postType"],
    components: {
        VideoPlayer,
        ElementAudio,
        ShareDialog,
        Report,
    },
    data: function () {
        return {
            menu: false,
            showShareDialog: false,
            showIsGroupPostIndicator: false,
            reportDialog: false,
            viewLoading: true,
            audioLoading: true,
            postData: { ...this.currentPost },
            selectedAudioId: null,
            currentAudioElement: null,// this will be the currently selected AudioElement.
            currentAudioSegment: null,// this will be the current segment loaded into the audioPlayer.
            nextAudioSegment: null,// this will be the next audio segment to be loaded into the audioPlayer.
            vidOptions: {
                autoplay: false,
                controls: false,
                responsive: true,
                sources: {},
                techOrder: ["html5", "youtube"],
                textTrackSettings: false,
                html5: {
                nativeTextTracks: false,
                nativeControlsForTouch: false,
                nativeAudioTracks: false,
                },
                youtube: {
                    enablejsapi: 1,
                    fs: 0,
                    playsinline: 1,
                    cc_load_policy: 3,
                    disablekb: 1,
                    ytControls: 0,
                    // origin: "https://tawq.in" || window.location.orgin, // maybe can use this instead of having to comment out below for testing?
                    // origin: "https://tawq.in", // uncomment for deploy to dev/main
                    origin: window.location.origin, // uncomment for testing
                    modestbranding: 1,
                    start: 0,
                    // widget_referrer: "https://tawq.in",
                },
            },
            audioData: [],
            showCaptions: true,
            playbackStyle: 'pauseplay', // 'continuous',
            audioPlayer: new Audio(),
            preloadAudioPlayer: new Audio(),
            vidPlayerVolume: null,
            captionVolume: 1.0,
            viewFullDescription: false,
            descriptionWordCount: 0,
            filter: {
                languageSelected: "All",
                languageCodes: ["All"],
            },
            postViewedCounter: null,
            postDoesNotExist: false,
            videoLoading: true, // Used to put the video player in, or take out of, a loading state. When true the vjs-loading-spinner will show, else it's hidden.
            audioElementHasBegunPlaying: false,
            audioCanPlay: false, // Used to make sure audio is ready to play when marker is reached.
            // markerHasBeenClicked: false,
            audioElementOrSegmentClicked: false,
        };
    },
    methods: {
        toggleHome: function () {
            if (firebase.auth.currentUser) {
                this.$router.push({path: "/home"});
            } else {
                this.$router.push({path: "/intro"});
            }
        },
        loginUser: function () {
            if (firebase.auth.currentUser) {
                if (this.$route.hash) {
                    this.$router.replace({path: "/view-post", query: {pid: this.$route.query.pid, postType: this.$route.query.postType}, hash: this.$route.hash});
                } else {
                    this.$router.replace({path: "/view-post", query: {pid: this.$route.query.pid, postType: this.$route.query.postType}});
                }
            } else {
                // save current route info to store so user can be redirected back to correct post when logged in
                // have special checks for group posts?
                this.$router.push({path: "/login"});
            }
        },
        signupUser: function () {
            if (firebase.auth.currentUser) {
                if (this.$route.hash) {
                    this.$router.replace({path: "/view-post", query: {pid: this.$route.query.pid, postType: this.$route.query.postType}, hash: this.$route.hash});
                } else {
                    this.$router.replace({path: "/view-post", query: {pid: this.$route.query.pid, postType: this.$route.query.postType}});
                }
            } else {
                // save current route info to store so user can be redirected back to correct post when signed up
                // have special checks for group posts?
                this.$router.push({path: "/sign-up"});
            }
        },
        consoleLogToUser: function (val) {
            let text = `To use this feature you must be signed in.`;
            if (val === 'name') {
                if (typeof this.postData.username != 'undefined') {
                    text = 'To view this users profile you must be signed in.'
                } else {
                    return;
                }
            } else if (val === 'contribute') {
                text = 'To contribute to this post you must be signed in.'
            }
            this.$store.commit('alertUser', { show: true, text: text, type: 'snackbar' });
        },
        pauseAudioPlayer: function () {
            if(this.audioPlayer.duration > 0 && !this.audioPlayer.paused) {
                if(this.playbackStyle == "continuous") {
                    this.audioPlayer.pause();
                }
            }
        },
        playAudioPlayer: function () {
            if (!this.audioPlayer.ended && this.audioPlayer.duration > 0 && this.audioPlayer.paused) {
                if(this.playbackStyle == "continuous") {
                    this.audioPlayer.play();
                }
            }
        },
        loadAndPreloadFirstAudioData: async function () {
            // This function is called on initial load of the page and anytime a new AudioElement is clicked.
            // It will load the first AudioSegment into the audioPlayer and if playbackStyle is pauseplay, it
            // will load in the second AudioSegment, otherwise, playbackStyle is continuous, and it will load
            // in the next, or one available after the overlapping segments.

            // load first AudioSegment into this.audioPlayer
            this.audioPlayer.src = this.currentAudioElement.audioData.audio[0].audioURL;
            this.audioPlayer.load();
            this.currentAudioSegment = this.currentAudioElement.audioData.audio[0];
            this.currentAudioSegment.index = 0;
            this.currentAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[0].subtitle;
            this.currentAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[0].audioSegId;
            (this.currentAudioSegment.audioStart == 0) && (this.audioElementHasBegunPlaying = true);

            if (this.currentAudioElement.audioData.audio.length > 1) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous') {
                    // check for overlaps and return the next available AudioSegment index.
                    var nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(0,1);
                    if (nextAvailableIndexAfterOverlap !== null) {
                        this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                        this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                        this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                        this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                    } else {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        this.addAudioPlayerEventListeners();
                        return;
                    }
                } else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[1];
                    this.nextAudioSegment.index = 1;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[1].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[1].audioSegId;
                }
                // this.preloadAudioPlayer.src = this.nextAudioSegment != null ? this.nextAudioSegment.audioURL : null;
                this.preloadAudioPlayer.src = (this.nextAudioSegment && this.nextAudioSegment.audioURL);
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }

            // Wait until the end of this function to add event listeners, that way we make sure we get the result of the nextAudioURL
            // before the function stops loading in this.audioCanPlayThrough(). These will set the event listeners to be on for every
            // AudioSegment that plays.
            this.addAudioPlayerEventListeners();
        },
        loadAndPreloadNextAudioData: async function () {
            // set this.preloadAudioPlayer.src (this.nextAudioSegment) to this.audioPlayer.src, since it is already preloaded it should be ready to go when set.
            this.audioPlayer.src = this.preloadAudioPlayer.src;
            this.audioPlayer.load();
            this.currentAudioSegment = this.nextAudioSegment;
            var nextAvailableIndexAfterOverlap;

            if (this.nextAudioSegment != null && (this.currentAudioElement.audioData.audio.length-1) >= (this.nextAudioSegment.index+1)) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous') {
                    // check for overlaps and return the next available AudioSegment index.
                    nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(this.nextAudioSegment.index, this.nextAudioSegment.index+1);
                    if (nextAvailableIndexAfterOverlap !== null) {
                        this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                        this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                        this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                        this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                    } else {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        return;
                    }
                } else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    nextAvailableIndexAfterOverlap = this.nextAudioSegment.index+1; // Check to see if index+1 is out of range, if so set this.nextAudioSegment to null
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                    this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                }
                this.preloadAudioPlayer.src = this.nextAudioSegment.audioURL;
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }
        },
        loadAndPreloadAudioDataAfterClick: async function (index) {
            // load AudioSegment at index into this.audioPlayer
            this.audioPlayer.src = this.currentAudioElement.audioData.audio[index].audioURL;
            this.audioPlayer.load();
            this.currentAudioSegment = this.currentAudioElement.audioData.audio[index];
            this.currentAudioSegment.index = index;
            this.currentAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[index].subtitle;
            this.currentAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[index].audioSegId;

            if ((this.currentAudioElement.audioData.audio.length-1) >= (index+1)) {
                // preload next available AudioSegment so it is ready for playback when its marker is reached.
                if (this.playbackStyle === 'continuous') {
                    // check for overlaps and return the next available AudioSegment index, if available.
                    var nextAvailableIndexAfterOverlap = await this.getNextAvailableIndexAfterOverlap(index,(index+1));
                    if (nextAvailableIndexAfterOverlap == null) {
                        // All AudioSegments in this.currentAudioElement (currently select AudioElement) overlap, unable to set nextAudioSegment.
                        this.nextAudioSegment = null;
                        this.preloadAudioPlayer.src = this.nextAudioSegment;
                        this.preloadAudioPlayer.preload = 'none';
                        this.addAudioPlayerEventListeners();
                        return;
                    }
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[nextAvailableIndexAfterOverlap];
                    this.nextAudioSegment.index = nextAvailableIndexAfterOverlap;
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[nextAvailableIndexAfterOverlap].audioSegId;
                } else /*this.playbackStyle == 'pauseplay'*/{
                    // because the video player is paused we wont miss any segments, set next available AudioSegment as second in list, or index 1.
                    this.nextAudioSegment = this.currentAudioElement.audioData.audio[(index+1)];
                    this.nextAudioSegment.index = (index+1);
                    this.nextAudioSegment.subtitle = this.currentAudioElement.audioData.subtitles[(index+1)].subtitle;
                    this.nextAudioSegment.subtitleSegment = this.currentAudioElement.audioData.subtitles[(index+1)].audioSegId;
                }
                this.preloadAudioPlayer.src = (this.nextAudioSegment && this.nextAudioSegment.audioURL);
                this.preloadAudioPlayer.preload = 'auto';
            } else {
                // only one AudioSegment to play. No need for preloadAudioPlayer. Destroying preloadAudioPlayer.
                this.nextAudioSegment = null;
                this.preloadAudioPlayer.src = this.nextAudioSegment;
                this.preloadAudioPlayer.preload = 'none';
            }

            // Wait until the end of this function to add event listeners, that way we make sure we get the result of the nextAudioURL
            // before the function stops loading in this.audioCanPlayThrough(). These will set the event listeners to be on for every
            // AudioSegment that plays.
            this.addAudioPlayerEventListeners();
        },
        getNextAvailableIndexAfterOverlap: function (index, indexToCheck) {
            return new Promise( async (resolve) => {
                if (this.currentAudioElement.audioData.audio.length <= indexToCheck) {
                    resolve(null);
                } else {
                    if (this.currentAudioElement.audioData.audio[index].audioEnd < this.currentAudioElement.audioData.audio[indexToCheck].audioStart) {
                        resolve(indexToCheck);
                    } else {
                        let recursiveResult = await this.getNextAvailableIndexAfterOverlap(index, indexToCheck+1);
                        resolve(recursiveResult);
                    }
                }
            });
        },
        addAudioPlayerEventListeners: function () {
            this.audioPlayer.addEventListener('canplaythrough', this.audioCanPlayThrough/*, {once: true}*/);
            this.audioPlayer.addEventListener("ended", this.audioEnded/*, {once: true}*/);
        },
        removeAudioPlayerEventListeners: function () {
            this.audioPlayer.removeEventListener("canplaythrough", this.audioCanPlayThrough);
            this.audioPlayer.removeEventListener("ended", this.audioEnded/*, {once: true}*/);
        },
        audioCanPlayThrough: function () {
            this.audioCanPlay = true;
            if (this.videoLoading) this.videoLoading = false;
            // When videoLoading is set to false here, the function videoLoading() in VideoPlayer.vue's watch property
            // gets fired. Which in turn hides the loadingSpinner, shows the bigPlayButton, if necessary, updates the
            // markers and sets player controls to true. When player.controls(true) the .on("controlsenabled") listener
            // gets fired. This will playerPlay() the video player in VideoPlayer.vue and $emit("controlsEnabled") back
            // here to ViewPost.vue, which will call $refs.vidPlayer.placeMarkers();
        },
        audioEnded: function (/*event*/) {
            this.audioCanPlay = false;

            (this.playbackStyle === "pauseplay") && this.$refs.vidPlayer.playerPlay();
            this.$refs.vidPlayer.removeCue(false);
            this.nextAudioSegment && this.loadAndPreloadNextAudioData();
            if (this.nextAudioSegment == null && (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length)) {
                this.currentAudioSegment = null;
                this.removeAudioPlayerEventListeners();
            }
        },
        timeUpdate: function (time) {
            if (this.videoLoading || this.currentAudioSegment == null || !this.audioPlayer.paused /*|| (this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)*/) return;
            if ((this.currentAudioSegment.index == this.currentAudioElement.audioData.audio.length-1)) this.currentAudioSegment.index += 1;
            if (Math.abs(this.currentAudioSegment.audioStart - time) <= 0.3) { // timeUpdate is called every 0.2 - 0.3 seconds, so we check for anything + or - 0.3
                this.playbackStyle === "pauseplay" && this.$refs.vidPlayer.playerPause();
                this.$refs.vidPlayer.addCue(this.$refs.vidPlayer.playerGetTime(), this.currentAudioSegment.audioEnd, this.currentAudioSegment.subtitle, this.displayLang);
                this.audioPlayer.play();
            }
        },
        videoPlayerSeeked: function (time) {
            if (this.videoLoading/*|| this.currentAudioSegment.audioStart == 0 && this.audioPlayer.paused*/) return;
            if (this.audioElementOrSegmentClicked) {
                return this.audioElementOrSegmentClicked = false;
            }
            this.videoLoading = true;
            !this.audioPlayer.paused && this.audioPlayer.pause();
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

            [this.currentAudioSegment, this.nextAudioSegment] = [null, null];
            let nextAudioSegmentIndex = this.currentAudioElement.audioData.audio.findIndex((data) => {
                if (Math.abs(data.audioStart - time) <= 0.3) {
                    return true;
                }
                if (data.audioStart >= time) {
                    return true;
                }
            });
            if (nextAudioSegmentIndex == -1) {
                this.videoLoading = false;
                return;
            }
            this.loadAndPreloadAudioDataAfterClick(nextAudioSegmentIndex);
        },
        changeSegmentAudio: function (data) {
            //  this function is ran only when a audioSegment is clicked. It will update the markers if necessary and playSelectedAudioSegment
            this.audioElementOrSegmentClicked = true;
            this.videoLoading = true;

            !this.audioPlayer.paused && this.audioPlayer.pause(); // if the player is NOT paused then pause, else nothing;
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

            if (this.selectedAudioId === data.audioId) {
                this.$refs.vidPlayer.playerSetTime(data.audioSeg.audioSeg.audioStart);
            } else {
                // formatAE refers to the new AudioElement data, updated to the correct format, that will be set to this.currentAudioElement
                let formatAE = {
                    audioData: data.audioData,
                    audioDataId: data.audioId,
                };
                this.currentAudioElement = formatAE;
                this.selectedAudioId = data.audioId;

                this.$refs.vidPlayer.removeMarkers();
                this.$refs.vidPlayer.playerSetTime(data.audioSeg.audioSeg.audioStart);
                this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});

                this.audioElementHasBegunPlaying = false;
            }
            this.loadAndPreloadAudioDataAfterClick(data.audioSeg.index);
        },
        changeElementAudio: function (audioData, audioId) {
            // This function is called Everytime the play button on an AudioElement is clicked.
            // Therefore we must reset the audioSegment number, or else it can/will increment higher
            // Than the actual length of the audioData.

            if (this.selectedAudioId === audioId && !this.audioElementHasBegunPlaying) return;

            this.audioElementOrSegmentClicked = true;
            this.videoLoading = true;

            !this.audioPlayer.paused && this.audioPlayer.pause();
            this.removeAudioPlayerEventListeners();
            this.$refs.vidPlayer.hasTextTrack() && this.$refs.vidPlayer.removeCue(true); //this.$refs.vidPlayer.removeTextTrack();

            if (this.selectedAudioId === audioId && this.audioElementHasBegunPlaying) {
                this.audioElementHasBegunPlaying = false;
                this.$refs.vidPlayer.playerSetTime(0.0);
            } else if (this.selectedAudioId != audioId && !this.audioElementHasBegunPlaying) {
                // This else if statement should only happen if the user presses the play button of an AudioElement right after initial
                // loading is done, but before auto play has started. Shouldn't happen very often, if at all!!!
                this.$refs.vidPlayer.removeMarkers();
                this.currentAudioElement = {
                    audioDataId: audioId,
                    audioData: audioData,
                };
                this.selectedAudioId = audioId;
                this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});
            } else {
                this.currentAudioElement = {
                    audioDataId: audioId,
                    audioData: audioData,
                };
                this.selectedAudioId = audioId;

                this.$refs.vidPlayer.removeMarkers();
                this.$refs.vidPlayer.playerSetTime(0.0);
                this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});

                this.audioElementHasBegunPlaying = false;
            }
            this.loadAndPreloadFirstAudioData();
        },
        markerReached: function (/*marker*/){ //index, time){
            return false
        },
        markerClicked: function (/*marker*/){ //index, time){
            return false;
        },
        getPostData: function () {
            let publicPostRef = firebase.db.collection("publicPosts").doc(this.postId);
            publicPostRef.get().then((doc) => {
                if (!doc.exists) {
                    this.$store.commit('alertUser', { show: true, text: `This post does not exist.`, type: 'snackbar' });
                    this.postDoesNotExist = true;
                    setTimeout(() => {
                        this.$router.push({path: "/intro"});
                    }, 8000);
                    return;
                }
                var date = doc.data().postDate.toDate().toLocaleDateString();
                this.postData = doc.data();
                this.postData.postDate = date;
                var newSrc = {
                    type: this.postData.videoType,
                    src: this.postData.videoUrl,
                };
                this.vidOptions.sources = newSrc;
                this.viewLoading = false;
                this.descriptionWordCount = this.wordCount(this.postData.description);
            }).catch((err) => {
                this.viewLoading = false;
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        adjustSegmentVolume: function (value) {
            if (value == 'mute'){
                if (this.captionVolume > 0.0){
                    this.captionVolume = 0.0;
                } else {
                    this.captionVolume = 1.0;
                }
                this.$refs.vidPlayer.playerSetVolume(1.0 - this.captionVolume);
                this.audioPlayer.volume = this.captionVolume;
            } else {
                this.captionVolume = value;
                this.$refs.vidPlayer.playerSetVolume(1.0 - this.captionVolume);
                this.audioPlayer.volume = this.captionVolume;
            }
        },
        visibilityChanged: function () {
            if (document.hidden) {
                if (this.vidPlayer) {
                    this.$refs.vidPlayer.playerPause();
                    this.pauseAudioPlayer();
                }
            }
        },
        getAudioData: function () {
            /*  this function will retrieve the metadata for the audio segments, not the audio itself
            We will query the owners audio segments first, adding them to our local array. after we will
            query every audio segment, and only add it to the array if it hasn't been added yet. Firebase Querying
            does not allow '!=', or for us to use where clause with orderby clause on different fields. */

            this.postViewedCounter = setTimeout(() => {
                this.incrementPostViewed();
            }, 5000);
            this.audioData = [];
            let audioRef = firebase.db.collection("audioSegments").where("postId", "==", this.postData.postId).orderBy("date", "desc");
            audioRef.get().then((snapshot) => {
                if (snapshot.empty) {
                    this.audioLoading = false;
                    return;
                }
                snapshot.forEach((doc) => {
                    let data = {
                        audioData: doc.data(),
                        audioDataId: doc.id,
                    };
                    /* first check to see that there is audio here, then if it is the main audioData that is associated with the post, add it to the front not the back */
                    if (data.audioData.audio.length > 0) {
                        if(data.audioDataId == this.postData.postAudioDataId){
                            this.audioData.unshift(data);
                        } else {
                            this.audioData.push(data)
                        }
                    }
                    if (typeof data.audioData.languageName != 'undefined' && !this.filter.languageCodes.includes(data.audioData.languageName)){
                        this.filter.languageCodes.push(data.audioData.languageName)
                    }
                });
                if (!this.$route.query.aid) {
                    this.selectedAudioId = this.audioData[0].audioDataId; // no aid in url, set first AudioElement to be highlighted.
                    this.$router.replace({path: this.$route.name, query: { pid: this.postData.postId, postType: this.postData.postType, aid: this.selectedAudioId}});
                } else {
                    this.selectedAudioId = this.$route.query.aid; // set this so the AudioElement that will play when clicking video or big play button is highlighted.
                }
                this.currentAudioElement = this.audioData.find(data => data.audioDataId === this.selectedAudioId);
                this.loadAndPreloadFirstAudioData();
                this.audioLoading = false;
            }).catch((err) => {
                console.error(err);
                this.$store.commit('alertUser', { show: true, text: `Something went wrong. ${err.message}`, type: 'snackbar' });
            });
        },
        wordCount: function () {
            var temp = this.postData.description;
            temp = temp.replace(/(^\s*)|(\s*$)/gi,"");
            temp = temp.replace(/[ ]{2,}/gi," ");
            temp = temp.replace(/\n /,"\n");
            return temp.split(' ').length;
        },
        incrementPostViewed: function() {
            if (process.env.NODE_ENV === 'production'){
                let postRef;
                if (typeof this.postData.postId === 'undefined'){
                    return;
                } else if (typeof this.postData.postId != 'undefined'){
                    postRef = firebase.db.collection(this.postData.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.postData.postId);
                } else {
                    postRef = firebase.db.collection(this.postType === 'public' ? "publicPosts" : "groupPosts").doc(this.postId);
                }
                postRef.update({ postViewed: fb.firestore.FieldValue.increment(1),}).catch((error) => {
                    console.error("error incrementing the postViewed:", error.message);
                });
            }
        },
        setPost(timesCalled){
            // setPost() looks to see if we can use the current post from the store. if we can, then we don't have to fetch it from firebase. If we try through 5 ticks, with no success, then we will retrieve the post from firebase.
            // we have to wrap the code in the processNextTick() function because when viewPost is created, the store variable hasn't been set
            if (timesCalled > 5) { 
                // base case: if we have tried this 5 times already, we fallback to getting the post info from firebase;
                this.getPostData();
                return;
            } else {
                this.$nextTick(() => {
                    if(this.currentPost.postId === this.postId){
                        this.postData = { ...this.currentPost };
                        this.descriptionWordCount = this.wordCount(this.postData.description);
                        this.vidOptions.sources = {
                            type: this.postData.videoType,
                            src: this.postData.videoUrl,
                        };
                        this.viewLoading = false;
                    } else {
                        this.setPost(timesCalled + 1); // recursively call this function to see if the store has updated
                    }
                });
            }
        },
    },
    computed: {
        isMobile(){
            return this.$store.state.isMobileDevice;
        },
        isIOS(){
            return this.$store.state.isIOSDevice;
        },
        condensed() {
            return this.postData.description.substring(0,150);
        },
        languageFilteredAudio(){
            if(this.filter.languageSelected === "All"){
                return this.audioData;
            } else {
                return this.audioData.filter( audio => audio.audioData.languageName === this.filter.languageSelected );
            }
        },
        leftAudioData(){
            return this.audioData.filter((num, index) => index%2 === 0);
        },
        rightAudioData(){
            return this.audioData.filter((num, index) => index%2 != 0);
        },
        currentPost(){
            return this.$store.state.currentPost;
        },
    },
    beforeDestroy: function () {
        this.audioPlayer.pause();
        this.audioPlayer.remove();
        document.removeEventListener("visibilitychange", this.visibilityChanged);
        clearTimeout(this.postViewedCounter);
    },
    created: async function () {
        // console.log("postId: ", this.postId);
        if (this.postType == "group") {
            this.$store.commit('alertUser', { show: true, text: `This post can only be seen by members of the group it belongs to.`, type: 'snackbar' });
            this.audioLoading = false;
            this.showIsGroupPostIndicator = true;
        } else {
            this.setPost(1);
            if (this.isIOS) {
                // console.log("iphone doesn't allow continuous playback, because it can't have two media elements playing at once. Default to pauseplay");
                this.playbackStyle ='pauseplay';
            }
        }
    },
    mounted: function () {
        document.addEventListener("visibilitychange", this.visibilityChanged); // if the tab isn't being viewed we will pause the video
    },
  
};

</script>


<style scoped>
.v-input >>> .v-input__prepend-outer {
    margin-right: 0 !important;
}

.v-input >>> .v-input__append-outer {
    margin-left: 0 !important;
}

.expand {
    min-height: 105px !important;
    max-height: 600px !important;
    padding-bottom: 1em;
    overflow-y: scroll !important;
}

.faded {
    position: absolute; 
    bottom: 1em;
    width:100%;
    height: 3em;
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0) 0%, rgba(255,255,255, 1) 80%);
}

.faded-dark {
    position: absolute; 
    bottom: 1em;
    width:100%;
    height: 3em;
    background-image: linear-gradient(to bottom, rgba(0,0,0, 0) 0%,rgba(18,18,18, 1) 80%);
    transition: display .4s;
}

.bordered {
    background-color: var(--v-background-base);
    /* border: 5px solid var(--v-background-base); */
    border-radius: 25px;
}

.v-skeleton-loader__image {
    height: 100%;
}
</style>
