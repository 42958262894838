<template>
    <!-- <v-row justify="center" no-gutters>
        <v-col cols="12" xl="11" lg="11" md="12" sm="12" xs="12"> -->
            <video ref="videoPlayer" id="videoPlayer" class="video-js vjs-big-play-centered vjs-16-9 border" controls playsinline webkit-playsinline></video>
            <!-- <audio ref="videoPlayer" class="video-js vjs-big-play-centered vjs-16-9 border" controls playsinline webkit-playsinline :poster="typeof options.sources.type != 'undefined' && options.sources.type.split('/')[0] === 'audio' ? require('../assets/audio-only.jpeg') : null"></audio> -->
        <!-- </v-col>
    </v-row> -->
</template>

<script src="http://code.jquery.com/jquery-2.0.3.min.js"></script>
<script src="http://vjs.zencdn.net/7.20.3/video.js"></script>

<!--<script src="../node_modules/video.js/dist/video.min.js"></script>-->
<!--<script src="videojs-youtube/dist/Youtube.min.js"></script>-->

<!--<script src="https://vjs.zencdn.net/8.0.4/video.min.js"></script>-->

<!--<script src="http://vjs.zencdn.net/7.17.0/video.js"></script>
<script src="https://vjs.zencdn.net/7.17.0/video.min.js"></script>
<script src="http://vjs.zencdn.net/4.3/video.js"></script>-->

<script>

import 'video.js/dist/video-js.css';
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import 'videojs-wavesurfer/dist/videojs.wavesurfer.js';

import "videojs-youtube/dist/Youtube.min.js";
import videojs from "video.js";
import "video.js/dist/video.min.js";
import "../../videojs-markers";
import "../../videojs-markers/dist/videojs-markers.js";
import "../../videojs-markers/dist/videojs.markers.css";

export default {
    name: "VideoPlayer",
    props: {
        options: Object,
        isCreating: Boolean,
        videoLoading: Boolean,
        anonymousMode: Boolean,
        playbackStyle: String,
        playbackStyleAudioFirst: Boolean,
        audioElementOrSegmentClicked: Boolean,
        showCaptions: Boolean,
    },
    data: function () {
        return {
            player: null,
            cue: null,
            track: null,
        };
    },
    methods: {
        playerDispose: function () {
            this.player.dispose();
        },
        playerPlay: function () {
            /* We implement the checks below so that when an audioSegment ends at, or after, the end of the video, it doesn't restart the video */
            // if (this.player.currentTime() != this.player.duration()) this.player.play();

            if (isNaN(this.player.duration())) return;

            if (this.player.currentTime() <= this.player.duration()) this.player.play();

            // console.log(this.player.currentTime(), " : ", this.player.duration());
            // if (typeof this.player.duration() == 'number'){
            //     console.log("NaN");
            //     this.player.play();
            //     // console.log(this.player.currentTime(), " : ", this.player.duration());
            //     // return;
            // }

            // if (isNaN(this.player.duration()) || this.player.currentTime() <= this.player.duration()) {
            //     console.log("player play");
            //     this.player.play();
            // }

            // This code was commented out on April 18, 2023 by Scott Brady. It will need to be checked with all components that contain <VideoPlayer/>.
            // As far as I'm concerned, and currently it is not needed in PostView or ViewPost.
            // August 1, 2023: The following code might be needed for post creation and contribution creation. Because we need to get the duration of the video
            //  and that can only happen with youtube videos if the player has begun playing. Needs to be checked!
            // 
            // else if (this.options.sources.type === "video/youtube") {
            //     console.log("youtube should play");
            //     // if (this.player.currentTime() === 0 && this.player.duration() === 0){
            //     this.player.play();
            //     // } 
            // }
        },
        playerPause: function () {
            this.player.pause();
        },
        playerSetVolume: function (volumeInput) {
            if (volumeInput != null) this.player.volume(volumeInput);
        },
        playerSetTime: function (timeInput) {
            this.player.currentTime((timeInput <= 0) ? 0 : timeInput);
        },
        playerIsPaused: function() {
            return this.player.paused();
        },
        playerGetTime: function () {
            return this.player.currentTime();
        },
        addTextTrack: function(lang, code) {
            if (typeof code == 'undefined' || typeof lang == 'undefined') {
                this.player.addRemoteTextTrack({kind: 'subtitles', default: true, mode: 'showing'}, false);
            } else {
                if (code == "") {
                    this.player.addRemoteTextTrack({kind: 'subtitles', srclang: 'en', label: lang, default: true, mode: 'showing'}, false);
                } else {
                    this.player.addRemoteTextTrack({kind: 'subtitles', srclang: code, label: lang, default: true, mode: 'showing'}, false);
                }
            }
        },
        removeTextTrack: function(){
            this.removeCue();
            this.player.removeRemoteTextTrack( this.player.textTracks()[0] );
        },
        hasTextTrack: function(){
            return this.player.textTracks().length > 0 ? true : false;
        },
        addCue: function(start, end, caption, lang) {
            if (this.track === null || this.track === undefined) { // doesn't have a remote text track yet, addRemoteTextTrack and addCue;
                this.track = this.player.addRemoteTextTrack({kind: 'subtitles', default: true, label: lang, mode: (this.showCaptions ? 'showing' : 'hidden')}, false);
                this.cue = new VTTCue(start, end, caption); //`<i>${user}:</i> ${caption}`);
                this.track.track.addCue(this.cue);
            } 
            // else {
            //     // has a remote text track already, changeCue and set text track mode to 'showing';
            //     this.track.track.mode = 'showing';
            //     this.changeCue(start, end, caption);
            // }
        },
        removeCue: function(/*reset*/) {
            this.track.track.removeCue(this.cue);
            this.track = this.player.removeRemoteTextTrack(this.player.textTracks()[0]);
            this.cue = null;
            // reset will be a boolean value and will determine whether or not the remoteTextTrack needs to be called.
            // if reset is true, then a new AudioElement has been clicked, so we will removeRemoteTextTrack and removeCue.
            // else, the AudioSegment to play is within the same AudioElement, so we just removeCue.
            // if (reset) {
            //     this.track.track.removeCue(this.cue);
            //     this.track = this.player.removeRemoteTextTrack(this.player.textTracks()[0]);
            //     this.cue = null;
            // } else {
            //     this.track.track.mode = 'hidden';
            //     this.track.track.removeCue(this.cue);
            // }
        },
        changeCue: function(start, end, caption/*, user*/){
            this.cue.startTime = start;
            this.cue.endTime = end;
            this.cue.text = caption; //`<i>${user}:</i> ${caption}`;
            this.track.track.addCue(this.cue);
        },
        placeMarkers: function (audio, subtitles, speaker) {
            // placeMarkers(), plural, is used to place all markers for an AudioElement on video player.
            for (var i = 0; i < audio.length; i++) {
                var text = (typeof subtitles[i] != 'undefined' ? subtitles[i].subtitle : "");
                this.player.markers.add([{
                    time: (this.playbackStyle === 'continuous' || this.playbackStyleAudioFirst === true /*|| this.isCreating*/) ? audio[i].audioStart : (audio[i].audioStart + audio[i].audioDur),
                    text: text,
                    overlayText: text,
                    currentSpeaker: speaker,
                    dur: audio[i].audioDur,
                    url: audio[i].audioURL,
                    class: this.player.duration() > 300 ? "markers-style" : null,
                    index: i,
                }]);
                // The following code can be used to display another marker on the video player to show where the start of the video segment is.
                // if (this.playbackStyle === "pauseplay" && !this.playbackStyleAudioFirst) {
                //     this.player.markers.add([{
                //         time: audio[i].audioStart,
                //         text: `video segment-${audio[i].segment} start`,
                //         // overlayText: text,
                //         // currentSpeaker: speaker,
                //         // dur: audio[i].audioDur,
                //         // url: audio[i].audioURL,
                //         class: this.player.duration() > 300 ? "markers-style" : null,
                //         index: i,
                //     }]);
                // }
            }
        },
        removeMarkerAtIndex: function (index) {
            this.player.markers.remove(index);
        },
        removeMarkers: function () {
            this.player.markers.removeAll();
        },
        getMarkers: function () {
            return this.player.markers.getMarkers();
        },
        visibilityChanged: function() {
            if (document.hidden) {
                this.playerPause();
                this.$emit('visibilityChanged');
            }
        },
    },
    watch: {
        videoLoading: function (val) {
            // console.log("videoLoading: ", val);
            if (val) {
                if (!this.player.paused()) this.player.pause();

                this.player.loadingSpinner.show();
                this.player.controls(false);
            } else {
                if (this.audioElementOrSegmentClicked) this.playerPlay();
                if (this.options.sources.type.split('/')[0] === 'audio') {
                    this.$emit("controlsEnabled");
                    // if (!this.isCreating) this.playerPlay(); // might need this with audio file, but I think I want them to play.

                    this.player.loadingSpinner.hide();
                    this.player.responsive(true);
                }
                this.player.controls(true);
                this.player.markers.updateTime();
            }
        },
        showCaptions: function (val) {
            if (this.track !== null && this.track !== undefined) this.track.track.mode = val ? 'showing' : 'hidden';
        },
    },
    mounted: function () {
        if (typeof this.options.sources.type != 'undefined' && this.options.sources.type.split('/')[0] === 'audio') {
            // Link to options for audio visual wavesurfer and videojs player
            // wavesurfer.js: https://wavesurfer-js.org/docs/options.html
            // videojs-wavesurfer: https://collab-project.github.io/videojs-wavesurfer/#/options
            // video.js: https://github.com/videojs/video.js/blob/main/docs/guides/options.md

            // console.log("setting audio visualizer options");
            var options = {
                autoplay: false,
                aspectRation: "16:9",
                controls: true,
                controlBar: {
                    fullscreenToggle: false,
                },
                responsive: false,
                fluid: true,
                textTrackSettings: false,
                plugins: {
                    wavesurfer: {
                        backend: 'MediaElement', // This options works with all tested browsers (Chrome, Safari, Firefox, Microsoft Edge)
                        // One options below did not work in Firefox and Microsoft Edge (Can't remember which one) and the other is untested.
                        // Use 'MediaElement' as the backend unless an unforseen issue arises with it :)
                        // backend: 'MediaElementWebAudio',
                        // backend: 'WebAudio',
                        displayMilliseconds: false,
                        debug: false,
                        waveColor: 'grey',
                        progressColor: 'black',
                        cursorColor: 'black',
                        hideScrollbar: true,
                        mediaType: 'audio',
                        interact: false,
                    }
                }
            };
            this.player = videojs(this.$refs.videoPlayer, options);
            this.player.src({src: this.options.sources.src, type: this.options.sources.type});
        } else {
            this.player = videojs(this.$refs.videoPlayer, this.options);
            this.player.bigPlayButton.hide();
        }

        this.player.markers({
            markers: [],
            markerTip:{
                display: true,
                text: function(marker) {
                    return (this.anonymousMode ? marker.text : marker.currentSpeaker, ': ', marker.text);
                },
            },
            breakOverlay:{
                display: false,
                style: {
                    'width':'100%',
                    'height': '15%',
                    'background-color': 'rgba(0,0,0,0.7)',
                    'color': this.$vuetify.theme.dark ? '#FFC107' : '#86C232',
                    'font-size': '20px',
                },
                text: function(marker) {
                    this.displayTime = marker.dur;
                    return /*marker.currentSpeaker + ": " + */marker.overlayText// + marker.overlayText + marker.overlayText;
                }
            },
            markerStyle: {
                'background-color': this.$vuetify.theme.dark ? '#FFC107' : '#86C232',
            },
            onMarkerReached: (/*marker, index*/) => {
                return false;
                // this.$emit("markerReached", marker, this.player.currentTime()); // , index, this.player.currentTime());
            },
            onMarkerClick: (/*marker, index*/) => {
                return false;
                // this.$emit("markerClicked", marker);
            },
        });

        this.player.on("controlsenabled", () => {
            // console.log("controlsEnabled");

            this.$emit("controlsEnabled");
            if (!this.isCreating) this.playerPlay();
            this.player.loadingSpinner.hide();
        });

        this.player.on("ready", () =>{
            this.$emit("playerReady");
        });

        this.player.on("loadedmetadata", () => {
            // if the video is a file selected by the user, we can get the duration on loadedMetaData. 
            // else if the video is a youtube/url video, we must wait until the video is being played.

            // console.log("loadedmetadata: ", this.player.duration());
            if (this.isCreating) this.$emit('videoDuration', this.player.duration());

            // if (this.isCreating && this.options.sources.type !== "video/youtube") this.$emit('videoDuration', this.player.duration());

            // if (this.isCreating /*&& this.options.sources.type !== "video/youtube"*/) {
            //     this.$emit('videoDuration', this.player.duration());
            // }
        });

        this.player.on("seeked", () => {
            this.$emit("seeked", this.player.currentTime());
        });

        this.player.on("timeupdate", () => {
            // Because this event is fired every 15-250 milliseconds,
            // depending on the playback technology in use we have to check in ViewPost if the
            // next audioSegment is within 250 milliseconds of the time emitted. This will ensure
            // that we don't accidentaly miss or skip over an audioSegment.
            this.$emit("timeUpdate", this.player.currentTime().toFixed(1)); // give this a time update threshold of between 0.1 to 0.3
        });

        this.player.on("play", () => {
            // console.log("on play: ", this.videoLoading);
            this.$emit("hasBegunPlaying");
            if (this.videoLoading) this.playerPause();
            if (this.isCreating) this.$emit('videoPlayButtonClicked');
        });

        this.player.on("pause", () => {
            // console.log("on pause");
            if (this.isCreating) this.$emit('videoPauseButtonClicked');
        });

        this.player.on('error', (error) => {
            console.log("Video Player error event fired: ", error.message);
        })

        document.addEventListener('visibilitychange', this.visibilityChanged);
        const pid = this.$route.query.pid;
        if (pid === "rXm4nZfBwDP9DIeycLzS") { // this if statement is used to set the below values of the pinned post 'how to use tawq.in'
            this.playerSetVolume(0.0);
        }
    },
    created: function() {
    },
    beforeDestroy: function() {
        this.removeMarkers();
        this.playerDispose();
        document.removeEventListener('visibilitychange', this.visibilityChanged);
    },
    destroyed: function() {
        // this.player.markers.destroy();
    },
};
</script>

<style>
/* uncomment below to disable clicking event for video element. I.e. when clicking anywhere on video it will pause/play */
/* .vjs-tech {
  pointer-events: none;
} */

.video-js .vjs-control-bar {
    opacity: 1 !important;
    pointer-events: auto !important;
    transition: none !important;
}

.vjs-loading-spinner {
    display: block;
    visibility: visible;
    -webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
    animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
}

.markers-style {
    /* background-color: red !important; */
    width: 2px !important;
}

.vjs-big-play-button {
    background-color: rgba(97, 137, 47, 0.7) !important;
    border: none !important;
    line-height: 1.65em !important;
}

.vjs-icon-placeholder {
    font-size: 1.25em;
    background-color: rgba(255, 255, 255, 0.8);
}

.vjs-control-bar {
    background-color: rgba(97, 137, 47, 0.7) !important;
    height: 3.3em !important;
}

.vjs-hidden {
    display: none !important;
}

/* the subtitle background */
/* .vjs-text-track-cue {
    border: solid green 1px;
    bottom: 1em !important;
} */
.vjs-text-track-cue div {
    background-color: rgb(97, 137, 47) !important;
    font-size: 1.5rem !important;
    font-family: Roboto, Arial, sans-serif !important;
    /* bottom: -130px !important; */
    top: -10px !important;
    /* margin-bottom: -10% !important; */
    /* border: solid red 1px; */
    /* bottom: -2% !important; */
    z-index: 1;
}

/* .vjs-text-track-display {
    border: solid blue 1px;
} */

.vjs-text-track-cue div i {
    font-size: 1rem !important;
}

video::-webkit-media-controls {
    display: none !important;
}



/* media queries */
@media (max-width: 600px) {
    .vjs-text-track-cue div {
        font-size: 1.1rem !important;
        top: -12px !important;
    }
    .vjs-text-track-cue div i {
        font-size: .8rem !important;
    }
}

/* SM & MD */
@media (min-width: 601px) and (max-width: 1264px){
    .vjs-text-track-cue div {
        font-size: 1.3rem !important;
    }
    .vjs-text-track-cue div i {
        font-size: 0.9rem !important;
    }
}

/* LG */
@media (min-width: 1265px) and (max-width: 1904px){
    .vjs-text-track-cue div {
        font-size: 1.4rem !important;
    }
}

/* video::cue {
    background: rgb(97, 137, 47) !important;
    color: blue;
} */
</style>